import React, { PropsWithChildren } from 'react'

import TimeTrackingWidget from '../timesheet/TimeTrackingWidget'

export default function AppTitle({children, className = '', append = null}: PropsWithChildren<{
    className?: string,
    append?: React.ReactNode
}>) {
    return <div
        className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3">
        <h1 className={`h2 ${className}`}>{children}</h1>

        <div className="d-flex gap-4">
            <TimeTrackingWidget/>
            {append}
        </div>
    </div>
}