import { useContext } from 'react'

import { SmartTableContext } from './TableContext'

export const useSmartTable = () => {
    const context = useContext(SmartTableContext)
    if (context === undefined) {
        throw new Error('useSmartTable in not within SmartTableWrapper')
    }

    return context
}
