import React from 'react'

import { SmartTableBodyRowProps } from './types'

const SmartTableBodyRow = ({ className, onClick, children }: SmartTableBodyRowProps) => {
    const whiteListedProps = {
        className,
        onClick
    }

    return <tr {...whiteListedProps}>{children}</tr>
}

export default SmartTableBodyRow
