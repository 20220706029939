import React from 'react'

import 'react-multi-carousel/lib/styles.css'
import { Link, useSearchParams } from 'react-router-dom'

import { InvoicesList } from './InvoicesList'
import AppLayout from '../common/AppLayout'
import AppTitle from '../common/AppTitle'

function InvoicesPage() {
    const [searchParams,] = useSearchParams()

    return (
        <AppLayout>
            <AppTitle append={<Link to={'/app/invoices/add'}>
                <button className="amani-primary">Vytvoriť faktúru</button>
            </Link>}>Fakturácia</AppTitle>
            {searchParams.get('new') ? <div className="alert alert-success">Faktúra bola úspešne pridaná</div> : null}

            <InvoicesList/>
        </AppLayout>
    )
}

export default InvoicesPage
