import React, {useEffect} from 'react'

import {Col, Container, Row} from 'react-bootstrap'

import {useContentWrapper} from '../common/ContentWrapperProvider'
import PublicLayout from '../common/PublicLayout'
import {StyledInfoPageHeaderWrapper} from '../common/styledComponents'


function FaqPage() {
    const contentWrapper = useContentWrapper()

    useEffect(() => {
        contentWrapper.setNoBootstrapColumns(true)

        return () => {
            contentWrapper.setNoBootstrapColumns(false)
        }
    }, [])

    return (
        <PublicLayout>
            <div className="info-page">
                <StyledInfoPageHeaderWrapper>
                    <Container>
                        <Row>
                            <Col>
                                <h1>Najčastejšie otázky (F&Q)</h1>
                            </Col>
                        </Row>
                    </Container>
                </StyledInfoPageHeaderWrapper>
                <Container>
                    <div className="rounded-content-body mt-3">
                        <Row>
                            <Col>
                                todo
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </PublicLayout>
)
}


export default FaqPage
