import React, {PropsWithChildren, useEffect} from 'react'

import { useLocation } from 'react-router-dom'

import ContentWrapper from './ContentWrapper'
import Header from './Header'
import NewFooter from './NewFooter'
import ScrollToAnchor from './ScrollToAnchor'

function PublicLayout({children}: PropsWithChildren<unknown>) {
    const location = useLocation()
    useEffect(() => {
        const searchParams = location.search
        const match = searchParams.match(/affiliate-uuid=([^&]*)/)
        if (match && match[1]) {
            localStorage.setItem('affiliate-uuid', match[1])
        }
    }, [location.search])

    return (
        <>
            <ScrollToAnchor/>
            <div className="App d-flex flex-column h-100xx min-h-100vh">
                <Header/>
                <div className="flex-shrink-0">
                    <ContentWrapper>
                        {children}
                    </ContentWrapper>
                </div>
                <NewFooter/>
            </div>
        </>
    )
}

export default PublicLayout
