import React, {useEffect} from 'react'

import {useAuth} from '../common/Auth'
import axios from '../service/axiosClient'

function UnsubscribePage() {
    const auth = useAuth()

    useEffect(() => {
        axios.patch('/auth/email-subscribe', {
            newState: false,
            email: auth.user?.email,
            id: auth.user?.id
        })
            .then(response => {
                console.log('responseresponse', response)
            })
    }, [])

    return <>
        Úspešne ste boli odhlásený z odberu emailov.
    </>
}

export default UnsubscribePage
