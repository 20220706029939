import React, { useEffect } from 'react'

import { Link } from 'react-router-dom'

import { CalendarEventForm } from './CalendarEventForm'
import AppLayout from '../common/AppLayout'
import AppTitle from '../common/AppTitle'

export const CalendarAddPage = () => {

    useEffect(() => {

    }, [])

    return (
        <AppLayout>
            <AppTitle append={<Link to={'/app/calendar'}>
                <button className="btn amani-primary">Späť</button>
            </Link>}>Nová udalosť</AppTitle>
            <CalendarEventForm/>
        </AppLayout>
    )
}