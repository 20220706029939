import React, { useState } from 'react'

import { Field, Form, Formik, FormikHelpers } from 'formik'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'

import FormErrorMessage from '../common/FormErrorMessage'
import { KeyIconSvg } from '../common/KeyIconSvg'
import NiceButton from '../common/NiceButton'
import axiosClient from '../service/axiosClient'

interface ChangePswDTO {
    currentPassword: string
    password: string
    confirmPassword: string
}

function ChangePswForm() {
    const [currentPasswordShown, setCurrentPasswordShown] = useState(false)
    const [passwordShown, setPasswordShown] = useState(false)
    const [confirmPasswordShown, setConfirmPasswordShown] = useState(false)
    const [emailSettings, setEmailSettings] = useState<ChangePswDTO>({
        'currentPassword': '',
        'password': '',
        'confirmPassword': '',
    })
    const {t} = useTranslation()

    const submit = async (values: ChangePswDTO, actions: FormikHelpers<ChangePswDTO>) => {
        console.log('values', values)
        try {
            const response = await axiosClient.post('auth/change-password', {
                oldPassword: values.currentPassword,
                newPassword: values.password
            })
            actions.resetForm()
            toast.success('Heslo bolo úspešne aktualizované')
        } catch (error: any) {
            toast.error('Heslo sa neporadilo uložiť')
        }
    }

    const validate = (values: ChangePswDTO) => {
        const errors: Partial<ChangePswDTO> = {}

        if (!values.currentPassword) {
            errors.currentPassword = t('required_field_error')
        }

        if (!values.password) {
            errors.password = t('required_field_error')
        }

        if (!values.confirmPassword) {
            errors.confirmPassword = t('required_field_error')
        }
        if (values.password !== values.confirmPassword) {
            errors.confirmPassword = t('Heslá sa nezhodujú')
        }

        return errors
    }

    return (
        <Formik
            initialValues={emailSettings}
            enableReinitialize
            validate={validate}
            onSubmit={submit}
        >{({values, setFieldValue, isSubmitting}) => (<>
            <Form>

                <div className="form-group">
                    <label htmlFor="currentPassword">Súčasné heslo</label>
                    <div className="input-group show-psw-input-group">
                        <Field id="currentPassword" type={currentPasswordShown ? 'text' : 'password'}
                               name="currentPassword" className="form-control"/>
                        <div className="input-group-append">
                            <button onClick={(e: any) => setCurrentPasswordShown(!passwordShown)}
                                    className="" type="button"
                                    title={currentPasswordShown ? 'Skryť heslo' : 'Zobraziť heslo'}>
                                <KeyIconSvg/>
                            </button>
                        </div>
                    </div>
                    <FormErrorMessage name="currentPassword"/>
                </div>
                <div className="form-group">
                    <label htmlFor="password">Zvoľ heslo*</label>
                    <div className="input-group show-psw-input-group">
                        <Field id="password" type={passwordShown ? 'text' : 'password'}
                               name="password" className="form-control"/>
                        <div className="input-group-append">
                            <button onClick={(e: any) => setPasswordShown(!passwordShown)}
                                    className="" type="button"
                                    title={passwordShown ? 'Skryť heslo' : 'Zobraziť heslo'}>
                                <KeyIconSvg/>
                            </button>
                        </div>
                    </div>
                    <FormErrorMessage name="password"/>
                </div>
                <div className="form-group">
                    <label htmlFor="confirmPassword">Zadaj heslo znova*</label>
                    <div className="input-group show-psw-input-group">
                        <Field id="confirmPassword"
                               type={confirmPasswordShown ? 'text' : 'password'}
                               name="confirmPassword" className="form-control"/>
                        <div className="input-group-append">
                            <button
                                onClick={(e: any) => setConfirmPasswordShown(!confirmPasswordShown)}
                                className="" type="button"
                                title={confirmPasswordShown ? 'Skryť heslo' : 'Zobraziť heslo'}>
                                <KeyIconSvg/>
                            </button>
                        </div>
                    </div>
                    <FormErrorMessage name="confirmPassword"/>
                </div>

                <NiceButton type="submit" isLoading={isSubmitting}>
                    Uložiť
                </NiceButton>
            </Form>
        </>)}

        </Formik>

    )
}

export default ChangePswForm
