import React from 'react'

import clsx from 'clsx'

interface Props {
    addTodo: (e: React.FormEvent<HTMLFormElement>) => void
    handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    inputValue: string
}

const AddTodo = ({inputValue, handleInputChange, addTodo}: Props) => {
    return (
        <form
            className={clsx('add-todo', {active: inputValue})}
            onSubmit={addTodo}
        >
            <label className="add-todo-icon icon" htmlFor="add-todo-input"></label>
            <div className="add-todo-input">
                <input
                    id="add-todo-input"
                    type="text"
                    placeholder="Nová úloha"
                    value={inputValue}
                    onChange={handleInputChange}
                />
            </div>
            <div className="add-todo-action">
                <button className="btn-reset btn-add" type="submit">
                    Ulož
                </button>
            </div>
        </form>
    )
}

export default AddTodo

/*

*/
