import styled from 'styled-components'

export const StyledInfoPageHeaderWrapper = styled.div`
  // margin-top: 90px;
  //height: 185px;
  //background: #C4CEDD;
  //display: flex;
  //align-items: center;
  //justify-content: center;
  margin-bottom: 20px;
  margin-top: 20px;

  h2 {
    padding: 0;
    color: #fff;
    background: none;
    text-align: center;
    -webkit-text-fill-color: initial;
  }

  @media only screen and (max-width: 991px) {
    margin-top: 60px;
  }
`

export const ClientFormWrapper = styled.div`
    padding: 24px;
    border-radius: 6px;
    background: #ffffff;
    //box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
`