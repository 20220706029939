export type InvoiceDTO = {
    'id': number,
    'invoiceId': string,
    'providerBusinessName': string,
    'providerStreet': string,
    'providerCity': string,
    'providerZipCode': string,
    'providerIco': string,
    'providerDic': string,
    'providerIcDph': string,
    'providerPhone': string,
    'providerEmail': string,
    'providerWeb': string,
    'providerBankName': string,
    'providerSwift': string,
    'providerIban': string,
    'providerBankAccountNumber': string,
    'providerBankCode': string,
    'providerDph7aParagraph': boolean,
    'providerVatPayer': boolean,
    'customerBusinessName': string,
    'customerFirstName': string,
    'customerLastName': string,
    'customerStreet': string,
    'customerCity': string,
    'customerZipCode': string,
    'customerIco': string,
    'customerDic': string,
    'customerIcDph': string,
    'customerPhone': string,
    'customerEmail': string,
    'customerWeb': string,
    'customerBankName': string,
    'customerSwift': string,
    'customerDph7aParagraph': boolean,
    'customerIban': string,
    'customerBankAccountNumber': string,
    'customerBankCode': string,
    'preItemText': string,
    'invoiceCreationDate': string,
    'invoiceDueDate': string,
    'serviceRealizationDate': string,
    'paymentType': PaymentType,
    'invoiceItems':  InvoiceItemDto[],
    'total': number,
    'dphValue': number,
    'totalWithDph': number,
    'totalWithoutDph': number,
    'invoiceCreatedByName': string,
    'invoiceCreatedByEmail': string,
    'referenceId': string,
    'constantId': string,
    'orderNumber': string,
    'orderDate': string,
    'createdAt': string,
    'logo': string,
    'signature': string,
    'updatedAt': string,
    'invoiceStatus': 'PAID' | 'UNPAID'
}


export enum PaymentType {
    CASH =  'CASH',
    BANK_TRANSFER = 'BANK_TRANSFER'
}

export type InvoiceItemDto = {
    'title': string,
    'quantity': number,
    'unitPrice': number,
    'price': number,
    'dph': number,
    'dphValue': number,
    'totalWithDph': number
    'totalWithoutDph': number
}

export interface InvoicesFilter {
    from: Date | null,
    to: Date | null
}

export type InvoiceFormData = Omit<InvoiceDTO, 'id'>
