import React from 'react'

import sk from 'date-fns/locale/sk'
import CookieBot from 'react-cookiebot'
import { registerLocale } from 'react-datepicker'
import { Toaster } from 'react-hot-toast'

import AppRouter from '../common/AppRouter'
import { AuthProvider } from '../common/Auth'
import { ContentWrapperContextConsumer, ContentWrapperProvider } from '../common/ContentWrapperProvider'
import ErrorMsg from '../common/ErrorMsg'
import './App.scss'
import 'bootstrap/dist/js/bootstrap.min.js'

registerLocale('sk', sk)

const domainGroupId = '32440453-b3db-4b7d-8d00-817c3eab1b87'

function App() {
    return (
        <>
            {process.env.REACT_APP_SHOW_COOKIEBOT === 'true' && <CookieBot domainGroupId={domainGroupId}/>}
            <div className="App d-flex flex-column h-100xx min-h-100vh">
                <AuthProvider>
                    <ContentWrapperProvider>
                        <ContentWrapperContextConsumer>
                            {(contentContext) => (<>
                                <AppRouter/>
                                <Toaster toastOptions={{
                                    duration: 10000,
                                }}/>
                                <ErrorMsg/>
                            </>)}
                        </ContentWrapperContextConsumer>
                    </ContentWrapperProvider>
                </AuthProvider>
            </div>
        </>
    )
}

export default App
