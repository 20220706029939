import { IdentifiableItemResponse } from './Common'
import SocialNetwork from './SocialNetwork'

export enum PublishFrequency {
    ONCE = 'ONCE',
    DAILY = 'DAILY',
    WEEKLY = 'WEEKLY',
    MONTHLY = 'MONTHLY'
}

export enum PostType {
    POST = 'POST',
    STORY = 'STORY',
    REEL = 'REEL'
}

export enum PostApprovalStatusType {
    APPROVED = 'APPROVED',
    CHANGE_REQUIRED = 'CHANGE_REQUIRED',
    DECLINED = 'DECLINED'
}

export enum PostStatusType {
    PUBLISHED = 'PUBLISHED',
    NOT_PUBLISHED = 'NOT_PUBLISHED'
}

type PostDTO = {
    id?: number
    title: string
    clientFirstName: string
    clientLastName: string
    description: string
    hashTags: string
    clientId: number
    socialNetwork: SocialNetwork
    postType: PostType
    postApprovalStatus: PostApprovalStatusType
    postStatus: PostStatusType
    publishFrom: string
    publishUntil: string
    publishAt: string
    photo: string
    showInCalendar: boolean
    publishFrequency: PublishFrequency
} & IdentifiableItemResponse

export interface PostFormData extends Omit<PostDTO, 'hashTags' | 'publishFrom' | 'publishUntil' | 'publishAt' | 'clientId' | 'id' | 'clientFirstName' | 'clientLastName'> {
    hashTags: string[],
    publishAt: string,
    clientId: string,
    publishFrom: Date | null,
    publishUntil: Date | null
}

export interface PostsFilter {
    clientId: string | null,
}


export default PostDTO