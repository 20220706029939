export enum NoteType {
    NOTE = 'NOTE',
    TODO = 'TODO'
}

export type TodoItem = {
    id: number,
    value: string,
    checked: boolean
}

export type Note = {
    id: number
    noteType: NoteType,
    todoItems?: TodoItem[],
    content: string,
    color: string
    position: number
}
