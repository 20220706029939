export enum ReminderUnit {
    MINUTES = 'MINUTES', HOURS = 'HOURS', DAYS = 'DAYS'
}

export type CalendarEvent = {
    id: number
    description: string
    eventType: CalendarEvent
    name: string
    guestsEmailAddresses: string
    color: string
    meetingLink: string
    street: string
    city: string
    zipCode: string
    country: string
    entryDate: string
    startedAt: string
    endedAt: string
    firstName: string
    lastName: string
    reminder: string
    reminderUnit: ReminderUnit
    clientId: number
}

export type CalendarEventFormData = Omit<CalendarEvent, 'id' | 'eventType' | 'clientId' | 'guestsEmailAddresses' | 'firstName' | 'lastName'> & {
    eventType: number
    clientId: string
    guestsEmailAddresses: string[]
}

export type CalendarComponentEvent = {
    id: number,
    title: string,
    color: string,
    start: Date,
    end: Date,
    desc?: string,
    allDay?: boolean,
}

export type CalendarEventType = {
    id: number,
    name: string
    color?: string
    isSystemType: boolean
}