import React, { useEffect, useState } from 'react'

import DatePicker from 'react-datepicker'
import { useNavigate } from 'react-router-dom'

import amaniPointing from '../assets/amani_pointing.png'
import axiosClient from '../service/axiosClient'
import { CalendarEvent } from '../types/Calendar'
import { PageableResponse } from '../types/Common'

export const SideCalendar = () => {
    const [startDate, setStartDate] = useState(new Date())
    const [myEvents, setEvents] = useState<CalendarEvent[]>([])
    const navigate = useNavigate()

    useEffect(() => {
        if (startDate) {
            console.log('downloading events')
            axiosClient.get<CalendarEvent[]>('calendar-event/for-date', {
                params: {
                    date: startDate.toISOString().split('T')[0]
                }
            })
                .then(response => {
                    console.log('res', response)
                    // const eventsTmp: CalendarEvent[] = []
                    // response.data.content.forEach(item => {
                    //     const start = new Date(item.entryDate + 'T' + item.startedAt)
                    //     const end = new Date(item.entryDate + 'T' + item.endedAt)
                    //
                    //     eventsTmp.push({
                    //         id: item.id as number,
                    //         title: item.title,
                    //         start: start,
                    //         end: end,
                    //         color: item.color,
                    //         // allDay: true
                    //     })
                    // })
                    // console.log('eventsTmp', eventsTmp)
                    // setEvents(eventsTmp)
                    setEvents(response.data)
                })
        }
    }, [startDate])

    return (
        <div className={'side-calendar'}>
            {/*<h5>Kalendár</h5>*/}
            <DatePicker
                selected={startDate}
                locale="sk"
                dateFormat="dd.MM.yyyy"
                onChange={(date) => {
                    if (date) {
                        setStartDate(date)
                    }
                }}
                inline
            />

            {/*<div className="side-calendar-day-title">*/}
            {/*    {startDate.toLocaleDateString()}*/}
            {/*</div>*/}
            <div className={'d-flex flex-row mt-3'}>
                <div>

                <img width={60} src={amaniPointing}/>
                </div>
                <div>
                    {/*<h5 className={'mt-1 mb-1'}>Udalosti</h5>*/}
                    {/*<h5 className={'mt-1 mb-1'}>&nbsp;</h5>*/}
                    <div className="current-date">{startDate.toLocaleDateString('sk-SK')}</div>
                </div>

            </div>

            <div className="event-wrapper">
                {Array.isArray(myEvents) && myEvents.length ? myEvents.map(event => (
                    <div
                        onClick={() => navigate('/app/calendar/edit/' + event.id)}
                        key={event.id}
                        className="event-item cursor-pointer">
                        <div className="time" style={{backgroundColor: event.color}}>{event.startedAt.slice(0, -3)}</div>
                        <div className="info">
                            <div className="client">{`${event.firstName} ${event.lastName}`}</div>
                            <div className="title">{event.name}</div>
                        </div>
                    </div>
                )) : <div className="no-events">Žiadne udalosti</div>}

                {/*<div className="event-item">*/}
                {/*    <div className="time">08:00</div>*/}
                {/*    <div className="info">*/}
                {/*        <div className="client">Ranný beh</div>*/}
                {/*        <div className="title">Beh v parku</div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div className="event-item">*/}
                {/*    <div className="time">12:00</div>*/}
                {/*    <div className="info">*/}
                {/*        <div className="client">Ranný beh</div>*/}
                {/*        <div className="title">Beh v parku</div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div className="event-item">*/}
                {/*    <div className="time">16:30</div>*/}
                {/*    <div className="info">*/}
                {/*        <div className="client">Ranný beh</div>*/}
                {/*        <div className="title">Beh v parku</div>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        </div>
    )
}