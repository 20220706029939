import axios from 'axios'
import { debounce } from 'lodash'
import { parse } from 'tinyduration'

import axiosClient from '../service/axiosClient'
import { CorporateBody, CorporateOption } from '../types/Corporate'


export function createDate(days: number, months: number, years: number) {
    const date = new Date()
    date.setDate(date.getDate() + days)
    date.setMonth(date.getMonth() + months)
    date.setFullYear(date.getFullYear() + years)
    return date
}

export function getDuration(durationString: string) {
    const durationObj = parse(durationString)

    return durationObj
}

export function dateToIsoString(date: Date): string {

    const utcTimeInMilliseconds = date.getTime()

    // Get the local timezone offset from UTC in milliseconds
    const timezoneOffsetInMilliseconds = date.getTimezoneOffset() * 60 * 1000

    // Create a new date subtracting the timezone offset from the local time
    const dateMinusTimezoneOffset = new Date(utcTimeInMilliseconds - timezoneOffsetInMilliseconds)

    // Convert the adjusted date to an ISO string, which is the format the API expects
    const isoString = dateMinusTimezoneOffset.toJSON()

    return isoString
}

export function generateAndDownloadInvoicePdfFile(id: string, invoiceId: string) {
    axiosClient.get('/invoice/generate/' + id).then(response => {
        axiosClient({
            url: '/invoice/download/' + invoiceId,
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            // create file link in browser's memory
            const href = URL.createObjectURL(response.data)

            // create "a" HTML element with href to file & click
            const link = document.createElement('a')
            link.href = href
            link.setAttribute('download', 'faktura_' + invoiceId + '.pdf') //or any other extension
            document.body.appendChild(link)
            link.click()

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link)
            URL.revokeObjectURL(href)
        })
    })
}

export const money = new Intl.NumberFormat('sk-SK',
    {style: 'currency', currency: 'EUR'})

export   const loadOptionsForCompanySelector = debounce((
    inputValue: string,
    callback: (options: CorporateOption[]) => void
) => {
    axios.get('https://autoform.ekosystem.slovensko.digital/api/corporate_bodies/search', {
        params: {
            private_access_token: process.env.REACT_APP_SLOVENSKO_DIGITAL_API_KLUC,
            q: `name:${inputValue}`,
        }
    }).then((response) => {
        // console.log('responseee', response.data)
        callback(response.data.map((item: CorporateBody) => {
                return {
                    value: item,
                    label: item.name
                } as CorporateOption
            }
        ))
    })
}, 800)
