import React, { useEffect, useReducer, useState } from 'react'

import { isBoolean } from 'lodash'

import smartTableReducer, { initialSmartTableState } from './reducer'
import { SmartTableProvider } from './TableContext'
import SmartTablePagination from './TablePagination'
import { SmartTableReducerActionType, SmartTableWrapperProps, SortDir, } from './types'
import { IdentifiableItemResponse, PagingRequest } from '../../types/Common'

/*
* EXAMPLE:
*
* type TestTableItem = { id: number; title: string }
*
<FndtTableWrapper<TestTableItem>
    loadDataFn={(queryParams: PagingRequest) => {
        const pageNumber = queryParams.pageStart || 0
        const pageSize = queryParams.pageSize || 15
        return new Promise<PageableResponse<TestTableItem>>(
            (resolve) => {
                axios
                    .get(
                        'https://jsonplaceholder.typicode.com/posts'
                    )
                    .then((response: any) => {
                        const totalElements =
                            response.data.length
                        response.data.sort(
                            dynamicSort(
                                (queryParams.sortDesc
                                    ? '-'
                                    : '') + queryParams.sortBy
                            )
                        )
                        const paginatedData =
                            response.data.slice(
                                pageNumber * pageSize,
                                pageNumber * pageSize + pageSize
                            )
                        return resolve({
                            hasNextPage: true,
                            totalElements: totalElements,
                            elements: paginatedData,
                        })
                    })
            }
        )
    }}>
        <FndtTable<TestTableItem>
            HeadRow={
                <>
                    <FndtTableHeadCell sort={'id'}>
                        ID#
                    </FndtTableHeadCell>
                    <FndtTableHeadCell
                        sort={'title'}
                        className="text-end">
                        Title
                    </FndtTableHeadCell>
                </>
            }
            renderRow={(item) => {
                return (
                    <>
                        <td>{item.id}</td>
                        <td className="text-end">{item.title}</td>
                    </>
                )
            }}
            />
</FndtTableWrapper>
* */
export const SmartTableWrapper = <T extends IdentifiableItemResponse, T2>({
                                                                              initialPage = 1,
                                                                              initialPageSize = 15,
                                                                              initialSortBy = 'id',
                                                                              initialSortDir = SortDir.Asc,
                                                                              loadDataFn,
                                                                              filter,
                                                                              children,
                                                                          }: SmartTableWrapperProps<T, T2>) => {
    const [state, dispatch] = useReducer(smartTableReducer, initialSmartTableState)
    const [totalPages, setTotalPages] = useState(0)
    const [currentPage, setCurrentPage] = useState(initialPage)
    const [currentPageSize, setCurrentPageSize] = useState(initialPageSize)
    const [currentSortBy, setCurrentSortBy] = useState(initialSortBy)
    const [currentSortDir, setCurrentSortDir] = useState(initialSortDir)
    const isCalling = React.useRef<string>('')
    useEffect(() => {
        load()
    }, [currentSortDir, currentSortBy])

    useEffect(() => {
        setCurrentPage(1)
        load({
            pageStart: 0
        })
    }, [filter])

    function onSort(by: string, dir: SortDir) {
        setCurrentSortBy(by)
        setCurrentSortDir(dir)
    }

    function load(
        pagingParamsOverrides: Partial<PagingRequest> = {} as PagingRequest,
        isSilent = false
    ) {
        const pagingParams = {
            sortBy: currentSortBy,
            sortDesc: currentSortDir === SortDir.Desc,
            pageStart: currentPage - 1,
            pageSize: currentPageSize,
            ...pagingParamsOverrides,
        }

        if (isCalling && isCalling.current === JSON.stringify(pagingParams)) {
            return
        }
        isCalling.current = JSON.stringify(pagingParams)

        if (!isSilent) {
            dispatch({
                type: SmartTableReducerActionType.LOAD_INIT,
            })
        }

        try {
            loadDataFn(pagingParams)
                .then((result) => {
                    isCalling.current = ''
                    if (
                        isSilent &&
                        JSON.stringify(result.content) ===
                        JSON.stringify(state.entries)
                    ) {
                        // when it is silent table refresh (eg. websocket/polling) and the result is the same as it is currently rendered,
                        // don't update UI
                        return
                    }
                    setTotalPages(
                        Math.ceil(result.totalElements / pagingParams.pageSize)
                    )
                    dispatch({
                        type: SmartTableReducerActionType.LOAD_SUCCESS,
                        payload: {
                            content: result.content,
                            totalElements: result.totalElements,
                            ...(isBoolean(result.hasNextPage)
                                ? {hasNextPage: result.hasNextPage}
                                : null),
                        },
                    })
                })
                .catch((e) => {
                    isCalling.current = ''
                    setTotalPages(0)
                    dispatch({
                        type: SmartTableReducerActionType.LOAD_ERROR,
                    })
                    console.error(
                        'Error occurred when calling Smart Table Load Data function',
                        e
                    )
                })
        } catch (e) {
            dispatch({
                type: SmartTableReducerActionType.LOAD_ERROR,
            })
            console.error(
                'Error in Smart Table Load Data function. It must return a promise with object of {content: Array<any>,  totalElements: Number}'
            )
        }
    }

    const smartTableApi = {
        load,
        total: state.total,
        hasNextPage: state.hasNextPage,
        page: currentPage,
        pageSize: currentPageSize,
        isLoading: state.isLoading,
        entries: state.entries,
        error: state.error,
        currentSortBy,
        currentSortDir,
        onSort,
    }
    return (
        <SmartTableProvider value={smartTableApi}>
            {children}
            <SmartTablePagination
                page={currentPage}
                totalPages={totalPages}
                pageSize={currentPageSize}
                onPageChange={(newPage) => {
                    setCurrentPage(newPage)
                    load({
                        pageStart: newPage - 1,
                    })
                }}
                onPageSizeChange={(newPageSize) => {
                    setCurrentPage(1)
                    setCurrentPageSize(newPageSize)
                    load({
                        pageStart: 0,
                        pageSize: newPageSize,
                    })
                }}
            />
        </SmartTableProvider>
    )
}
