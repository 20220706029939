import React, { useState } from 'react'

import AddTodo from './AddTodo'
import Todo from './Todo'
import { TodoItem } from '../types/Note'
import './todos.scss'

interface Props {
    todos: TodoItem[]
    delTodo: (id: number) => void
    toggleTodoDone: (id: number, newState: boolean) => void
    editTodo: (todo: TodoItem) => void
    addTodo: (text: string) => void
}

export const Todos = ({todos, delTodo, toggleTodoDone, editTodo, addTodo}: Props) => {
    const [inputValue, setInputValue] = useState('')

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value)
    }

    const handleAddTodoFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        addTodo(inputValue)
        // /note/add/todo-item

        // setTodos([
        //     ...todos,
        //     {
        //         id: uuidv4(),
        //         title: inputValue.trim(),
        //         isDone: false,
        //         isStar: false
        //     }
        // ]);

        setInputValue('')
    }

    return (<div className="todos-wrapper">
        <AddTodo
            inputValue={inputValue}
            handleInputChange={handleInputChange}
            addTodo={handleAddTodoFormSubmit}
        />

        <div className="todos">
            {todos
                .map((todo, index) => (
                    <Todo
                        todo={todo}
                        delTodo={delTodo}
                        editTodo={editTodo}
                        toggleTodoDone={toggleTodoDone}
                        key={todo.id}
                    />
                ))}
        </div>
    </div>)
}