import { Reducer } from 'react'

import { isBoolean } from 'lodash'

import { SmartTableReducerAction, SmartTableReducerActionType, SmartTableWrapperStateType } from './types'

export const initialSmartTableState: SmartTableWrapperStateType = {
    total: 0,
    entries: [],
    hasNextPage: false,
    isLoading: false,
    error: false,
}

const smartTableReducer: Reducer<SmartTableWrapperStateType, SmartTableReducerAction> = (
    state,
    action
): SmartTableWrapperStateType => {
    switch (action.type) {
        case SmartTableReducerActionType.LOAD_INIT:
            return {
                ...state,
                error: false,
                hasNextPage: false,
                isLoading: true,
            }
        case SmartTableReducerActionType.LOAD_SUCCESS:
            return {
                isLoading: false,
                total: action?.payload?.totalElements || initialSmartTableState.total,
                entries: action?.payload?.content || initialSmartTableState.entries,
                hasNextPage: !!(isBoolean(action?.payload?.hasNextPage)
                    ? action?.payload?.hasNextPage
                    : false),
                error: false,
            }
        case SmartTableReducerActionType.LOAD_ERROR:
            return {
                ...initialSmartTableState,
                error: true,
            }
        default:
            throw new Error()
    }
}

export default smartTableReducer
