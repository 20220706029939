import React, { useState } from 'react'

import clsx from 'clsx'
import { SketchPicker } from 'react-color'
import styled from 'styled-components'

import { Todos } from './Todos'
import { NoteType, TodoItem } from '../types/Note'

type Props = {
    // note: Note
    type: NoteType,
    todoItems?: TodoItem[],
    content: string
    color: string
    onRemoveClick: () => void
    // onChange: ({content, color}: { content?: string, color?: string }) => void
    onContentChange: (content: string) => void
    onColorChange: (color: string) => void
    onAddTodoItem: (text: string) => void
    onDeleteTodoItem: (todoId: number) => void
    onToggleTodoItem: (todoId: number, newState: boolean) => void
    onEditTodoItem: (editedTodo: TodoItem) => void
}

export const StickyNote = ({
                               content,
                               color,
                               onContentChange,
                               onColorChange,
                               onRemoveClick,
                               onAddTodoItem,
                               onDeleteTodoItem,
                               onToggleTodoItem,
                               onEditTodoItem,
                               todoItems,
                               type
                           }: Props) => {
    const [isColorpickerOpen, setIsColorpickerOpen] = useState(false)
    // const [noteColor, setNoteColor] = useState(note.color)
    // const [noteColor, setNoteColor] = useState(color)

    return (
        <StyledNote>
            <div
                className={clsx('post-it', {'is-todo': type === NoteType.TODO})}
                style={{backgroundColor: color}}>
                <i className="change-color bi bi-palette" onClick={() => setIsColorpickerOpen(true)}></i>
                {isColorpickerOpen ? <div className="color-picker-wrapper">
                    <div className="color-picker-subwrapper"
                         onClick={() => {
                             console.log('helo')
                             setIsColorpickerOpen(false)
                         }}/>
                    <SketchPicker
                        onChange={(color) => {
                            console.log('selected', color)
                            onColorChange(color.hex)
                            // setNoteColor(color.hex)
                        }}
                        color={color || '#EBCD87'}
                        disableAlpha={true}/>
                    {/*<GithubPicker*/}
                    {/*    onChange={(color) => {*/}
                    {/*        console.log('selected', color)*/}
                    {/*        onChange({color: color.hex})*/}
                    {/*        setNoteColor(color.hex)*/}
                    {/*    }}*/}
                    {/*    triangle={'top-right'}*/}
                    {/*    colors={colors}/>*/}
                </div> : null}

                <i onClick={() => onRemoveClick()} className="remove-note bi bi-x"></i>
                {type === NoteType.NOTE ?
                    <textarea
                        defaultValue={content}
                        onChange={e => {
                            console.log('onInput', e.target.value)
                            onContentChange(e.target.value || '')
                        }}/> : <>
                        <Todos
                            addTodo={(text) => {
                                onAddTodoItem(text)
                            }}
                            todos={todoItems ? todoItems : []}
                            delTodo={(id) => {
                                onDeleteTodoItem(id)
                                // /note/todo-items/{todoItemId}
                            }}
                            toggleTodoDone={(id, newState) => {
                                onToggleTodoItem(id, newState)
                            }}
                            editTodo={(editedTodo) => {
                                onEditTodoItem(editedTodo)
                            }}/>
                        {/*{todoItems?.map((todoItem) => (*/}
                        {/*    <div key={todoItem.id}>*/}
                        {/*        <input type="checkbox" checked={todoItem.checked}/>*/}
                        {/*        <span>{todoItem.value}</span>*/}
                        {/*    </div>*/}
                        {/*))}*/}
                    </>}
                {/*<p*/}
                {/*    contentEditable={true}*/}
                {/*    onInput={e => {*/}
                {/*        console.log('onInput', e.currentTarget.textContent)*/}
                {/*        onChange({content: e.currentTarget.textContent || ''})*/}
                {/*    }}>*/}
                {/*    {note.content}*/}
                {/*</p>*/}
            </div>
        </StyledNote>
    )
}

const StyledNote = styled.div`
    width: 100%;
    .color-picker-subwrapper {
        position: fixed;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
    }

    .color-picker-wrapper {
        position: absolute;
        right: 15px;
        margin-top: 10px;
        z-index: 2;
    }

    .change-color {
        position: absolute;
        top: 5px;
        line-height: 0;
        right: 23px;
        font-size: 12px;
        cursor: pointer;
    }

    .remove-note {
        position: absolute;
        top: 2px;
        line-height: 0;
        right: 8px;
        cursor: pointer;

        //visibility: hidden;
        //opacity: 0;
        //transition: visibility 0s, opacity 0.4s linear;
    }

    //&:hover {
    //    .remove-note {
    //        visibility: visible;
    //        opacity: 1;
    //    }
    //}

    textarea {
        width: 100%;
        height: 100%;
        background: none;
        outline: none;
        border: none;
        resize: none;
        font-family: "Shantell Sans", cursive;
    }

    p {
        outline: none;
        height: 100%;
        height: 190px;
        overflow: auto;
        font-family: "Shantell Sans", cursive;
    }

    .post-it {
        //width: 285px;
        width: 100%;
        height: 285px;
        position: relative;
        background: #FFFAF0;
        //overflow: hidden;
        //margin: 30px auto;
        padding: 20px;
        line-height: 1.7em;
        font-size: 19px;

        color: #130d6b;

        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-left-radius: 5px;

        &.is-todo {
            padding: 20px 2px 5px 2px;
        }
    }

    .post-it li {
        cursor: pointer;
    }

    .post-it:after {
        right: 0;
        border-top-color: #DDC6C3;
        border-left-color: #DDC6C3;
        border-bottom-color: #F0F1F3;
        border-right-color: #F0F1F3;
        content: "";
        position: absolute;
        bottom: 0;
        border-width: 12px 12px 12px 12px;
        border-style: solid;
    }

    //.post-it:before {
    //    content: "";
    //    display: block;
    //    position: absolute;
    //    width: 20px;
    //    height: 25px;
    //    background: #ffa;
    //    box-shadow: 3px -2px 10px rgba(0, 0, 0, 0.2),
    //    inset 15px -15px 15px rgba(0, 0, 0, 0.3);
    //    left: 0;
    //    bottom: 0;
    //    z-index: 2;
    //    transform: skewX(25deg);
    //}



`