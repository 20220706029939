import React, { PropsWithChildren, Reducer, useContext, useEffect, useReducer, useRef } from 'react'

import { AxiosPromise } from 'axios'
import jwtDecode from 'jwt-decode'
import { GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

import axios from '../service/axiosClient'
import persistService from '../service/persistService'
import { Package } from '../types/gopay'

interface AuthStateType {
    user: User | null;
    isInitialised: boolean,
    isAuthenticated: boolean,
}

export interface AuthContextType extends AuthStateType {
    login: (email: string, password: string) => void;
    logout: (callback: VoidFunction) => void;
    register: (user: User) => AxiosPromise<User>;
    accountActivation: (token: string) => void;
    resetPassword: (newPassword: string, token: string) => void;
    changePassword: (oldPassword: string, newPassword: string) => void;
    forgotPassword: (email: string) => void;
    updateProfile: (user: Partial<User>) => void;
    updateUser: () => Promise<void>;
    verifyGoogleLogin: (response: GoogleLoginResponse | GoogleLoginResponseOffline) => void;
    verifyFacebookLogin: (response: any) => void;
}

enum ReducerActionType {
    INIT,
    LOGIN,
    LOGOUT,
    REGISTER,
    UPDATE_USER,
}

type ReducerAction = { type: ReducerActionType, payload?: any }

enum Role {
    ADMIN = 'ADMIN',
    USER = 'USER'
}

export enum Gender {
    MALE = 'MALE',
    FEMALE = 'FEMALE',
    NOT_SET = 'not_set',
}

export type User = {
    id?: number,
    email: string,
    username?: string,
    title?: string,
    firstName: string,
    lastName: string,
    fullName?: string,
    gender?: Gender,
    vatPayer?: boolean,
    insertsFromTheRegister?: string,
    dph7aParagraph?: boolean,
    startingInvoiceId?: number,
    signature?: string,
    logo?: string,
    businessName?: string,
    isCompany?: boolean,
    getdph7aParagraph?: boolean,
    billingStreet?: string,
    billingCity?: string,
    billingZipCode?: string,
    ico?: string,
    dic?: string,
    icDph?: string,
    bankName?: string,
    swift?: string,
    iban?: string,
    bankAccountNumber?: string,
    bankCode?: string,
    role?: Role,
    photo?: string | Blob,
    dateOfBirth?: Date,
    address?: {
        street: string;
        city: string;
        zipCode: string;
        country: string;
    },
    contact?: {
        countryPrefix: string;
        phoneNumber: string;
        email: string;
        web?: string;
    },
    password?: string,
    affiliateId?: string; // affiliate id from the url - sb
    selectedPackage?: Package
}

const initialState = {
    isInitialised: false,
    isAuthenticated: false,
    user: null,
}

const isValidToken = (accessToken: string): boolean => {
    if (!accessToken) {
        return false
    }

    const decodedToken: any = jwtDecode(accessToken)
    const currentTime = Date.now() / 1000

    console.log('decodedToken', decodedToken)
    console.log('currentTime', currentTime)
    console.log('decodedToken.exp > currentTime', decodedToken.exp > currentTime)
    //@ts-ignore
    return decodedToken.exp > currentTime
}

const API_PATHS = {
    refreshToken: '/auth/refreshtoken',
    getProfile: '/auth/current-user',
    updateProfile: '/user',
    login: '/auth/login',
    register: '/auth/register',
    changePassword: '/auth/password',
    resetPassword: '/auth/password-reset',
    accountActivation: '/auth/account-activation',
    forgotPassword: '/auth/invoke-password-reset',
}

const refreshTokenCall = () => {
    return axios.post(API_PATHS.refreshToken, {
        refreshToken: persistService.get('refreshToken'),
    })
}

export const getProfile = () => {
    return axios.get(API_PATHS.getProfile)
}

const reducer: Reducer<AuthStateType, ReducerAction> = (state, action): AuthStateType => {
    switch (action.type) {
        case ReducerActionType.INIT: {
            const {isAuthenticated, user} = action.payload

            return {
                ...state,
                isAuthenticated,
                user,
                isInitialised: true
            }
        }
        case ReducerActionType.LOGIN: {
            const {user} = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        case ReducerActionType.UPDATE_USER: {
            const {user} = action.payload

            return {
                ...state,
                user
            }
        }
        case ReducerActionType.LOGOUT: {
            return {
                ...state,
                isAuthenticated: false,
                user: null,
            }
        }
        case ReducerActionType.REGISTER: {
            const {user} = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        default: {
            return {...state}
        }
    }
}

export const AuthContext = React.createContext<AuthContextType>(null!)

export function AuthProvider({children}: { children: React.ReactNode }) {
    const [state, dispatch] = useReducer(reducer, initialState)
    const authStateRef = useRef<AuthStateType | null>(null)

    const setSession = (accessToken: string, user: User, refreshToken: string) => {
        authStateRef.current = {
            isAuthenticated: true,
            isInitialised: true,
            user: user
        }
        persistService.set('accessToken', accessToken)
        persistService.set('user', user)
        persistService.set('refreshToken', refreshToken)
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`

        axios.interceptors.response.use(
            (res) => {
                return res
            },
            async (err) => {
                const originalRequest = err.config

                if (err.response) {
                    if (err.response.status === 401 && originalRequest.url.includes(API_PATHS.refreshToken)) {
                        unsetSession()
                        dispatch({type: ReducerActionType.LOGOUT})

                        return Promise.reject(err)
                    }

                    // Access Token was expired
                    if (err.response.status === 401 && !originalRequest._retry && authStateRef.current?.isAuthenticated) {
                        originalRequest._retry = true
                        try {
                            // i++;
                            const rs = await refreshTokenCall()
                            const {accessToken, refreshToken} = rs.data

                            persistService.set('accessToken', accessToken)
                            persistService.set('refreshToken', refreshToken)
                            axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
                            originalRequest.headers.Authorization = `Bearer ${accessToken}`

                            return axios(originalRequest)
                        } catch (_error: any) {
                            if (_error.response && _error.response.data) {
                                return Promise.reject(_error.response.data)
                            }
                            return Promise.reject(_error)
                        }
                    }
                    if (err.response.status === 403 && err.response.data) {
                        return Promise.reject(err.response.data)
                    }
                }
                return Promise.reject(err)
            }
        )
    }

    const unsetSession = () => {
        authStateRef.current = {
            isAuthenticated: false,
            isInitialised: false,
            user: null
        }
        persistService.remove('accessToken')
        persistService.remove('user')
        persistService.remove('refreshToken')
        delete axios.defaults.headers.common.Authorization
    }

    const accountActivation = (token: string) => {
        return axios.patch(API_PATHS.accountActivation, {token})
    }

    const resetPassword = (password: string, token: string) => {
        return axios.post(API_PATHS.resetPassword, {newPassword: password, passwordResetToken: token})
    }

    const changePassword = (oldPassword: string, newPassword: string) => {
        return axios.patch(API_PATHS.changePassword, {oldPassword: oldPassword, newPassword: newPassword})
    }

    const forgotPassword = (email: string) => {
        return axios.get(API_PATHS.forgotPassword, {
            params: {
                email
            }
        })
    }

    const login = async (email: string, password: string) => {
        const response = await axios.post(API_PATHS.login, {email, password})
        const {accessToken, firstName, lastName, username, refreshToken, id} = response.data
        const userObj = {
            ...response.data,
            lastName,
            firstName,
            email: username,
            fullName: firstName + ' ' + lastName,
            id
        }
        dispatch({type: ReducerActionType.LOGIN, payload: {user: userObj}})
        setSession(accessToken, userObj, refreshToken)
    }

    const logout = (callback: VoidFunction) => {
        unsetSession()
        dispatch({type: ReducerActionType.LOGOUT})
        callback()
    }

    const register = (userDto: User): AxiosPromise => {
        // following code is for sending form data with image
        // const formData = new FormData()
        // Object.entries(userDto).forEach(entry => {
        //     const [key, value] = entry
        //     if (key === 'photo') {
        //         formData.append('photo', userDto.photo)
        //     } else if (key === 'dateOfBirth') {
        //         formData.append('dateOfBirth', userDto.dateOfBirth instanceof Date ? userDto.dateOfBirth.toISOString() : userDto.dateOfBirth || '')
        //     } else {
        //         formData.append(key, value as string)
        //     }
        // })
        //
        // return axios.post(API_PATHS.register, formData)


        return axios.post(API_PATHS.register, userDto)
    }

    const updateProfile = async (userDto: Partial<User>) => {
        // const formData = new FormData()
        // Object.entries(userDto).forEach(entry => {
        //     const [key, value] = entry
        //     if (key === 'photo' && userDto.photo) {
        //         formData.append('photo', userDto.photo)
        //     } else if (key === 'dateOfBirth') {
        //         formData.append('dateOfBirth', userDto.dateOfBirth instanceof Date ? userDto.dateOfBirth.toISOString() : userDto.dateOfBirth || '')
        //     } else {
        //         formData.append(key, value as string)
        //     }
        // })
        //
        // const response = await axios.put(API_PATHS.updateProfile, formData)
        const storedUser = persistService.get('user') as User
        console.log('storedUser', storedUser)
        const response = await axios.put(API_PATHS.updateProfile, userDto)
        await updateUser()
    }

    const updateUser = async (): Promise<void> => {
        const accessToken = persistService.get('accessToken')
        const refreshToken = persistService.get('refreshToken')
        const responseUser = await getProfile()
        const updatedUser = responseUser.data
        setSession(accessToken, updatedUser, refreshToken)
        console.log('updatedUser: ', updatedUser)
        dispatch({
            type: ReducerActionType.UPDATE_USER,
            payload: {user: {...updatedUser, fullName: updatedUser.firstName + ' ' + updatedUser.lastName}}
        })
    }

    const verifyGoogleLogin = async (response: GoogleLoginResponse | GoogleLoginResponseOffline) => {
        console.log('response from google', response)
        const responseFromApi = await axios.post('/auth/google/token/verify', {
            token: (response as GoogleLoginResponse).accessToken,
            idToken: (response as GoogleLoginResponse).tokenId
        })
        const {accessToken, user, refreshToken} = responseFromApi.data
        const userObj = {...user, fullName: user.firstName + ' ' + user.lastName}
        dispatch({type: ReducerActionType.LOGIN, payload: {user: userObj}})
        setSession(accessToken, user, refreshToken)
    }

    const verifyFacebookLogin = async (response: any) => {
        console.log('response from fb', response)
        const responseFromApi = await axios.post('/auth/facebook/token/verify', {
            token: response.accessToken,
            email: response.email,
            picture: response.picture?.data?.url
        })

        const {accessToken, user, refreshToken} = responseFromApi.data
        const userObj = {...user, fullName: user.firstName + ' ' + user.lastName}
        dispatch({type: ReducerActionType.LOGIN, payload: {user: userObj}})
        setSession(accessToken, user, refreshToken)
    }

    const value = {
        ...state,
        login,
        logout,
        register,
        updateProfile,
        updateUser,
        resetPassword,
        forgotPassword,
        changePassword,
        accountActivation,
        verifyGoogleLogin,
        verifyFacebookLogin
    }

    useEffect(() => {
        (async () => {
            try {
                const accessToken = persistService.get('accessToken')
                const refreshToken = persistService.get('refreshToken')
                const storedUser = persistService.get('user')

                if (accessToken && isValidToken(accessToken) && storedUser) {
                    setSession(accessToken, storedUser as User, refreshToken)
                    const response = await getProfile()
                    const user = response.data
                    dispatch({
                        type: ReducerActionType.INIT,
                        payload: {
                            isAuthenticated: true,
                            user: {...user, fullName: user.firstName + ' ' + user.lastName},
                        },
                    })
                } else if (refreshToken && isValidToken(refreshToken) && storedUser) {
                    const response = await refreshTokenCall()
                    const {accessToken, refreshToken, user} = response.data
                    setSession(accessToken, user as User, refreshToken)
                    dispatch({
                        type: ReducerActionType.INIT,
                        payload: {
                            isAuthenticated: true,
                            user: {...user, fullName: user.firstName + ' ' + user.lastName},
                        },
                    })
                } else {
                    unsetSession()
                    dispatch({
                        type: ReducerActionType.INIT,
                        payload: {
                            isAuthenticated: false,
                            user: null,
                        },
                    })
                }
            } catch (err: any) {
                unsetSession()
                dispatch({
                    type: ReducerActionType.INIT,
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                })
            }
        })()
    }, [])

    if (!state.isInitialised) {
        return <>Loading...</>
    }

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>)
}

export function useAuth() {
    return useContext(AuthContext)
}

export function RequireAuth({children}: PropsWithChildren<any>) {
    const auth = useAuth()
    const location = useLocation()

    if (!auth.isAuthenticated || !auth.user) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to="/login" state={{from: location}} replace/>
    }

    return <Outlet/>
}