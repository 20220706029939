import React from 'react'

import TimeAgo from 'javascript-time-ago'
import sk from 'javascript-time-ago/locale/sk.json'
import ReactDOM from 'react-dom'
import {BrowserRouter} from 'react-router-dom'

import App from './app/App'
import './common/i18n'
import './index.scss'

TimeAgo.addDefaultLocale(sk)
TimeAgo.addLocale(sk)

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </React.StrictMode>
    , document.getElementById('root')
)

