import React, { useEffect, useState } from 'react'

import moment from 'moment'
import DatePicker from 'react-datepicker'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

import { useAuth } from '../common/Auth'
import { getDuration, money } from '../common/helpers'
import { datePickerDateFormat } from '../common/i18n'
import axiosClient from '../service/axiosClient'
import ClientDTO from '../types/Client'
import { TimesheetFilter } from '../types/TSEntry'

interface Props {
    filter: TimesheetFilter
    setFilter: React.Dispatch<React.SetStateAction<TimesheetFilter>>
    totals: {
        totalDurationForCurrentPeriod: string,
        totalForCurrentPeriod: number
    }
}

export const TsListFilter = ({filter, setFilter, totals}: Props) => {
    const { updateUser } = useAuth()
    const navigate = useNavigate()
    const [clients, setClients] = useState<ClientDTO[]>([])

    useEffect(() => {
        axiosClient.get('client').then(response => {
            console.log('response', response)
            setClients(response.data)
        })
    }, [])

    function generateInvoice() {
        axiosClient({
            url: 'timesheet/create/invoice', //your url
            params: {
                clientId: filter.clientId,
                from: filter.from?.toISOString().slice(0, 10),
                until: filter.until?.toISOString().slice(0, 10),
            },
            method: 'GET',
        }).then((response) => {
            updateUser().finally(() => navigate('/app/invoices/edit/' + response.data))
        })
    }

    function exportReport() {
        const momentFrom = moment(filter.from)
        const momentTo = moment(filter.until)

        if (!momentFrom.isValid()) {
            toast.error('Dátum od nemá správnu hodnotu')
            return
        }
        if (!momentTo.isValid()) {
            toast.error('Dátum do nemá správnu hodnotu')
            return
        }

        axiosClient({
            url: 'timesheet/export',
            params: {
                clientId: filter.clientId,
                dateFrom: moment(filter.from).format('yyyy-MM-DD'),
                dateTo: moment(filter.until).format('yyyy-MM-DD')
            },
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            // create file link in browser's memory
            const href = URL.createObjectURL(response.data)

            // create "a" HTML element with href to file & click
            const link = document.createElement('a')
            link.href = href
            link.setAttribute('download', 'report.pdf') //or any other extension
            document.body.appendChild(link)
            link.click()

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link)
            URL.revokeObjectURL(href)
        })
    }

    return (
        <>
            <div className="rounded-content-body mb-4">
                <div className="row d-flex justify-content-center">
                    <div className="col-md-4">
                        <div
                            className={'form-group'}>
                            <label htmlFor="clientId">
                                Klient
                            </label>
                            <select
                                id="clientId"
                                name="clientId"
                                className="form-select"
                                onChange={(e) => {
                                    setFilter((old) => ({...old, clientId: e.target.value}))
                                }}>
                                <option value="">Vyberte</option>
                                {clients.map((option) => (
                                    <option key={option.id}
                                            value={option.id}>{option.companyName || (`${option.firstName} ${option.lastName}`)}</option>))}
                            </select>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label htmlFor="entryDate">Dátum od</label>
                            <DatePicker
                                scrollableYearDropdown
                                yearDropdownItemNumber={100}
                                locale="sk"
                                dateFormat={datePickerDateFormat}
                                showYearDropdown
                                selected={(filter.from && new Date(filter.from)) || null}
                                onChange={(value: any) => {
                                    setFilter((old) => ({...old, from: value}))
                                }}
                                id="from"
                                name="from"
                                className="form-control"/>
                        </div>

                    </div>

                    <div className="col-md-4">
                        <div className="form-group">
                            <label htmlFor="entryDate">Dátum do</label>
                            <DatePicker
                                scrollableYearDropdown
                                yearDropdownItemNumber={100}
                                locale="sk"
                                dateFormat={datePickerDateFormat}
                                showYearDropdown
                                selected={(filter.until && new Date(filter.until)) || null}
                                onChange={(value: any) => {
                                    setFilter((old) => ({...old, until: value}))
                                }}
                                id="until"
                                name="until"
                                className="form-control"/>
                        </div>

                    </div>

                </div>
                <div className="row">
                    {/*<div className="col-md-9">*/}
                    {/*    <strong>Celkovo*/}
                    {/*        odpracované:</strong> {totals.totalDurationForCurrentPeriod ? `${getDuration(totals.totalDurationForCurrentPeriod).hours ? getDuration(totals.totalDurationForCurrentPeriod).hours : 0}h ${getDuration(totals.totalDurationForCurrentPeriod).minutes ? getDuration(totals.totalDurationForCurrentPeriod).minutes : 0}m` : '-'}*/}
                    {/*    <br/>*/}
                    {/*    <strong>Celkový zárobok:</strong> {money.format(totals.totalForCurrentPeriod) || '-'}*/}
                    {/*</div>*/}
                    <div className="col-md-12 d-flex justify-content-end gap-2">
                        <div className="text-right pe-2">
                            <strong>Celkovo
                                odpracované:</strong> {totals.totalDurationForCurrentPeriod ? `${getDuration(totals.totalDurationForCurrentPeriod).hours ? getDuration(totals.totalDurationForCurrentPeriod).hours : 0}h ${getDuration(totals.totalDurationForCurrentPeriod).minutes ? getDuration(totals.totalDurationForCurrentPeriod).minutes : 0}m` : '-'}
                            <br/>
                            <strong>Celkový zárobok:</strong> {money.format(totals.totalForCurrentPeriod) || '-'}
                        </div>
                        <button onClick={exportReport} disabled={!filter.clientId}
                                className="w-auto btn amani-primary amani-small">Exportovať
                        </button>
                        <button
                            onClick={generateInvoice}
                            disabled={!filter.clientId}
                            className="ms-1 w-auto btn amani-primary amani-small">Vygenerovať faktúru
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}