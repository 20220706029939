import React, {useEffect, useRef, useState} from 'react'

import styled from 'styled-components'

import useOnClickOutside from './useOnClickOutside'
import xIcon from '../assets/x-btn-white.svg'
import emitter from '../service/emitter'

export default function ErrorMsg() {
    const ref = useRef<HTMLDivElement>(null)
    const [isShow, setIsShown] = useState(false)

    useOnClickOutside(ref, () => setIsShown(false))

    useEffect(() => {
        const onShowErr = () => {
            setIsShown(true)
        }
        emitter.on('show-server-err', onShowErr)

        return () => {
            emitter.off('show-server-err', onShowErr)
        }
    }, [])
    return <>
        {isShow && <StyledBackdrop>
            <div className="error-msg" ref={ref}>
                <img src={xIcon} alt="x" onClick={() => setIsShown(false)}/>
                Ojoj, malý problém...<br/>
                <span>...no ty za nič nemôžeš. Na riešení usilovne pracujeme. Chvíľu počkaj a skús to znova, prosím.</span>
            </div>
        </StyledBackdrop>}
    </>
}

const StyledBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.25);
  z-index: 1100;

  .error-msg {
    background: #CE1A2B;
    z-index: 1101;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 25px;
    text-align: center;
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;

    img {
      position: absolute;
      right: 5px;
      top: 5px;
      padding: 10px;
      color: #fff;
      cursor: pointer;
    }

    span {
      font-size: 14px;
      font-weight: 400;
    }
  }
`