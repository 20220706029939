import React, { useEffect } from 'react'

import { Link } from 'react-router-dom'

import { InvoiceForm } from './InvoiceForm'
import AppLayout from '../common/AppLayout'
import AppTitle from '../common/AppTitle'

export const InvoiceAddPage = () => {

    useEffect(() => {

    }, [])

    return (
        <AppLayout>
            <AppTitle append={<Link to={'/app/invoices'}>
                <button className="btn amani-primary">Späť</button>
            </Link>}>Nová faktúra</AppTitle>
            <InvoiceForm/>
        </AppLayout>
    )
}