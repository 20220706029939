import React, { useEffect, useState } from 'react'

import toast from 'react-hot-toast'

import { useAuth } from '../common/Auth'
import { ClientFormWrapper } from '../common/styledComponents'
import axiosClient from '../service/axiosClient'

// Define API_PATHS.promo link
const API_PATHS = {
    linkPath: `${process.env.REACT_APP_SERVER_URL}/affiliate/link`
}

const AffiliateLink = () => {
    const { user } = useAuth() // Get user
    const [data, setData] = useState('')

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (!API_PATHS.linkPath) {
                    throw new Error('API endpoint for promo link is not defined')
                }
                console.log('Fetching data from:', API_PATHS.linkPath) // Debug
                // Fetch data
                const response = await axiosClient.get(API_PATHS.linkPath)
                setData(response.data)
                console.log('Data fetched:', response.data) // Debug
            } catch (error) {
                console.error('Error fetching data:', error)
            }
        }

        fetchData()
    }, [user?.id])

    const handleCopy = () => {
      navigator.clipboard.writeText(data)
          .then(() => {
              console.log('Link copied to clipboard') // Debug
              toast.success('Odkaz bol skopírovaný do schránky')
          })
          .catch(err => {
              console.error('Failed to copy the link: ', err)
          })
  }


    return (
        <ClientFormWrapper>
            <span>
                Tvoj <strong>unikátny odkaz</strong>, ktorý môžeš zdieľať na svojom blogu, 
                sociálnych sieťach alebo e-mailových kampaniach.
            </span>
            <br></br>
            <br></br>
            <strong>Iba tvoj link</strong>
            {/** Affiliate Link */}
            <div>
                <button 
                  className="color-primary px-3 border border-3 rounded d-block"
                  onClick={handleCopy}>
                    {data}
                </button>
            </div>
        </ClientFormWrapper>
    )
}

export default AffiliateLink