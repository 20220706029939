import React, { useEffect } from 'react'

import 'react-multi-carousel/lib/styles.css'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { TSEntryForm } from './TSEntryForm'
import AppLayout from '../common/AppLayout'
import AppTitle from '../common/AppTitle'
import { useContentWrapper } from '../common/ContentWrapperProvider'

function TimesheetEditPage() {
    const params = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        if (isNaN(parseInt(params.entryId as string)) || parseInt(params.entryId as string) <= 0) {
            navigate('/app/timesheet')
        }
    }, [params.entryId])

    return (
        <AppLayout>
            <AppTitle append={<Link to={'/app/timesheet'}>
                <button className="btn amani-primary">Späť</button>
            </Link>}>Úprava záznamu #{params.entryId}</AppTitle>

            <div className="rounded-content-body p-3">
                <TSEntryForm id={parseInt(params.entryId as string)}/>
            </div>
        </AppLayout>
)
}

export default TimesheetEditPage
