import React, { useEffect, useState } from 'react'

import { Link, useSearchParams } from 'react-router-dom'

import { useAuth } from '../common/Auth'
import PublicLayout from '../common/PublicLayout'

function AccountActivationPage() {
    const {accountActivation} = useAuth()
    const [errorMsg, setErrorMsg] = useState<string>('')
    const [successMsg, setSuccessMsg] = useState<string>('')
    const [searchParams,] = useSearchParams()
    const accountActivationToken = searchParams.get('token')

    useEffect(() => {
        (async () => {
            if (!accountActivationToken) {
                return
            }
            try {
                const response = await accountActivation(accountActivationToken)
                setSuccessMsg('Účet bol úspešne aktivovaný. Môžeš sa prihlásiť')
            } catch (error: any) {
                setErrorMsg(error?.response?.data?.message || error)
            }
        })()

    }, [])


    return (
        <PublicLayout>
            <div className="row d-flex justify-content-center">
                <div className="col-md-5">
                    <h2 className="text-center mt-5">
                        {!accountActivationToken || errorMsg ? 'Nepodarilo sa ti aktivovať účet' : 'Účet bol úspešne aktivovaný. Môžeš sa prihlásiť'}
                    </h2>

                    <div className="text-center mt-5">
                        <Link to={'/login'} className="btn-amani not-uppercase not-bold">Prihlásenie</Link>
                    </div>
                </div>
            </div>
        </PublicLayout>
    )
}

export default AccountActivationPage
