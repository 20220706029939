import React, { useState } from 'react'

import { Field, Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'

import { useAuth } from '../common/Auth'
import FormErrorMessage from '../common/FormErrorMessage'

function ChangePasswordPage() {
    const { user, changePassword } = useAuth()
    const { t } = useTranslation()
    const [errorMsg, setErrorMsg] = useState<string>('')
    const [successMsg, setSuccessMsg] = useState<string>('')
    const [passwordShown, setPasswordShown] = useState(false)
    const [confirmPasswordShown, setConfirmPasswordShown] = useState(false)

    const submit = async (values: any, { resetForm }: any) => {
        console.log('values', values)
        setErrorMsg('')
        setSuccessMsg('')
        try {
            const response = await changePassword(values.oldPassword, values.password)
            setSuccessMsg('Heslo bolo úspešne aktualizované')
            resetForm()
        } catch (error: any) {
            setErrorMsg(error?.response?.data?.error || error)
        }
    }

    const validate = (values: any) => {
        const errors: any = {}

        if (!values.oldPassword.trim()) {
            errors.oldPassword = t('required_field_error')
        }

        if (!values.password.trim()) {
            errors.password = t('required_field_error')
        } else if (values.password.length < 4) {
            errors.password = 'Heslo musí mať viac ako 4 znaky'
        }

        if (!values.confirmPassword.trim()) {
            errors.confirmPassword = t('required_field_error')
        } else if (values.confirmPassword !== values.password) {
            errors.confirmPassword = 'Heslá sa musia zhodovať'
        }

        return errors
    }
    return (
        <div className="row d-flex justify-content-center">
            <div className="col-md-5">
                <h2 className={'text-center mb-5 osoba'}>Zmena hesla</h2>

                <Formik
                    initialValues={{
                        oldPassword: '', password: '', confirmPassword: ''
                    }}
                    validate={validate}
                    onSubmit={submit}
                >{({ values, setFieldValue }) => (<>
                    {/* <PrettyPrintJson data={values} /> */}

                    <Form>
                        <div className="form-group">
                            <label htmlFor="oldPassword">Staré heslo</label>
                            <Field id="oldPassword" type="password" name="oldPassword" className="form-control"/>
                            <FormErrorMessage name="oldPassword"/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">Nové heslo</label>
                            <div className="input-group show-psw-input-group">
                                <Field id="password" type={passwordShown ? 'text' : 'password'} name="password"
                                       className="form-control"/>
                                <div className="input-group-append">
                                    <button onClick={(e: any) => setPasswordShown(!passwordShown)} className=""
                                            type="button" title={passwordShown ? 'Skryť heslo' : 'Zobraziť heslo'}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"
                                             fill="currentColor" className="bi bi-key" viewBox="0 0 16 16">
                                            <path
                                                d="M0 8a4 4 0 0 1 7.465-2H14a.5.5 0 0 1 .354.146l1.5 1.5a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0L13 9.207l-.646.647a.5.5 0 0 1-.708 0L11 9.207l-.646.647a.5.5 0 0 1-.708 0L9 9.207l-.646.647A.5.5 0 0 1 8 10h-.535A4 4 0 0 1 0 8m4-3a3 3 0 1 0 2.712 4.285A.5.5 0 0 1 7.163 9h.63l.853-.854a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.793-.793-1-1h-6.63a.5.5 0 0 1-.451-.285A3 3 0 0 0 4 5"/>
                                            <path d="M4 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                            <FormErrorMessage name="password"/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="confirmPassword">Nové heslo znovu</label>
                            <div className="input-group show-psw-input-group">
                                <Field id="confirmPassword" type={confirmPasswordShown ? 'text' : 'password'}
                                       name="confirmPassword" className="form-control"/>
                                <div className="input-group-append">
                                    <button onClick={(e: any) => setConfirmPasswordShown(!confirmPasswordShown)}
                                            className="" type="button"
                                            title={confirmPasswordShown ? 'Skryť heslo' : 'Zobraziť heslo'}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"
                                             fill="currentColor" className="bi bi-key" viewBox="0 0 16 16">
                                            <path
                                                d="M0 8a4 4 0 0 1 7.465-2H14a.5.5 0 0 1 .354.146l1.5 1.5a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0L13 9.207l-.646.647a.5.5 0 0 1-.708 0L11 9.207l-.646.647a.5.5 0 0 1-.708 0L9 9.207l-.646.647A.5.5 0 0 1 8 10h-.535A4 4 0 0 1 0 8m4-3a3 3 0 1 0 2.712 4.285A.5.5 0 0 1 7.163 9h.63l.853-.854a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.793-.793-1-1h-6.63a.5.5 0 0 1-.451-.285A3 3 0 0 0 4 5"/>
                                            <path d="M4 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                            <FormErrorMessage name="confirmPassword"/>
                        </div>

                        {errorMsg && <div className="text-danger form-text text-center mb-2">{errorMsg}</div>}
                        {successMsg &&
                            <div className="text-success form-text text-center mb-2">{successMsg}</div>}

                        <button type="submit" className="btn btn-primary btn-amani">
                            Zmeniť
                        </button>
                    </Form>
                </>)}

                </Formik>
            </div>
        </div>
    )
}

export default ChangePasswordPage
