import React, { useEffect, useState } from 'react'

import { Field, Form, Formik, FormikHelpers } from 'formik'
import { pick } from 'lodash'
import { Col, Row } from 'react-bootstrap'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import AsyncSelect from 'react-select/async'

import { Gender, getProfile, useAuth, User } from '../common/Auth'
import FormErrorMessage from '../common/FormErrorMessage'
import { loadOptionsForCompanySelector } from '../common/helpers'
import NiceButton from '../common/NiceButton'
import PrettyPrintJson from '../common/PrettyPrint'
import axiosClient, { SERVER_URL } from '../service/axiosClient'
import { CorporateBody, CorporateOption } from '../types/Corporate'

function UpdateProfileForm() {
    const {user, updateProfile} = useAuth()
    const {t} = useTranslation()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [key, setKey] = useState('initial')
    const [logoUrl, setLogoUrl] = useState<string>('')
    const [signatureUrl, setSignatureUrl] = useState<string>('')
    const [defaultCompanyName, setDefaultCompanyName] = useState<string>('')

    const defaultValues = {
        iban: user?.iban,
        swift: user?.swift,
        isCompany: user?.isCompany,
        businessName: user?.businessName,
        billingStreet: user?.billingStreet,
        billingCity: user?.billingCity,
        billingZipCode: user?.billingZipCode,
        icDph: user?.icDph,
        dic: user?.dic,
        ico: user?.ico,
        // email: user?.email  ,
        // password: '',
        // confirmPassword: '',
        title: user?.title,
        firstName: user?.firstName,
        lastName: user?.lastName,
        city: user?.address?.city,
        zipCode: user?.address?.zipCode,
        country: user?.address?.country,
        street: user?.address?.street,
        web: user?.contact?.web,
        phoneNumber: user?.contact?.phoneNumber,
        gender: user?.gender,
        logo: user && user.logo ? user.logo : '',
        signature: user && user.signature ? user.signature : '',
        // photo: null,
        // dateOfBirth: null,
        getdph7aParagraph: user?.getdph7aParagraph || false,
        vatPayer: user?.vatPayer,
        startingInvoiceId: user && user.startingInvoiceId ? user.startingInvoiceId : '',
    }


    useEffect(() => {
        getProfile()
    }, [])

    useEffect(() => {
        if (user?.logo) {
            setLogoUrl(`${SERVER_URL}/storage/${user.logo}`)
        }
        if (user?.signature) {
            setSignatureUrl(`${SERVER_URL}/storage/${user.signature}`)
        }

        setDefaultCompanyName(user?.businessName || '')
    }, [user])

    const submit = async (values: typeof defaultValues, actions: FormikHelpers<typeof defaultValues>) => {
        console.log('values', values)
        setIsLoading(true)
        try {
            const response = await updateProfile({
                ...pick(values, ['firstName', 'lastName', 'title', 'gender', 'startingInvoiceId', 'iban', 'swift', 'logo', 'signature']),
                ...(values.isCompany ? {
                    isCompany: values.isCompany,
                    businessName: values.businessName,
                    getdph7aParagraph: values.getdph7aParagraph,
                    vatPayer: values.vatPayer,
                    billingStreet: values.billingStreet,
                    billingCity: values.billingCity,
                    billingZipCode: values.billingZipCode,
                    ico: values.ico,
                    icDph: values.icDph,
                    dic: values.dic,
                } : {
                    address: {
                        street: values.street,
                        city: values.city,
                        zipCode: values.zipCode,
                        country: values.country,
                    }
                }),
                contact: {
                    countryPrefix: '+421',
                    phoneNumber: values.phoneNumber,
                    web: values.web,
                }
            } as Partial<User>)
            actions.resetForm()
            setKey(new Date().toString())
            toast.success('Profil bol úspešne aktualizovaný')
        } catch (error: any) {
            toast.error('Profil sa nepodarilo aktualizovať')
        } finally {
            setIsLoading(false)
        }
    }

    const validate = (values: typeof defaultValues) => {
        const errors: any = {}

        if (!values.firstName?.trim()) {
            errors.firstName = t('required_field_error')
        }

        if (!values.lastName?.trim()) {
            errors.lastName = t('required_field_error')
        }
        if (values.isCompany) {
            if (!values.businessName?.trim()) {
                errors.businessName = t('required_field_error')
            }
            if (!(new String(values.ico)).trim()) {
                errors.ico = t('required_field_error')
            }
            if (!(new String(values.dic)).trim()) {
                errors.dic = t('required_field_error')
            }
            if (!values.billingStreet?.trim()) {
                errors.billingStreet = t('required_field_error')
            }
            if (!values.billingCity?.trim()) {
                errors.billingCity = t('required_field_error')
            }
            if (!values.billingZipCode?.trim()) {
                errors.billingZipCode = t('required_field_error')
            }

            if (values.vatPayer || values.getdph7aParagraph) {
                if (!values.icDph || !(new String(values.icDph)).trim()) {
                    errors.icDph = t('required_field_error')
                }
            }

        } else {
            if (!values.street?.trim()) {
                errors.street = t('required_field_error')
            }
            if (!values.city?.trim()) {
                errors.city = t('required_field_error')
            }
            if (!values.zipCode?.trim()) {
                errors.zipCode = t('required_field_error')
            }
        }
        console.log('errors', errors)
        return errors
    }

    return (
        <Formik
            initialValues={defaultValues}
            enableReinitialize
            validate={validate}
            onSubmit={submit}
        >{({values, setFieldValue}) => (<>
            <Form>
                <Row>
                    <Col>
                        <div className="form-group">
                            <label
                                htmlFor="logo">{'Logo'}</label>
                            {logoUrl ?
                                <>
                                    <img key={key} src={logoUrl as unknown as string}
                                         style={{width: '100px', display: 'block'}}/>
                                    <button
                                        onClick={() => {
                                            setLogoUrl('')
                                            setFieldValue('logo', '')
                                        }}
                                        type="button"
                                        className="button btn btn-primary btn-amani mt-3 mb-2">{'Vymazať logo'}</button>
                                </> : null}

                            <label className="btn btn-like-input d-block mt-1"
                                   htmlFor="logo">{'Nahrať logo'}</label>
                            <input className={'d-none'} id="logo" key={key} name="logo" type="file"
                                   onChange={(event) => {
                                       if (event.target.files && event.target.files[0]) {
                                           const formData = new FormData()
                                           formData.append('file', event.target.files[0])

                                           axiosClient.post('storage', formData).then((resp) => {
                                               console.log('resp', resp.data)
                                               setFieldValue('logo', resp.data)
                                               setLogoUrl(`${SERVER_URL}/storage/${resp.data}`)
                                           })
                                       }
                                   }}/>
                        </div>
                    </Col>
                    <Col>
                        <div className="form-group">
                            <label
                                htmlFor="signature">{'Podpis'}</label>
                            {signatureUrl ?
                                <>
                                    <img key={key} src={signatureUrl as unknown as string}
                                         style={{width: '100px', display: 'block'}}/>
                                    <button
                                        onClick={() => {
                                            setSignatureUrl('')
                                            setFieldValue('signature', '')
                                        }}
                                        type="button"
                                        className="button btn btn-primary btn-amani mt-3 mb-2">{'Vymazať podpis'}</button>
                                </> : null}

                            <label className="btn btn-like-input d-block mt-1"
                                   htmlFor="signature">{'Nahrať podpis'}</label>
                            <input className={'d-none'} id="signature" key={key} name="signature"
                                   type="file"
                                   onChange={(event) => {
                                       if (event.target.files && event.target.files[0]) {
                                           const formData = new FormData()
                                           formData.append('file', event.target.files[0])

                                           axiosClient.post('storage', formData).then((resp) => {
                                               console.log('resp', resp.data)
                                               setFieldValue('signature', resp.data)
                                               setSignatureUrl(`${SERVER_URL}/storage/${resp.data}`)
                                           })
                                       }
                                   }}/>
                        </div>
                    </Col>
                </Row>

                <h4 className="text-left mb-2">Meno</h4>

                <div className="form-group">
                    <label htmlFor="title">Titul</label>
                    <Field id="title" name="title" className="form-control"/>
                    <FormErrorMessage name="title"/>
                </div>

                <div className="form-group">
                    <label htmlFor="firstName">Meno*</label>
                    <Field id="firstName" name="firstName" className="form-control"/>
                    <FormErrorMessage name="firstName"/>
                </div>
                <div className="form-group">
                    <label htmlFor="lastName">Priezvisko*</label>
                    <Field id="lastName" name="lastName" className="form-control"/>
                    <FormErrorMessage name="lastName"/>
                </div>

                <div className="form-group">
                    <label>Pohlavie</label>
                    <div className="mb-3">
                        <div className="form-check form-check-inline">
                            <Field type="radio" id="gender-male" className="form-check-input"
                                   name="gender"
                                   value={Gender.MALE}/>
                            <label className="form-check-label" htmlFor="gender-male">
                                Muž
                            </label>
                        </div>
                        <div className="form-check form-check-inline">
                            <Field type="radio" id="gender-female" className="form-check-input"
                                   name="gender" value={Gender.FEMALE}/>
                            <label className="form-check-label" htmlFor="gender-female">
                                Žena
                            </label>
                        </div>
                    </div>
                    <FormErrorMessage name="gender"/>
                </div>

                <h4 className="text-left mb-2">Fakturačné údaje</h4>

                <div className="form-group form-check">
                    <Field type="checkbox" id="isCompany" className="form-check-input"
                           name="isCompany"/>
                    <label className="form-check-label" htmlFor={'isCompany'}>
                        Som firma
                    </label>
                </div>
                {values.isCompany ? <>
                    <div className="form-group">
                        <label htmlFor="businessName">Názov firmy</label>

                        <AsyncSelect
                            placeholder={'Vyhľadajte spoločnosť'}
                            className={'async-select'}
                            {...(defaultCompanyName ? {
                                value: {
                                    label: defaultCompanyName,
                                    value: {} as CorporateBody
                                } as CorporateOption
                            } : null)}
                            classNamePrefix={'async-select'}
                            loadingMessage={(inputValue) => <>Vyhľadávam...</>}
                            noOptionsMessage={(inputValue) => <>{inputValue.inputValue.length ? 'Nenašli sa žiadne výsledky' : 'Začnite písať...'}</>}
                            onChange={(item) => {
                                console.log('sel', item)
                                setDefaultCompanyName(item?.label as string)
                                setFieldValue('businessName', item?.label)
                                setFieldValue('ico', item?.value?.cin)
                                setFieldValue('dic', item?.value?.tin)
                                setFieldValue('icDph', item?.value?.vatin)
                                setFieldValue('billingStreet', item?.value?.formatted_street)
                                setFieldValue('billingCity', item?.value?.municipality)
                                setFieldValue('billingZipCode', item?.value?.postal_code)

                                setFieldValue('vatPayer', false)
                                setFieldValue('getdph7aParagraph', false)
                                if (item?.value?.vatin_paragraph === '§4') {
                                    setFieldValue('vatPayer', true)
                                    setFieldValue('getdph7aParagraph', false)
                                }
                                if (item?.value?.vatin_paragraph === '§7' || item?.value?.vatin_paragraph === '§7a') {
                                    setFieldValue('getdph7aParagraph', true)
                                    setFieldValue('vatPayer', false)
                                }
                            }}
                            cacheOptions
                            loadOptions={loadOptionsForCompanySelector}/>

                        <Field id="businessName" name="businessName" className="form-control d-none"/>
                        <FormErrorMessage name="businessName"/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="businessName">IČO</label>
                        <Field id="ico" name="ico" className="form-control"/>
                        <FormErrorMessage name="ico"/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="businessName">DIČ</label>
                        <Field id="dic" name="dic" className="form-control"/>
                        <FormErrorMessage name="dic"/>
                    </div>
                    <div className="form-group form-check">
                        <input
                            name="vatPayer"
                            onChange={(e) => {
                                setFieldValue('vatPayer', false)
                                setFieldValue('getdph7aParagraph', false)
                            }}
                            checked={!values.vatPayer && !values.getdph7aParagraph}
                            type="checkbox"
                            id="vatPayerFalse"
                            className="form-check-input"/>
                        <label className="form-check-label" htmlFor="vatPayerFalse">
                            Subjekt nie je platiteľ DPH
                        </label>
                    </div>


                    <div className="form-group form-check">
                        <input
                            name="vatPayer"
                            onChange={(e) => {
                                setFieldValue('vatPayer', true)
                                setFieldValue('getdph7aParagraph', false)
                            }}
                            checked={values.vatPayer && !values.getdph7aParagraph}
                            type="checkbox"
                            id="vatPayerTrue"
                            className="form-check-input"/>
                        <label className="form-check-label" htmlFor="vatPayerTrue">
                            Subjekt je platiteľ DPH
                        </label>
                    </div>

                    <div className="form-group form-check">
                        <input
                            name="vatPayer"
                            onChange={(e) => {
                                setFieldValue('vatPayer', false)
                                setFieldValue('getdph7aParagraph', true)
                            }}
                            checked={values.getdph7aParagraph}
                            type="checkbox"
                            id="getdph7aParagraph"
                            className="form-check-input"/>


                        <label className="form-check-label" htmlFor={'getdph7aParagraph'}>
                            Subjekt je registrovaný podľa §7/§7a
                        </label>
                    </div>

                    {values.vatPayer || values.getdph7aParagraph ? <div className="form-group">
                        <label htmlFor="icDph">IČ DPH*</label>
                        <Field id="icDph" name="icDph" className="form-control"/>
                        <FormErrorMessage name="icDph"/>
                    </div> : ''}
                </> : null}
                <div className="form-group">
                    <label htmlFor="iban">IBAN</label>
                    <Field id="iban" name="iban" className="form-control"/>
                    <FormErrorMessage name="iban"/>
                </div>
                <div className="form-group">
                    <label htmlFor="swift">SWIFT</label>
                    <Field id="swift" name="swift" className="form-control"/>
                    <FormErrorMessage name="swift"/>
                </div>

                <h4 className="text-left mb-2">Adresa</h4>
                <div className="form-group">
                    <label htmlFor="title">Ulica*</label>
                    <Field id="street" name={values.isCompany ? 'billingStreet' : 'street'}
                           className="form-control"/>
                    <FormErrorMessage name={values.isCompany ? 'billingStreet' : 'street'}/>
                </div>
                <div className="form-group">
                    <label htmlFor="zipCode">PSČ*</label>
                    <Field id="zipCode" name={values.isCompany ? 'billingZipCode' : 'zipCode'}
                           className="form-control"/>
                    <FormErrorMessage name={values.isCompany ? 'billingZipCode' : 'zipCode'}/>
                </div>
                <div className="form-group">
                    <label htmlFor="city">Mesto*</label>
                    <Field id="city" name={values.isCompany ? 'billingCity' : 'city'}
                           className="form-control"/>
                    <FormErrorMessage name={values.isCompany ? 'billingCity' : 'city'}/>
                </div>
                {!values.isCompany && <div className="form-group">
                    <label htmlFor="country">Krajina*</label>
                    <Field id="country" name="country" className="form-control"/>
                    <FormErrorMessage name="country"/>
                </div>}

                <h4 className="text-left mb-2">Kontaktné údaje</h4>
                <div className="form-group">
                    <label htmlFor="phoneNumber">Telefón</label>
                    <Field id="phoneNumber" name="phoneNumber" className="form-control"/>
                    <FormErrorMessage name="phoneNumber"/>
                </div>
                <div className="form-group">
                    <label htmlFor="web">Web</label>
                    <Field id="web" name="web" className="form-control"/>
                    <FormErrorMessage name="web"/>
                </div>

                <h4 className="text-left mb-2">Faktúry</h4>

                <div className="form-group">
                    <label htmlFor="startingInvoiceId">Aktuálne číslo faktúry <br/><small>(číslo sa automaticky navýši o
                        1 po vygenerovaní ďalšej faktúry)</small></label>


                    <Field id="startingInvoiceId" name="startingInvoiceId" className="form-control"/>
                    <FormErrorMessage name="startingInvoiceId"/>
                </div>


                <NiceButton type="submit" isLoading={isLoading}>
                    Uložiť
                </NiceButton>
            </Form>
        </>)}

        </Formik>

    )
}

export default UpdateProfileForm
