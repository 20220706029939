import React from 'react'

import { ClientFormWrapper } from '../common/styledComponents'

//TODO define API_PATHS.promo
const API_PATHS = {
    promo: `${process.env.REACT_APP_SERVER_URL}/api/affiliate-promo`
}

const AffiliatePromo = () => {
  return (
    <ClientFormWrapper> 
        <div className="row">
            <div className="col-10 ">
{/*                 <div className="row" style={{minHeight:'200px'}}><strong>materíal 1</strong></div> 
                <div className="row" style={{minHeight:'200px'}}><strong>materíal 2</strong></div>
                <div className="row" style={{minHeight:'200px'}}><strong>materíal 3</strong></div> */}
            </div>
            <div className="col">
                <button className="d-flex flex-column align-items-center p-3 px-5 border border-2 rounded" 
                    style={{backgroundColor:'#ffecc4', width:'150px'}}>stiahnúť
                </button>
            </div>
        </div>
    </ClientFormWrapper>
  )
}

export default AffiliatePromo