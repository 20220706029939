import React, { useState } from 'react'

export interface ContextWrapperContextType {
    noBootstrapColumns: boolean;
    setNoBootstrapColumns: (newValue: boolean) => void;
    noHeader: boolean;
    setNoHeader: (newValue: boolean) => void;
    noFooter: boolean;
    setNoFooter: (newValue: boolean) => void;
    customMainContentClasses: string;
    setCustomMainContentClasses: (newValue: string) => void;
}

export const ContentWrapperContext = React.createContext<ContextWrapperContextType>(null!)
export const ContentWrapperContextConsumer = ContentWrapperContext.Consumer

export function ContentWrapperProvider({ children }: { children: React.ReactNode }) {
    const [noBootstrapColumns, setNoBootstrapColumns] = useState<boolean>(false)
    const [noHeader, setNoHeader] = useState<boolean>(false)
    const [noFooter, setNoFooter] = useState<boolean>(false)
    const [customMainContentClasses, setCustomMainContentClasses] = useState('')

    const value = {
        noBootstrapColumns, setNoBootstrapColumns,
        noHeader, setNoHeader,
        noFooter, setNoFooter,
        customMainContentClasses, setCustomMainContentClasses
    }

    return (
        <ContentWrapperContext.Provider value={value}>
            {children}
        </ContentWrapperContext.Provider>)
}

export function useContentWrapper() {
    return React.useContext(ContentWrapperContext)
}