import React, { useState } from 'react'

import { ColorResult, SketchPicker } from 'react-color'

interface Props {
    color: string,
    setColor: (color: string) => void
}

export const ColorPickerInput = ({color, setColor}: Props) => {
    const [displayColorPicker, setDisplayColorPicker] = useState(false)

    return (
        <>
            <div style={{
                padding: '5px',
                background: '#fff',
                borderRadius: '1px',
                boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                cursor: 'pointer',
            }} onClick={() => {
                setDisplayColorPicker(prevState => !prevState)
            }}>
                <div style={{
                    width: '100%',
                    height: '45px',
                    borderRadius: '2px',
                    background: `${color || '#fff'}`,
                }}/>
            </div>
            {displayColorPicker ? <div style={{
                position: 'absolute',
                zIndex: '2',
            }}>
                <div style={{
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                }} onClick={() => setDisplayColorPicker(false)}/>
                <SketchPicker
                    color={color}
                    disableAlpha={true}
                    onChange={(color: ColorResult) => {
                        setColor(color.hex)
                    }}/>
            </div> : null}
        </>
    )
}