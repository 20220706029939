import React, {FunctionComponent} from 'react'

import {Col, Container, Row} from 'react-bootstrap'
import styled from 'styled-components'

import {useContentWrapper} from './ContentWrapperProvider'

const StyledContainer = styled(Container)`
  //padding-top: 70px;
  //padding-bottom: 40px;
  min-height: calc(100vh - 97px);
`
const ContentWrapper: FunctionComponent = (props) => {
    const contentWrapper = useContentWrapper()

    return contentWrapper.noBootstrapColumns ?
        <>{props.children}</> :
        // <StyledContainer
           <div  className={'main-content-wrapper ' + (contentWrapper.customMainContentClasses ? contentWrapper.customMainContentClasses : '')}>
            {/*<Row className="sg-0">*/}
            {/*    <Col className="sg-0">*/}
                    {props.children}
                {/*</Col>*/}
            {/*</Row>*/}
           </div>
        // </StyledContainer>
}

export default ContentWrapper