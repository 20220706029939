import React from 'react'

import 'react-multi-carousel/lib/styles.css'
import { Link, useSearchParams } from 'react-router-dom'

import { TSList } from './TSList'
import AppLayout from '../common/AppLayout'
import AppTitle from '../common/AppTitle'

function TimesheetPage() {
    const [searchParams,] = useSearchParams()

    return (
        <AppLayout>
            <AppTitle append={<Link to={'/app/timesheet/add'}>
                <button className="amani-primary">Pridať záznam</button>
            </Link>}>Timesheet</AppTitle>
            {searchParams.get('new') ? <div className="alert alert-success">Záznam bol úspešne pridaný</div> : null}

            <TSList/>
        </AppLayout>
    )
}

export default TimesheetPage
