import React, { useEffect, useState } from 'react'

import { AxiosError } from 'axios'
import toast from 'react-hot-toast'
/* import { Link } from 'react-router-dom' */

import { useAuth } from '../common/Auth'
import { ClientFormWrapper } from '../common/styledComponents'
import axiosClient from '../service/axiosClient'
import { AffiliatePayoutData } from '../types/Payout'


const API_PATHS = { 
    info: `${process.env.REACT_APP_SERVER_URL}/affiliate/info`,
    affiliate: `${process.env.REACT_APP_SERVER_URL}/affiliate`
}

// console.log('API_PATHS.payout:', API_PATHS.payout) // Debug

const AffiliatePayout = () => {
    const {user} = useAuth() // Get user
    // console.log('User:', user) // Debug
    const [data, setData] = useState<AffiliatePayoutData>({
        remainingCredit: 0,
        iban: '',
        resolved: [],
        unresolved: [],
    })

    const [payoutRequest, /* setPayoutRequest */] = useState({ // TODO
        payoutTypeId: 1,
        credit: 0
    })

    /** On first render or when user.id is changed fetch data for payouts */
    useEffect(() => {
        const fetchData = async () => {
            try {
                if (!API_PATHS.info) {
                    throw new Error('API endpoint for payout is not defined')
                }
                //console.log('Fetching data from:', API_PATHS.info) // Debug
                // Fetch data
                const response = await axiosClient.get<AffiliatePayoutData>(API_PATHS.info)
                setData(response.data as AffiliatePayoutData)
                //console.log('Data fetched:', response.data) // Debug
            } catch (error) {
                console.error('Error fetching data:', error)
            }
        }

        fetchData()
    }, [user?.id])


    const handlePayoutRequest = async () => {
        try {
            if (!API_PATHS.affiliate) {
                throw new Error('API endpoint for payout is not defined')
            }
            //console.log('Sending payout request to:', API_PATHS.affiliate) // Debug
    
            // Set the credit to the remainingCredit
            const updatedPayoutRequest = {
                ...payoutRequest,
                credit: 30//data.remainingCredit
            }
            //console.log('Payout request:', updatedPayoutRequest) // Debug
    
            // Send payout request
            const response = await axiosClient.post<AffiliatePayoutData>(API_PATHS.affiliate, updatedPayoutRequest)
            setData(response.data as AffiliatePayoutData)
        } catch (error) {
            const axiosError = error as AxiosError
            console.error('Error sending payout request:', axiosError.response?.data)
            toast.error(axiosError.response?.data ?? 'Nepodarilo sa poslať žiadosť o vyplatenie provízie')
        }
    }


  return (
    <ClientFormWrapper>
        <div className="container">
            <div className="row">
                <div className="col">
                    <div className="d-flex flex-column align-items-center text-center">
                        <strong className="py-3">Moje aktuálne provízie</strong>
                        <div className="d-flex flex-column align-items-center p-3 border border-2 rounded" 
                        style={{backgroundColor:'#ffecc4', width:'150px'}}>
                            <span style={{fontSize:'0.7rem'}}>Stav prasiatka</span>
                            <span><strong>{ data.remainingCredit }€</strong></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row py-3">
                <div className="col">
                    <div className="d-flex flex-column align-items-center text-center">
                        <strong className="py-3">Nevybavené žiadosti o vyplatenie provízie</strong>
                        <div className="d-flex flex-column align-items-center p-3 border border-2 rounded" 
                        style={{backgroundColor:'#ffecc4', width:'150px'}}>
                            {
                                data.unresolved.length === 0 ? 
                                <span style={{fontSize:'0.7rem'}}>Nemáš žiadne žiadosti</span>:
                                data.unresolved.map((payout) => (
                                    <div key={payout.id}>
                                        <span>{ payout.credit }€</span>
                                    </div>
                                ))
                            }
                            {/* <span style={{fontSize:'0.7rem'}}>nemáš žiadne žiadosti</span> */}
                        </div>
                    </div>
                </div>
                <div className="col">
                <div className="d-flex flex-column align-items-center text-center">
                        <strong className="py-3">Vybavené žiadosti o vyplatenie provízie</strong>
                        <div className="d-flex flex-column align-items-center p-3 border border-2 rounded" 
                        style={{backgroundColor:'#ffecc4', width:'150px'}}>
                            {
                                data.resolved.length === 0 ? 
                                <span style={{fontSize:'0.7rem'}}>Nemáš žiadne žiadosti</span>:
                                data.resolved.map((payout) => (
                                    <div key={payout.id}>
                                        <span>{payout.credit }€</span>
                                    </div>
                                ))
                            }
                            {/* <span style={{fontSize:'0.7rem'}}>nemáš žiadne žiadosti</span> */}

                        </div>
                    </div>
                </div>
            </div>                            
            <div className="row">
                <div className="col">
                <div className="d-flex flex-column align-items-center text-center">
                        <strong className="py-3">Chcem si vybrať moju províziu na účet</strong>
                        <div className="d-flex flex-column align-items-center py-3 border border-2 rounded" 
                        onClick={data.iban ? handlePayoutRequest : () => {}}
                        style={{backgroundColor:'#ffecc4', width:'150px'}}>
                            {
                            data.iban ?
                            <>
                                <span>IBAN: {data.iban}</span>
                                <span>Vybrať</span>
                            </>:
                            <>
                                {/* <Link to={'/edit-profile'} className="btn-amani not-uppercase not-bold"> */}
                                    <span>Prosím, vyplňte IBAN</span>
                                {/* </Link> */}
                            </>
                            }

                            {/* <span style={{fontSize:'0.7rem'}}>
                                prevod na učet
                                (v pripade ak klient nema v
                                profile vyplneny IBAN
                                presmeruje ho spat na profil
                                kde si ho bude musiet
                                vyplnit) ak ma jednoducho
                                si to da len vyplatit
                                potvrdenim
                            </span> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ClientFormWrapper>
  )
}

export default AffiliatePayout