import React, { useEffect, useState } from 'react'

import { Col, Container, Row } from 'react-bootstrap'
import toast from 'react-hot-toast'
import { Link, useSearchParams } from 'react-router-dom'

import hands from '../assets/amani-thumb_up.png'
import { useContentWrapper } from '../common/ContentWrapperProvider'
import PublicLayout from '../common/PublicLayout'
import axiosClient from '../service/axiosClient'

const PAID_STATUS = 'PAID'
const UNPAID_STATUS = 'UNPAID'

function GoPayCallbackPage() {
    const contentWrapper = useContentWrapper()
    const [searchParams,] = useSearchParams()
    const paymentIdFromUrl = searchParams.get('id')
    const [status, setStatus] = useState('')

    useEffect(() => {
        console.log('paymentIdFromUrl', paymentIdFromUrl)
        axiosClient.get(`gopay/verification/${paymentIdFromUrl}`).then((response) => {
            console.log('response', response.data)
            setStatus(response.data)
            if (response.data === PAID_STATUS) {
                toast.success('Platba bola úspešne spracovaná')
            }
            // const result = response.data.
        }).catch((error) => {
            console.log('EROR', error)
            setStatus('UNPAID_STATUS')
        })
    }, [paymentIdFromUrl])

    useEffect(() => {
        contentWrapper.setNoBootstrapColumns(true)

        return () => {
            contentWrapper.setNoBootstrapColumns(false)
        }
    }, [])

    return (
        <PublicLayout>
            <div className="info-page">
                <Container>
                    <div className="rounded-content-body mt-3">
                        <Row>
                            <Col>
                                {!status ?
                                    <h2 className="text-center">Tvoju platbu validujeme, prosíme o strpenie…</h2> :
                                    status === PAID_STATUS ? <>
                                            <h2 className="text-center mb-5">Výborne, tvoja platba prebehla v poriadku…</h2>
                                            <div className="form-text text-center">
                                                Môžeš pokračovať do aplikácie
                                                <br/>
                                                <br/>
                                                <br/>
                                                <Link to={'/app/dashboard'}>
                                                    <button
                                                        className="btn btn-primary pink-color btn-amani w-auto d-inline-block text-uppercase">
                                                        Preklik do aplikácie
                                                    </button>
                                                </Link>
                                                <br/><br/><img src={hands} width={200} alt="congrats"/>
                                            </div>
                                        </> :
                                        <h2 className="text-center">Žiaľ, tvoja platba neprebehla v poriadku…</h2>}
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </PublicLayout>
    )
}


export default GoPayCallbackPage
