import React from 'react'

import { ErrorMessage } from 'formik'

function FormErrorMessage({ name }: { name: string }) {

    return (
        <ErrorMessage name={name}>{(msg) => <div className="text-danger form-text">{msg}</div>}</ErrorMessage>
    )
}

export default FormErrorMessage
