import React, { useEffect, useState } from 'react'

import { Field, Form, Formik, FormikHelpers } from 'formik'
import DatePicker from 'react-datepicker'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import AsyncSelect from 'react-select/async'

import { ColorPickerInput } from '../common/ColorPickerInput'
import FormErrorMessage from '../common/FormErrorMessage'
import { loadOptionsForCompanySelector } from '../common/helpers'
import { datePickerDateFormat } from '../common/i18n'
import NiceButton from '../common/NiceButton'
import { ClientFormWrapper } from '../common/styledComponents'
import axiosClient from '../service/axiosClient'
import ClientDTO from '../types/Client'
import { CorporateBody, CorporateOption } from '../types/Corporate'

interface Props {
    id?: number
}

export const ClientForm = ({id}: Props) => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [defaultCompanyName, setDefaultCompanyName] = useState<string>('')

    const [client, setClient] = useState<ClientDTO>({} as ClientDTO)

    useEffect(() => {
        if (id && id > 0) {
            axiosClient.get('client/' + id)
                .then(response => {
                    setClient(response.data)
                    setDefaultCompanyName(response.data.companyName || '')
                }, () => {
                    toast.error('Zdá sa, že klient neexistuje')
                    navigate('/app/clients')
                })
        }
    }, [id])

    const submit = async (values: Partial<ClientDTO>, actions: FormikHelpers<Partial<ClientDTO>>) => {

        console.log('values', values)
        // setErrorMsg('')
        // setSuccessMsg('')
        setIsLoading(true)
        try {
            if (id && id > 0) {
                axiosClient.put('client/' + id, values)
                // navigate('/clients?new=true')
                window.scrollTo(0, 0)
                toast.success('Klient bol úspešne upravený')
            } else {
                const createResponse = await axiosClient.post('client', values)
                console.log('createResponse', createResponse)
                navigate('/app/clients?new=true')
                toast.success('Klient bol úspešne vytvorený')
            }
        } catch (error: any) {
            // setErrorMsg(error?.response?.data?.error || error)
        } finally {
            setIsLoading(false)
        }
    }

    const validate = (values: any) => {
        const errors: any = {}

        if (!values.firstName.trim()) {
            errors.firstName = t('required_field_error')
        }

        if (!values.lastName.trim()) {
            errors.lastName = t('required_field_error')
        }
        if (!values.hourlyRate) {
            errors.hourlyRate = t('required_field_error')
        }

        // if (!values.gender) {
        //     errors.gender = t('required_field_error')
        // }
        //
        // if (!values.dateOfBirth) {
        //     errors.dateOfBirth = t('required_field_error')
        // }

        return errors
    }

    return (
        <ClientFormWrapper>
            <div className="row d-flex">
                <div className="col-md-8">
                    <Formik
                        initialValues={({
                            vatPayer: client.vatPayer ?? false,
                            dph7aParagraph: client.dph7aParagraph ?? false,
                            hourlyRate: client.hourlyRate ?? '',
                            firstName: client.firstName ?? '',
                            lastName: client.lastName ?? '',
                            color: client.color ?? '',
                            title: client.title ?? '',
                            street: client.street ?? '',
                            zipCode: client.zipCode ?? '',
                            city: client.city ?? '',
                            country: client.country ?? '',
                            countryPrefix: client.countryPrefix ?? '',
                            phoneNumber: client.phoneNumber ?? '',
                            email: client.email ?? '',
                            web: client.web ?? '',
                            birthDate: client.birthDate ?? '',
                            companyAnniversary: client.companyAnniversary ?? '',
                            cmdTitle: client.cmdTitle ?? '',
                            cmdFirstName: client.cmdFirstName ?? '',
                            cmdLastName: client.cmdLastName ?? '',
                            companyName: client.companyName ?? '',
                            companyStreet: client.companyStreet ?? '',
                            companyZipcode: client.companyZipcode ?? '',
                            companyCity: client.companyCity ?? '',
                            companyCountry: client.companyCountry ?? '',
                            companyCountryPrefix: client.companyCountryPrefix ?? '',
                            companyPhoneNumber: client.companyPhoneNumber ?? '',
                            companyEmail: client.companyEmail ?? '',
                            companyWeb: client.companyWeb ?? '',
                            companyVatId: client.companyVatId ?? '',
                            companyTaxId: client.companyTaxId ?? '',
                            companyId: client.companyId ?? '',
                            companyIban: client.companyIban ?? ''
                        }) as Partial<ClientDTO>}
                        enableReinitialize
                        validate={validate}
                        onSubmit={submit}
                    >{({values, setFieldValue}) => (<>
                        {/* <PrettyPrintJson data={values} /> */}

                        <Form>
                            <h4 className="text-left mb-2">Kontaktné údaje</h4>
                            <div className="row d-flex justify-content-center">
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label htmlFor="title">Titul</label>
                                        <Field id="title" name="title" className="form-control"/>
                                        <FormErrorMessage name="title"/>
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className="form-group">
                                        <label htmlFor="firstName">Meno</label>
                                        <Field id="firstName" name="firstName" className="form-control"/>
                                        <FormErrorMessage name="firstName"/>
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className="form-group">
                                        <label htmlFor="lastName">Priezvisko</label>
                                        <Field id="lastName" name="lastName" className="form-control"/>
                                        <FormErrorMessage name="lastName"/>
                                    </div>
                                </div>
                            </div>

                            <div className="row d-flex justify-content-center">
                                <div className="col-md-7">
                                    <div className="form-group">
                                        <label htmlFor="street">Ulica</label>
                                        <Field id="street" name="street" className="form-control"/>
                                        <FormErrorMessage name="street"/>
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className="form-group">
                                        <label htmlFor="zipCode">PSČ</label>
                                        <Field id="zipCode" name="zipCode" className="form-control"/>
                                        <FormErrorMessage name="zipCode"/>
                                    </div>
                                </div>
                            </div>
                            <div className="row d-flex justify-content-center">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="city">Mesto</label>
                                        <Field id="city" name="city" className="form-control"/>
                                        <FormErrorMessage name="city"/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="country">Krajina</label>
                                        <Field id="country" name="country" className="form-control"/>
                                        <FormErrorMessage name="country"/>
                                    </div>
                                </div>
                            </div>

                            <div className="row d-flex justify-content-center">
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label htmlFor="countryPrefix">Telefón</label>
                                        <Field as="select" id="countryPrefix" name="countryPrefix"
                                               className="form-control">
                                            <option value="+421">+421</option>
                                            <option value="+420">+420</option>
                                        </Field>
                                        <FormErrorMessage name="countryPrefix"/>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <div className="form-group">
                                        <label htmlFor="phoneNumber">&nbsp;</label>
                                        <Field id="phoneNumber" name="phoneNumber" className="form-control"/>
                                        <FormErrorMessage name="phoneNumber"/>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label>Farba</label>
                                        <ColorPickerInput
                                            color={values.color as string}
                                            setColor={(color) => setFieldValue('color', color)}/>
                                    </div>
                                </div>
                            </div>
                            <div className="row d-flex justify-content-center">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="web">E-mail</label>
                                        <Field id="email" name="email" className="form-control"/>
                                        <FormErrorMessage name="email"/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="web">Web</label>
                                        <Field id="web" name="web" className="form-control"/>
                                        <FormErrorMessage name="web"/>
                                    </div>
                                </div>
                            </div>
                            <h4 className="text-left mb-2">Významné udalosti</h4>
                            <div className="row d-flex justify-content-center">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="birthDate">Dátum narodenia</label>
                                        <DatePicker
                                            scrollableYearDropdown
                                            yearDropdownItemNumber={100}
                                            locale="sk"
                                            dateFormat={datePickerDateFormat}
                                            showYearDropdown
                                            selected={(values.birthDate && new Date(values.birthDate)) || null}
                                            onChange={(value: any) => {
                                                setFieldValue('birthDate', value)
                                            }}
                                            id="birthDate"
                                            name="birthDate"
                                            className="form-control"/>
                                        <FormErrorMessage name="birthDate"/>
                                    </div>

                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="companyAnniversary">Dátum založenia firmy</label>
                                        <DatePicker
                                            scrollableYearDropdown
                                            yearDropdownItemNumber={100}
                                            locale="sk"
                                            dateFormat={datePickerDateFormat}
                                            showYearDropdown
                                            selected={(values.companyAnniversary && new Date(values.companyAnniversary)) || null}
                                            onChange={(value: any) => {
                                                setFieldValue('companyAnniversary', value)
                                            }}
                                            id="companyAnniversary"
                                            name="companyAnniversary"
                                            className="form-control"/>
                                        <FormErrorMessage name="companyAnniversary"/>
                                    </div>

                                </div>
                            </div>

                            <h4 className="text-left mb-2">Fakturačné údaje</h4>
                            <div className="form-group">
                                <label htmlFor="companyName">Názov spoločnosti</label>

                                <AsyncSelect
                                    placeholder={'Vyhľadajte spoločnosť'}
                                    className={'async-select'}
                                    {...(defaultCompanyName ? {
                                        value: {
                                            label: defaultCompanyName,
                                            value: {} as CorporateBody
                                        } as CorporateOption
                                    } : null)}
                                    classNamePrefix={'async-select'}
                                    loadingMessage={(inputValue) => <>Vyhľadávam...</>}
                                    noOptionsMessage={(inputValue) => <>{inputValue.inputValue.length ? 'Nenašli sa žiadne výsledky' : 'Začnite písať...'}</>}
                                    onChange={(item) => {
                                        console.log('sel', item)
                                        setDefaultCompanyName(item?.label as string)
                                        setFieldValue('companyName', item?.label)
                                        setFieldValue('companyId', item?.value?.cin)
                                        setFieldValue('companyTaxId', item?.value?.tin)
                                        setFieldValue('companyVatId', item?.value?.vatin)
                                        setFieldValue('companyStreet', item?.value?.formatted_street)
                                        setFieldValue('companyCity', item?.value?.municipality)
                                        setFieldValue('companyZipcode', item?.value?.postal_code)
                                        setFieldValue('companyCountry', item?.value?.country)

                                        setFieldValue('vatPayer', false)
                                        setFieldValue('dph7aParagraph', false)
                                        if (item?.value?.vatin_paragraph === '§4') {
                                            setFieldValue('vatPayer', true)
                                            setFieldValue('dph7aParagraph', false)
                                        }
                                        if (item?.value?.vatin_paragraph === '§7' || item?.value?.vatin_paragraph === '§7a') {
                                            setFieldValue('dph7aParagraph', true)
                                            setFieldValue('vatPayer', false)
                                        }
                                    }}
                                    cacheOptions
                                    loadOptions={loadOptionsForCompanySelector}/>


                                <Field id="companyName" className="form-control d-none" name="companyName"/>
                                <FormErrorMessage name="companyName"/>
                            </div>

                            <div className="row d-flex justify-content-center">
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label htmlFor="title">Titul</label>
                                        <Field id="cmdTitle" name="cmdTitle" className="form-control"/>
                                        <FormErrorMessage name="cmdTitle"/>
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className="form-group">
                                        <label htmlFor="cmdFirstName">Meno</label>
                                        <Field id="cmdFirstName" name="cmdFirstName" className="form-control"/>
                                        <FormErrorMessage name="cmdFirstName"/>
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className="form-group">
                                        <label htmlFor="cmdLastName">Priezvisko</label>
                                        <Field id="cmdLastName" name="cmdLastName" className="form-control"/>
                                        <FormErrorMessage name="cmdLastName"/>
                                    </div>
                                </div>
                            </div>

                            <div className="row d-flex justify-content-center">
                                <div className="col-md-7">
                                    <div className="form-group">
                                        <label htmlFor="companyStreet">Ulica</label>
                                        <Field id="companyStreet" name="companyStreet" className="form-control"/>
                                        <FormErrorMessage name="companyStreet"/>
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className="form-group">
                                        <label htmlFor="companyZipcode">PSČ</label>
                                        <Field id="companyZipcode" name="companyZipcode" className="form-control"/>
                                        <FormErrorMessage name="companyZipcode"/>
                                    </div>
                                </div>
                            </div>
                            <div className="row d-flex justify-content-center">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="companyCity">Mesto</label>
                                        <Field id="companyCity" name="companyCity" className="form-control"/>
                                        <FormErrorMessage name="companyCity"/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="companyCountry">Krajina</label>
                                        <Field id="companyCountry" name="companyCountry" className="form-control"/>
                                        <FormErrorMessage name="companyCountry"/>
                                    </div>
                                </div>
                            </div>
                            <div className="row d-flex justify-content-center">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="companyId">IČO</label>
                                        <Field id="companyId" name="companyId" className="form-control"/>
                                        <FormErrorMessage name="companyId"/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="companyTaxId">DIČ</label>
                                        <Field id="companyTaxId" name="companyTaxId" className="form-control"/>
                                        <FormErrorMessage name="companyTaxId"/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="companyVatId">IČ DPH</label>
                                        <Field id="companyVatId" name="companyVatId" className="form-control"/>
                                        <FormErrorMessage name="companyVatId"/>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group form-check">
                                <input
                                    name="vatPayer"
                                    onChange={(e) => {
                                        setFieldValue('vatPayer', false)
                                        setFieldValue('dph7aParagraph', false)
                                    }}
                                    checked={!values.vatPayer && !values.dph7aParagraph}
                                    type="checkbox"
                                    id="vatPayerFalse"
                                    className="form-check-input"/>
                                <label className="form-check-label" htmlFor="vatPayerFalse">
                                    Subjekt nie je platiteľ DPH
                                </label>
                            </div>
                            <div className="form-group form-check">
                                <input
                                    name="vatPayer"
                                    onChange={(e) => {
                                        setFieldValue('vatPayer', true)
                                        setFieldValue('dph7aParagraph', false)
                                    }}
                                    checked={values.vatPayer && !values.dph7aParagraph}
                                    type="checkbox"
                                    id="vatPayerTrue"
                                    className="form-check-input"/>
                                <label className="form-check-label" htmlFor="vatPayerTrue">
                                    Subjekt je platiteľ DPH
                                </label>
                            </div>
                            <div className="form-group form-check">
                                <input
                                    name="vatPayer"
                                    onChange={(e) => {
                                        setFieldValue('vatPayer', false)
                                        setFieldValue('dph7aParagraph', true)
                                    }}
                                    checked={values.dph7aParagraph}
                                    type="checkbox"
                                    id="getdph7aParagraph"
                                    className="form-check-input"/>
                                <label className="form-check-label" htmlFor={'getdph7aParagraph'}>
                                    Subjekt je registrovaný podľa §7/§7a
                                </label>
                            </div>


                            {/*<div className="form-group form-check">*/}
                            {/*    <Field type="checkbox" id="vatPayer" className="form-check-input"*/}
                            {/*           name="vatPayer"/>*/}
                            {/*    <label htmlFor={'vatPayer'} className="form-check-label">*/}
                            {/*        Subjekt je platiteľ DPH*/}
                            {/*    </label>*/}
                            {/*</div>*/}
                            {/*<div className="form-group form-check">*/}
                            {/*    <Field type="checkbox" id="dph7aParagraph"*/}
                            {/*           className="form-check-input"*/}
                            {/*           name="dph7aParagraph"/>*/}
                            {/*    <label className="form-check-label" htmlFor={'dph7aParagraph'}>*/}
                            {/*        Subjekt je registrovaný podľa §7/§7a*/}
                            {/*    </label>*/}
                            {/*</div>*/}

                            <div className="row d-flex">
                                <div className="col-md-9">
                                    <div className="form-group">
                                        <label htmlFor="companyIban">IBAN</label>
                                        <Field id="companyIban" name="companyIban" className="form-control"/>
                                        <FormErrorMessage name="companyIban"/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label htmlFor="hourlyRate">Hodinová sadzba</label>
                                        <Field id="hourlyRate" name="hourlyRate" className="form-control"/>
                                        <FormErrorMessage name="hourlyRate"/>
                                    </div>
                                </div>
                            </div>

                            {/*{errorMsg && <div className="text-danger form-text text-center mb-2">{errorMsg}</div>}*/}
                            {/*{successMsg &&*/}
                            {/*    <div className="text-success form-text text-center mb-2">{successMsg}</div>}*/}

                            <NiceButton isLoading={isLoading} type="submit">
                                Uložiť
                            </NiceButton>
                        </Form>
                    </>)}

                    </Formik>
                </div>

            </div>
        </ClientFormWrapper>
    )
}
