import React, { useEffect, useState } from 'react'

import { tr } from 'date-fns/locale'
import { Field, Form, Formik } from 'formik'
import { Col, Row, Table } from 'react-bootstrap'
import toast from 'react-hot-toast'

import { ColorPickerInput } from '../common/ColorPickerInput'
import FormErrorMessage from '../common/FormErrorMessage'
import NiceButton from '../common/NiceButton'
import { NoRecords } from '../common/NoRecords'
import axiosClient from '../service/axiosClient'
import { CalendarEventType } from '../types/Calendar'

function CalendarEventTypes() {
    const [calendarEventTypes, setCalendarEventTypes] = useState<CalendarEventType[]>([])
    const [editingType, setEditingType] = useState<CalendarEventType>()

    function load() {
        axiosClient.get('calendar-event/type').then(response => {
            console.log('response', response)
            setCalendarEventTypes(response.data)
        })
    }

    useEffect(() => {
        load()
    }, [])

    async function deleteType(id: number) {
        if (confirm('Naozaj si želáte daný typ udalosti vymazať?')) {
            await axiosClient.delete('calendar-event/type/' + id)
            toast.success('Typ udalosti bol úspešne vymazaný')
            load()
        }
    }

    const editForm = <>
        {editingType && <Formik
            initialValues={editingType as CalendarEventType}
            enableReinitialize
            validate={(values) => {
                const err: Partial<CalendarEventType> = {}
                if (!values.name) {
                    err.name = 'Toto pole je povinné'
                }
                if (!values.color) {
                    err.color = 'Vyberte farbu'
                }
                console.log('values', values)
                console.log('err', err)
                return err
            }}
            onSubmit={(values) => {
                axiosClient.post('/calendar-event/type', {
                    name: values.name,
                    color: values.color,
                    ...(editingType.id > 0 ? {id: editingType.id} : null)
                }).then((res) => {
                    toast.success('Typy udalosti boli úspešne aktualizované')
                    load()
                    setEditingType(undefined)
                })
            }}
        >{({values, setFieldValue, isSubmitting}) => (<>
            <Form>
                <Row>
                    <Col md={2}>
                        <div className="form-group">
                            <label>Farba</label>
                            <ColorPickerInput
                                color={values.color|| ''}
                                setColor={(color) => setFieldValue('color', color)}/>
                            <FormErrorMessage name="color"/>
                        </div>
                    </Col>
                    <Col md={10}>
                        <div className="form-group">
                            <label htmlFor="name">Názov</label>
                            <Field id="name" type={'text'} name="name" className="form-control"/>
                            <FormErrorMessage name="name"/>
                        </div>
                    </Col>
                </Row>

                <div className="button-group">
                    <NiceButton type="submit" isLoading={isSubmitting}>
                        Uložiť
                    </NiceButton>
                    <button className={'button btn btn-amani button-secondary'} onClick={() => {
                        setEditingType(undefined)
                    }}>Zrušiť
                    </button>
                </div>

            </Form>
        </>)}

        </Formik>}
    </>

    return (
        editingType ? editForm :
            <>{calendarEventTypes.length === 0 ?
                <NoRecords/> :
                <>
                    <Table className={'fndt_table'}>
                        <thead>
                        <tr>
                            {/*<th scope="col">#</th>*/}
                            <th scope="col">Názov</th>
                            <th scope="col" className="text-right">Akcia</th>
                        </tr>
                        </thead>
                        <tbody>
                        {calendarEventTypes.map((eventType, index) => (
                            <tr
                                className=""
                                key={eventType.id}
                                onClick={() => {
                                    // navigate('/clients/' + client.id)
                                }}
                            >
                                <td>
                                    <span style={{backgroundColor: eventType.color}} className={'color-client-square'}></span> {eventType.name}
                                </td>
                                <td className="text-right">
                                    {!eventType.isSystemType && <div className="d-flex gap-2 justify-content-end">

                                        <a className={'table-action-link cursor-pointer'} onClick={() => {
                                            setEditingType(eventType)
                                        }}
                                           title={'Upraviť'}>
                                            <i className="bi bi-pencil primary-text-color"></i>
                                        </a>
                                        <a className={'table-action-link'} href={'#'} onClick={(e) => {
                                            e.preventDefault()
                                            eventType.id && deleteType(eventType.id)
                                        }} title={'Vymazať'}>
                                            <i className="bi bi-trash primary-text-color"></i>
                                        </a>
                                    </div>}
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </>
            }
                <button
                    type="button"
                    onClick={() => {
                        setEditingType({id: 0, name: '', isSystemType: false, color: ''})
                    }}
                    className="button btn btn-primary btn-amani mt-3 mb-2 w-auto">Pridať typ
                </button>
            </>)

}

export default CalendarEventTypes
