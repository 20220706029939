import React, { PropsWithChildren, useEffect } from 'react'


import { arrayMoveImmutable } from 'array-move'
import { debounce } from 'lodash'
import SortableList, { SortableItem } from 'react-easy-sort'
import styled from 'styled-components'

import { StickyNote } from './StickyNote'
import { useAuth } from '../common/Auth'
import axios from '../service/axiosClient'
import emitter from '../service/emitter'
import { Note, NoteType } from '../types/Note'

export const StickyNotesWrapper = ({children}: PropsWithChildren<unknown>) => {
    const auth = useAuth()
    const [notes, setNotes] = React.useState<Note[]>([])

    function fetchNotes() {
        axios.get('/note', {params: {userAccountId: auth.user?.id}}).then(response => {
            setNotes(response.data)
        })
    }

    function updateNoteContent(noteId: number, note: Note) {
        setNotes((notes) => {
            const newNotes = [...notes]
            newNotes.find((n) => n.id === noteId)!.content = note.content
            return newNotes
        })
        axios.put('/note', {noteId, ...note})
            .then(response => {

            })
    }

    const updateNoteContentDebounced = debounce(updateNoteContent, 800)

    function removeNote(id: number) {
        if (confirm('Naozaj si želáte vymazať túto poznámku?')) {
            axios.delete(`/note/${id}`)
                .then(response => {
                    fetchNotes()
                })

        }
    }

    function onNewNoteBtnClick() {
        axios.post('/note', {noteType: NoteType.NOTE, content: '', userAccountId: auth.user?.id}).then(response => {
            fetchNotes()
        })
    }

    function onNewTodoBtnClick() {
        axios.post('/note', {noteType: NoteType.TODO, content: '', userAccountId: auth.user?.id}).then(response => {
            const id = response.data
            axios.post('/note/add/todo-item', {
                noteId: id,
                value: 'Niečo...',
                isChecked: false
            }).then(() => {
                fetchNotes()
            })
        })
    }

    useEffect(() => {
        fetchNotes()

        emitter.on('add-sticky-note', onNewNoteBtnClick)
        emitter.on('add-sticky-todo', onNewTodoBtnClick)

        return () => {
            emitter.off('add-sticky-note', onNewNoteBtnClick)
            emitter.off('add-sticky-todo', onNewTodoBtnClick)
        }
    }, [])


    const onSortEnd = (oldIndex: number, newIndex: number) => {
        setNotes((notesList) => {
            const newArr = arrayMoveImmutable(notesList, oldIndex, newIndex)
            let startPosition = 1
            newArr.forEach((note) => {
                note.position = startPosition
                startPosition++
                console.log('note', note)
                updateNoteContent(note.id, note)
            })
            return newArr
        })
    }

    return (
        <StyledWrapper>
            <div className="notes-wrapper">

                <SortableList
                    onSortEnd={onSortEnd}
                    className="list"
                    draggedItemClassName="dragged"
                >
                    {Array.isArray(notes) ? notes.map((item) => (
                        <SortableItem key={item.id}>
                            <div className="item">
                                <StickyNote
                                    onEditTodoItem={(editedTodo) => {
                                        axios.post('/note/add/todo-item', {
                                            noteId: item.id,
                                            id: editedTodo.id,
                                            isChecked: editedTodo.checked,
                                            value: editedTodo.value
                                        })
                                            .then(() => {
                                                fetchNotes()
                                            })
                                    }}
                                    onToggleTodoItem={(todoId, newState) => {
                                        axios.post('/note/add/todo-item', {
                                            noteId: item.id,
                                            id: todoId,
                                            isChecked: newState
                                        })
                                            .then(() => {
                                                fetchNotes()
                                            })
                                    }}
                                    onDeleteTodoItem={(todoId) => {
                                        axios.delete(`/note/todo-items/${todoId}`)
                                            .then(() => {
                                                fetchNotes()
                                            })
                                    }}
                                    onAddTodoItem={(text) => {
                                        axios.post('/note/add/todo-item', {
                                            noteId: item.id,
                                            value: text,
                                            isChecked: false
                                        }).then(() => {
                                            fetchNotes()
                                        })
                                    }}
                                    onRemoveClick={() => removeNote(item.id)} key={item.id}
                                    // onChange={(updateData) => {
                                    //     console.log('updateData', updateData.content)
                                    //     updateNoteContentDebounced(item.id, {...item, ...updateData})
                                    // }}
                                    onContentChange={(content) => {
                                        setNotes((notes) => {
                                            const newNotes = [...notes]
                                            newNotes.find((n) => n.id === item.id)!.content = content
                                            return newNotes
                                        })
                                        updateNoteContentDebounced(item.id, {...item, content: content})
                                    }}
                                    onColorChange={(color) => {
                                        // console.log('updateData', updateData.content)
                                        setNotes((notes) => {
                                            const newNotes = [...notes]
                                            newNotes.find((n) => n.id === item.id)!.color = color
                                            return newNotes
                                        })
                                        updateNoteContentDebounced(item.id, {...item, color: color})
                                    }}
                                    content={item.content}
                                    type={item.noteType}
                                    todoItems={item.todoItems}
                                    color={item.color}/>
                            </div>
                        </SortableItem>
                    )) : null}
                </SortableList>
            </div>
        </StyledWrapper>
    )
}

const StyledWrapper = styled.div`
    min-height: calc(100vh - 85px);
    display: flex;
    flex-direction: column;
    position: relative; // Add this to make the child button's absolute positioning relative to this container
    padding-bottom: 80px;

    .notes-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: start;
        gap: 20px;
    }

    button.action {
        position: absolute;
        left: 0;
        bottom: 10px;
        background-color: #393E5E; /* Green background */
        border: none; /* Remove borders */
        color: white; /* White text */
        padding: 6px 19px;
        text-align: center; /* Centered text */
        text-decoration: none; /* Remove underline */
        display: inline-block;
        font-size: 32px;
        margin: 4px 2px;
        cursor: pointer; /* Mouse pointer on hover */
        border-radius: 12px; /* Rounded corners */
        transition-duration: 0.4s; /* Transition */

        &:hover {
            background-color: #4b517a; /* Green background on hover */
        }
    }

`