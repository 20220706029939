import React, { useEffect, useState } from 'react'

import { useLocalStorage } from 'usehooks-ts'

import { useAuth } from './Auth'
import axiosClient from '../service/axiosClient'
import ClientDTO from '../types/Client'

export const ClientColorSquare = ({clientId, color}: { clientId?: number, color?: string }) => {
    const {user} = useAuth()
    const [clients, setClients] = useLocalStorage<ClientDTO[]>(user?.id + '_clients', [])
    const [colorInternal, setColorInternal] = useState('')

    useEffect(() => {
        console.log('color', color, clientId, clients)
        if (!color && clientId && clients.length > 0) {
            console.log('tu sme???', clients.find(client => client.id === clientId)?.color)
            setColorInternal(clients.find(client => client.id === clientId)?.color || '')
        } else if (color) {
            setColorInternal(color)
        }
    }, [clients, clientId, color])

    // useEffect(() => {
    //     axiosClient.get('client').then(response => {
    //         console.log('response', response)
    //         setClients(response.data)
    //     })
    // }, [])

    return (
        colorInternal ? <span style={{backgroundColor: colorInternal}} className={'color-client-square'}></span> : null
    )
}