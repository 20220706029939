import React, { useEffect } from 'react'

import 'react-multi-carousel/lib/styles.css'
import { Tab, Tabs } from 'react-bootstrap'
import toast from 'react-hot-toast'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { ClientForm } from './ClientForm'
import { NoteClient } from './NoteClient'
import { SocialCredentials } from './SocialCredentials'
import AppLayout from '../common/AppLayout'
import AppTitle from '../common/AppTitle'

function ClientsEditPage() {
    const params = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        if (isNaN(parseInt(params.clientId as string)) || parseInt(params.clientId as string) <= 0) {
            navigate('/app/clients')
        }
    }, [params.clientId])

    return (
        <AppLayout>
            <AppTitle append={<Link to={'/app/clients'}>
                <button className="btn amani-primary">Späť</button>
            </Link>}>Úprava klienta #{params.clientId}</AppTitle>

            <Tabs
                defaultActiveKey="udaje"
                id="client-form-tabs"
            >
                <Tab eventKey="udaje" title="Údaje">
                    <ClientForm id={parseInt(params.clientId as string)}/>
                </Tab>
                <Tab eventKey="socials" title="Prihl. údaje k sociálnym sieťam">
                    <SocialCredentials id={parseInt(params.clientId as string)}/>
                </Tab>
                <Tab eventKey="note" title="Poznámka">
                    <NoteClient id={parseInt(params.clientId as string)}/>
                </Tab>

            </Tabs>

        </AppLayout>
    )
}

export default ClientsEditPage
