import React, { useEffect, useState } from 'react'

import { Field, Form, Formik, FormikHelpers } from 'formik'
import { isEmpty } from 'lodash'
import { Col, Row } from 'react-bootstrap'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'

import FormErrorMessage from '../common/FormErrorMessage'
import { KeyIconSvg } from '../common/KeyIconSvg'
import NiceButton from '../common/NiceButton'
import axiosClient, { SERVER_URL } from '../service/axiosClient'
import { EmailSettings } from '../types/EmailSettings'

function EmailSettingsForm() {
    const [passwordShown, setPasswordShown] = useState(false)
    const [emailSettings, setEmailSettings] = useState<EmailSettings>({
        'email': '',
        'password': '',
        'smtpHost': '',
        'smtpPort': '' as unknown as number,
        'predefinedText': '',
        'signature': '',
        'logo': ''
    })
    const {t} = useTranslation()
    const [key, setKey] = useState('initial')
    const [logoUrl, setLogoUrl] = useState<string>('')
    const [signatureUrl, setSignatureUrl] = useState<string>('')


    useEffect(() => {
        axiosClient.get('email-config').then((data) => {
            console.log('data.data', data.data)
            if (!isEmpty(data.data)) {
                setEmailSettings(data.data)
            }
        })
    }, [])

    useEffect(() => {
        if (emailSettings?.logo) {
            setLogoUrl(`${SERVER_URL}/storage/${emailSettings.logo}`)
        }
        if (emailSettings?.signature) {
            setSignatureUrl(`${SERVER_URL}/storage/${emailSettings.signature}`)
        }
    }, [emailSettings])

    const submit = async (values: EmailSettings, actions: FormikHelpers<EmailSettings>) => {
        console.log('values', values)
        try {
            const response = await axiosClient.post('email-config', {...values})
            // actions.resetForm()
            console.log('response', response.data)
            // if (response.data) {
            setEmailSettings(prevState => {
                return {
                    ...values,
                    ...(values.id ? {id: values.id} : response.data ? {id: response.data} : {})
                }
            })
            // }
            setKey(new Date().toString())
            toast.success('Emailové nastavenia bolo úspešne aktualizované')
        } catch (error: any) {
            toast.error('Emailové nastavenia sa neporadilo uložiť')
        }
    }

    const validate = (values: any) => {
        const errors: any = {}

        if (!values.email) {
            errors.email = t('required_field_error')
        }

        if (!values.password) {
            errors.password = t('required_field_error')
        }

        if (!values.smtpHost) {
            errors.smtpHost = t('required_field_error')
        }

        if (!values.smtpPort) {
            errors.smtpPort = t('required_field_error')
        }

        return errors
    }

    return (
        <Formik
            initialValues={emailSettings}
            enableReinitialize
            validate={validate}
            onSubmit={submit}
        >{({values, setFieldValue, isSubmitting}) => (<>
            <Form>
                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <Field id="email" type={'email'} name="email" className="form-control"/>
                    <FormErrorMessage name="email"/>
                </div>

                <div className="form-group">
                    <label htmlFor="password">Heslo</label>
                    <div className="input-group show-psw-input-group">
                        <Field id="password" type={passwordShown ? 'text' : 'password'}
                               name="password" className="form-control"/>
                        <div className="input-group-append">
                            <button onClick={(e: any) => setPasswordShown(!passwordShown)}
                                    className="" type="button"
                                    title={passwordShown ? 'Skryť heslo' : 'Zobraziť heslo'}>
                                <KeyIconSvg/>
                            </button>
                        </div>
                    </div>

                    {/*<Field id="password" type={'password'} name="password" className="form-control"/>*/}
                    {/*<FormErrorMessage name="password"/>*/}
                </div>
                <div className="form-group">
                    <label htmlFor="smtpHost">SMTP Host</label>
                    <Field id="smtpHost" name="smtpHost" className="form-control"/>
                    <FormErrorMessage name="smtpHost"/>
                </div>
                <div className="form-group">
                    <label htmlFor="smtpPort">SMTP Port</label>
                    <Field id="smtpPort" name="smtpPort" className="form-control"/>
                    <FormErrorMessage name="smtpPort"/>
                </div>
                <div className="form-group">
                    <label htmlFor="predefinedText">Predvolený text do e-mailov s faktúrou</label>
                    <Field as={'textarea'} rows={7} id="predefinedText" name="predefinedText" className="form-control"/>
                    <FormErrorMessage name="predefinedText"/>
                </div>

                <Row>
                    <Col>
                    <div className="form-group">
                            <label
                                htmlFor="logo">{'Logo'}</label>
                            {logoUrl ?
                                <>
                                    <img key={key} src={logoUrl as unknown as string}
                                         style={{width: '100px', display: 'block'}}/>
                                    <button
                                        onClick={() => {
                                            setLogoUrl('')
                                            setFieldValue('logo', '')
                                        }}
                                        type="button"
                                        className="button btn btn-primary btn-amani mt-3 mb-2">{'Vymazať logo'}</button>
                                </> : null}

                            <label className="btn btn-like-input d-block mt-1"
                                   htmlFor="logo-email">{'Nahrať logo'}</label>
                            <input className={'d-none'} id="logo-email" key={key} name="logo" type="file"
                                   onChange={(event) => {
                                       if (event.target.files && event.target.files[0]) {
                                           const formData = new FormData()
                                           formData.append('file', event.target.files[0])

                                           axiosClient.post('storage', formData).then((resp) => {
                                               console.log('resp', resp.data)
                                               setFieldValue('logo', resp.data)
                                               setLogoUrl(`${SERVER_URL}/storage/${resp.data}`)
                                           })
                                       }
                                   }}/>
                        </div>
                    </Col>
                    <Col>
                        <div className="form-group">
                            <label
                                htmlFor="signature">{'Podpis'}</label>
                            {signatureUrl ?
                                <>
                                    <img key={key} src={signatureUrl as unknown as string}
                                         style={{width: '100px', display: 'block'}}/>
                                    <button
                                        onClick={() => {
                                            setSignatureUrl('')
                                            setFieldValue('signature', '')
                                        }}
                                        type="button"
                                        className="button btn btn-primary btn-amani mt-3 mb-2">{'Vymazať podpis'}</button>
                                </> : null}

                            <label className="btn btn-like-input d-block mt-1"
                                   htmlFor="signature-email">{'Nahrať podpis'}</label>
                            <input className={'d-none'} id="signature-email" key={key} name="signature"
                                   type="file"
                                   onChange={(event) => {
                                       if (event.target.files && event.target.files[0]) {
                                           const formData = new FormData()
                                           formData.append('file', event.target.files[0])

                                           axiosClient.post('storage', formData).then((resp) => {
                                               console.log('resp', resp.data)
                                               setFieldValue('signature', resp.data)
                                               setSignatureUrl(`${SERVER_URL}/storage/${resp.data}`)
                                           })
                                       }
                                   }}/>
                        </div>
                    </Col>
                </Row>

                <NiceButton type="submit" isLoading={isSubmitting}>
                    Uložiť nastavenia
                </NiceButton>
            </Form>
        </>)}

        </Formik>

    )
}

export default EmailSettingsForm
