import React, { useEffect } from 'react'

import 'react-multi-carousel/lib/styles.css'
import toast from 'react-hot-toast'
import { Link, useParams, useSearchParams } from 'react-router-dom'

import { ClientsList } from './ClientsList'
import AppLayout from '../common/AppLayout'
import AppTitle from '../common/AppTitle'
import { useContentWrapper } from '../common/ContentWrapperProvider'

function ClientsPage() {
    const [searchParams,] = useSearchParams()
    const successInUrl = searchParams.get('showSuccess')

    useEffect(() => {
        if (successInUrl) {
            toast.success('Klient bol úspešne spárovaný so socialnou sieťou')
        }
    }, [successInUrl])

    return (
        <AppLayout>
            <AppTitle append={<Link to={'/app/clients/add'}><button className="amani-primary">Pridať nového</button></Link>}>Klienti</AppTitle>

            {/*<div className="rounded-content-body">*/}
                <ClientsList/>
            {/*</div>*/}
        </AppLayout>
    )
}

export default ClientsPage
