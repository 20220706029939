import { PropsWithChildren } from 'react'

import { IdentifiableItemResponse, PageableResponse, PagingRequest } from '../../types/Common'

export enum SortDir {
    Desc = 'desc',
    Asc = 'asc',
}

// export type LoadItemsFunction<T> = (queryParams: PagingRequest) => Promise<T>
export type LoadItemsFunction<T extends IdentifiableItemResponse> = (
    queryParams: PagingRequest
) => Promise<PageableResponse<T>>

export type SmartTableWrapperProps<T extends IdentifiableItemResponse, T2> = PropsWithChildren<{
    initialPage?: number
    initialPageSize?: number
    initialSortBy?: string
    initialSortDir?: SortDir
    filter?: T2
    loadDataFn: LoadItemsFunction<T>
}>

export interface SmartTableWrapperStateType {
    total: number
    entries: IdentifiableItemResponse[]
    hasNextPage: boolean
    isLoading: boolean
    error: boolean
}

export enum SmartTableReducerActionType {
    LOAD_INIT,
    LOAD_SUCCESS,
    LOAD_ERROR,
}

export type SmartTableReducerAction = {
    type: SmartTableReducerActionType
    payload?: PageableResponse<IdentifiableItemResponse>
}

export interface SmartTableContextType extends SmartTableWrapperStateType {
    load: (queryParams?: PagingRequest, isSilent?: boolean) => void
    currentSortBy: string
    currentSortDir: SortDir
    onSort: (by: string, dir: SortDir) => void
}

export type SmartTableProps<T extends IdentifiableItemResponse> = {
    keyExtractor?: (item: T) => string | number
    renderRow: (item: T) => React.ReactNode
    onRowClick?: (item: T) => void
    HeadRow: React.ReactNode
}

export type SmartTableBodyRowProps = PropsWithChildren<{
    className?: string
    onClick?: () => void
}>

export type SmartTableHeadCellProps = PropsWithChildren<{
    className?: string
    children: React.ReactNode
    sortDir?: SortDir
    sort?: string
    onSort?: (sortDir: SortDir) => void
}>

export type SmartTablePaginationProps = {
    page: number
    pageSize: number
    totalPages: number
    onPageChange?: (page: number) => void
    onPageSizeChange?: (pageSize: number) => void
}
