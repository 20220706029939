import React, { useEffect, useState } from 'react'

import { Field, Form, Formik } from 'formik'
import toast from 'react-hot-toast'

import FormErrorMessage from '../common/FormErrorMessage'
import { ClientFormWrapper } from '../common/styledComponents'
import axiosClient from '../service/axiosClient'

interface Props {
    id: number
}

export const NoteClient = ({id}: Props) => {
    const [note, setNote] = useState('')

    useEffect(() => {
        if (id && id > 0) {
            axiosClient.get(`/client/${id}`).then(response => {
                setNote(response.data.note || '')
            })
        }
    }, [id])


    return (
        <ClientFormWrapper>
            <Formik
                onSubmit={(values) => {
                    axiosClient.put(`/client/${id}`, {
                        note: values.note
                    })
                    toast.success('Poznámka bola uložená')
                }}
                initialValues={{note: note}}
                enableReinitialize
            >{({values, setFieldValue}) => (<>
                <Form>

                    <div className="row">
                        <div className="col-md-8">
                            <div className="form-group">
                                <label htmlFor={'note'}>Poznámka</label>
                                <Field as={'textarea'} rows={20} id={'note'} name={'note'} className="form-control"/>
                                <FormErrorMessage name={'note'}/>
                            </div>
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col-md-8">
                            <div className="form-group">
                                <label>&nbsp;</label>
                                <button
                                    type="submit"
                                    className="btn btn-primary btn-amani">
                                    Uložiť
                                </button>
                            </div>
                        </div>
                    </div>


                </Form>
            </>)}

            </Formik>
        </ClientFormWrapper>
    )
}