import React, { useState } from 'react'

import { Tab, Tabs } from 'react-bootstrap'

import AffiliateCommisionLetters from './AffiliateCommisionLetters'
import AffiliateCommissions from './AffiliateCommissions'
import AffiliateLink from './AffiliateLink'
import AffiliatePayout from './AffiliatePayout'
import AffiliatePromo from './AffiliatePromo'
import AppLayout from '../common/AppLayout'
import AppTitle from '../common/AppTitle'


const AffiliatePage = () => {
    return (
        <AppLayout>
            <AppTitle>Affiliate program</AppTitle>
                <Tabs
                    defaultActiveKey="provizie"
                    id="affiliate-tabs"
                >
                    <Tab eventKey="provizie" title="Provízie" >
                        <AffiliateCommissions></AffiliateCommissions>
                    </Tab>
                    <Tab eventKey="odkazy" title="Odkazy" >
                        <AffiliateLink></AffiliateLink>
                    </Tab>
                    <Tab eventKey="vyplata" title="Výplata" >
                        <AffiliatePayout></AffiliatePayout>
                    </Tab>
                    <Tab eventKey="provizne" title="Provízne listy" >
                        <AffiliateCommisionLetters></AffiliateCommisionLetters>
                    </Tab>
                    <Tab eventKey="promo" title="Promo materíaly" >
                        <AffiliatePromo></AffiliatePromo>
                    </Tab>
                </Tabs>
        </AppLayout>
    )
}

export default AffiliatePage
