import React, { useEffect, useState } from 'react'

import { Tab, Tabs } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'

import CalendarEventTypes from './CalendarEventTypes'
import ChangePswForm from './ChangePswForm'
import EmailSettingsForm from './EmailSettingsForm'
import UpdateProfileForm from './UpdateProfileForm'
import AppLayout from '../common/AppLayout'
import AppTitle from '../common/AppTitle'
import { useAuth } from '../common/Auth'
import NiceButton from '../common/NiceButton'
import { ClientFormWrapper } from '../common/styledComponents'
import axiosClient from '../service/axiosClient'

function UpdateProfilePage() {
    const {user, logout} = useAuth()
    const navigate = useNavigate()
    const location = useLocation()
    const [activeKey, setActiveKey] = useState<string>('profil')

    // Set active tab based on the URL hash on initial load
    useEffect(() => {
        const currentHash = location.hash.replace('#', '')
        if (currentHash) {
            setActiveKey(currentHash)
        }
    }, [location.hash])

    // Update URL hash when the tab changes
    const handleSelect = (key: string | null) => {
        if (key) {
            setActiveKey(key)
            navigate(`#${key}`, {replace: true})
        }
    }

    function deleteAccount() {
        if (confirm('Naozaj chcete zrušiť účet?')) {
            axiosClient.delete('user/' + user?.id).then(() => {
                logout(() => navigate('/'))
            })
        }
    }

    return (
        <AppLayout>
            <AppTitle>Nastavenia</AppTitle>

            <Tabs
                activeKey={activeKey} onSelect={handleSelect}
                id="setting-tabs"
            >
                <Tab eventKey="profil" title="Upraviť profil">
                    <ClientFormWrapper>
                        <div className="row d-flex">
                            <div className="col-md-8">
                                <UpdateProfileForm/>
                            </div>
                        </div>
                    </ClientFormWrapper>
                </Tab>
                <Tab eventKey="socials" title="Emailové nastavenia">
                    <ClientFormWrapper>
                        <div className="row d-flex">
                            <div className="col-md-8">
                                <EmailSettingsForm/>
                            </div>
                        </div>
                    </ClientFormWrapper>
                </Tab>
                <Tab eventKey="calendar-event-types" title="Typy udalostí v kalendári">
                    <ClientFormWrapper>
                        <div className="row d-flex">
                            <div className="col-md-12">
                                <CalendarEventTypes/>
                            </div>
                        </div>
                    </ClientFormWrapper>
                </Tab>
                <Tab eventKey="change-psw" title="Zmena hesla">
                    <ClientFormWrapper>
                        <div className="row d-flex">
                            <div className="col-md-8">
                                <ChangePswForm/>
                            </div>
                        </div>
                    </ClientFormWrapper>
                </Tab>
                <Tab eventKey="cancel-account" title="Zrušenie účtu">
                    <ClientFormWrapper>
                        <div className="row d-flex">
                            <div className="col-md-8">
                                <p>
                                    Zrušením účtu príjdete o všetky svoje uložené údaje, vrátane faktúr.<br/>
                                    Preto si ich pred zrušením stiahnite. Po potvrdení bude účet a aj všetky údaje
                                    vymazané po 30 dňoch. V tomto časovom horizonte je možné zrušenie anulovať.
                                </p>

                                <NiceButton className={'dark-pink-color'} onClick={deleteAccount} type="button"
                                            isLoading={false}>
                                    Vymazať účet
                                </NiceButton>
                            </div>
                        </div>
                    </ClientFormWrapper>
                </Tab>

            </Tabs>


            {/*<ClientFormWrapper>*/}
            {/*    <div className="row d-flex">*/}
            {/*        <div className="col-md-8">*/}
            {/*            <UpdateProfileForm/>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</ClientFormWrapper>*/}
        </AppLayout>
    )
}

export default UpdateProfilePage
