import React, { useEffect, useState } from 'react'

import type { AxiosError, AxiosResponse } from 'axios'
import { Field, FieldArray, Form, Formik } from 'formik'
import { Col, Row } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import AsyncSelect from 'react-select/async'

import { useAuth } from '../common/Auth'
import FormErrorMessage from '../common/FormErrorMessage'
import { generateAndDownloadInvoicePdfFile, loadOptionsForCompanySelector } from '../common/helpers'
import { datePickerDateFormat } from '../common/i18n'
import NiceButton from '../common/NiceButton'
import { ClientFormWrapper } from '../common/styledComponents'
import axiosClient, { SERVER_URL } from '../service/axiosClient'
import ClientDTO from '../types/Client'
import { CorporateBody, CorporateOption } from '../types/Corporate'
import { InvoiceFormData, PaymentType } from '../types/Invoice'


const formaUhradyOptions = [
    {value: PaymentType.BANK_TRANSFER, label: 'Bankový prevod'},
    {value: PaymentType.CASH, label: 'Hotovosť'},
]
const invoiceStatusOptions = [
    {value: 'PAID', label: 'Zaplatená'},
    {value: 'UNPAID', label: 'Nezaplatená'},
]

// 0%, 5%, 10%, 19%, 20%, 23%
const dphOptions = [
    {value: '5', label: '5%'},
    {value: '10', label: '10%'},
    {value: '19', label: '19%'},
    {value: '20', label: '20%'},
    {value: '23', label: '23%'},
]

interface Props {
    id?: number
}

let generatePdf = false
let sendPdf = false
export const InvoiceForm = ({id}: Props) => {
    const {user, updateUser} = useAuth()
    const [searchParams,] = useSearchParams()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const queryClientId = searchParams.get('clientId')
    console.log('id', id)
    console.log('id', typeof id)
    const navigate = useNavigate()
    const [clients, setClients] = useState<ClientDTO[]>([])
    const [defaultProviderName, setDefaultProviderName] = useState<string>(user?.businessName ?? '')
    const [defaultCustomerName, setDefaultCustomerName] = useState<string>('')
    const [invoice, setInvoice] = useState<InvoiceFormData>({
        constantId: '',
        createdAt: '',
        customerBankAccountNumber: '',
        customerBankCode: '',
        customerBankName: '',
        customerDph7aParagraph: false,
        customerBusinessName: '',
        customerCity: '',
        customerDic: '',
        customerEmail: '',
        customerFirstName: '',
        logo: user?.logo || '',
        signature: user?.signature || '',
        customerIban: '',
        customerIcDph: '',
        customerIco: '',
        customerLastName: '',
        customerPhone: '',
        customerStreet: '',
        customerSwift: '',
        customerWeb: '',
        preItemText: '',
        customerZipCode: '',
        invoiceCreatedByEmail: '',
        invoiceCreatedByName: '',
        invoiceCreationDate: new Date().toString(),
        invoiceDueDate: new Date(Date.now() + 14 * 24 * 60 * 60 * 1000).toString(),
        invoiceStatus: 'UNPAID',
        invoiceId: '',
        invoiceItems: [{
            dph: 0,
            dphValue: 0,
            price: 0,
            unitPrice: 0,
            title: '',
            quantity: 1,
            totalWithDph: 0,
            totalWithoutDph: 0
        }],
        orderDate: '',
        orderNumber: '',
        paymentType: PaymentType.BANK_TRANSFER,
        providerBankAccountNumber: user?.bankAccountNumber ?? '',
        providerBankCode: user?.bankCode ?? '',
        providerBankName: user?.bankName ?? '',
        providerBusinessName: user?.businessName ?? '',
        providerDph7aParagraph: !!user?.getdph7aParagraph,
        providerVatPayer: !!user?.vatPayer,
        providerCity: user?.billingCity ?? '',
        providerDic: user?.dic ?? '',
        providerEmail: user?.email ?? '',
        providerIban: user?.iban ?? '',
        providerIcDph: user?.icDph ?? '',
        providerIco: user?.ico ?? '',
        providerPhone: '',
        providerStreet: user?.billingStreet ?? '',
        providerSwift: user?.swift ?? '',
        providerWeb: '',
        providerZipCode: user?.billingZipCode ?? '',
        referenceId: '',
        serviceRealizationDate: new Date().toString(),
        total: 0,
        dphValue: 0,
        totalWithoutDph: 0,
        totalWithDph: 0,
        updatedAt: ''
    })
    const {t} = useTranslation()
    const [logoUrl, setLogoUrl] = useState<string>('')
    const [signatureUrl, setSignatureUrl] = useState<string>('')

    useEffect(() => {
        axiosClient.get('client').then(response => {
            console.log('response', response)
            setClients(response.data)
        })
    }, [])

    function notExistingInvoice() {
        navigate('/app/invoices')
    }

    useEffect(() => {
        if (id && id > 0) {
            axiosClient.get('invoice/' + id)
                .then(response => {
                    console.log('response', response)
                    // const postTmp = response.data

                    if (!response.data.id) {
                        notExistingInvoice()
                        return
                    }

                    if (response.data.logo) {
                        setLogoUrl(`${SERVER_URL}/storage/${response.data.logo}`)
                    }
                    // else if (user?.logo) {
                    //     setLogoUrl(`${SERVER_URL}/storage/${user.logo}`)
                    // }

                    if (response.data.signature) {
                        setSignatureUrl(`${SERVER_URL}/storage/${response.data.signature}`)
                    }
                    // else if (user?.signature) {
                    //     setSignatureUrl(`${SERVER_URL}/storage/${user.signature}`)
                    // }


                    setInvoice({
                        preItemText: '',
                        ...response.data
                    })
                    if (response.data.providerBusinessName) {
                        setDefaultProviderName(response.data.providerBusinessName)
                    }
                    if (response.data.customerBusinessName) {
                        setDefaultCustomerName(response.data.customerBusinessName)
                    }
                }, (e) => {
                    notExistingInvoice()
                })
        } else {
            if (user?.logo) {
                setLogoUrl(`${SERVER_URL}/storage/${user.logo}`)
            }
            if (user?.signature) {
                setSignatureUrl(`${SERVER_URL}/storage/${user.signature}`)
            }
            setInvoice((prevState) => ({
                ...prevState,
                preItemText: 'Fakturujem Vám za:',
                logo: user?.logo || '',
                signature: user?.signature || ''
            }))
            axiosClient.get('invoice/generate/next-invoice-parameters')
                .then(response => {
                        setInvoice(
                            (prevState) => ({
                                ...prevState,
                                invoiceId: response.data.invoiceId,
                                referenceId: response.data.referenceId
                            })
                        )
                    }
                )
                .catch((error) => {
                    toast.error('Posledné číslo faktúry uložené v profile má nesprávny formát!')
                })
        }
    }, [id])

    function sendPdfFile(id: string) {
        axiosClient.get('/invoice/generate/' + id).then(response => {
            axiosClient.get('/invoice/send-by-email/' + id).then(response => {
                toast.success('Faktúra bola úspešne odoslaná')
            })
        })
    }

    function generatePdfFile(id: string, invoiceId: string) {
        generateAndDownloadInvoicePdfFile(id, invoiceId)
    }

    const submit = async (values: InvoiceFormData) => {
        console.log('values', values)

        try {
            // const response = await updateProfile({...values})
            setIsLoading(true)
            const request = {
                ...values,
                invoiceCreationDate: values.invoiceCreationDate ? new Date(values.invoiceCreationDate).toISOString().split('T')[0] : '',
                invoiceDueDate: values.invoiceDueDate ? new Date(values.invoiceDueDate).toISOString().split('T')[0] : '',
                serviceRealizationDate: values.serviceRealizationDate ? new Date(values.serviceRealizationDate).toISOString().split('T')[0] : '',
                totalWithDph: Array.isArray(values.invoiceItems) && values.invoiceItems.length ? values.invoiceItems.reduce((acc, service) => acc + service.totalWithDph, 0) : 0,
                totalWithoutDph: Array.isArray(values.invoiceItems) && values.invoiceItems.length ? values.invoiceItems.reduce((acc, service) => acc + service.totalWithoutDph, 0) : 0,
                dphValue: Array.isArray(values.invoiceItems) && values.invoiceItems.length ? values.invoiceItems.reduce((acc, service) => acc + service.dph, 0) : 0,
                // logo: values.logo || user?.logo || '',
                // signature: values.signature || user?.signature || '',
                // invoiceDu: values.invoiceCreationDate ? new Date(values.invoiceCreationDate).toISOString().split('T')[0],

                // title: values.title ?? '',
                // description: values.description ?? '',
                // clientId: values.clientId as unknown as number,
                // socialNetwork: values.socialNetwork as SocialNetwork,
                // publishFrequency: values.publishFrequency as PublishFrequency,
                // photo: values.photo as string,
                // showInCalendar: values.showInCalendar,
                // hashTags: '',
                // publishAt: values.publishAt,
                // publishFrom: '',
                // publishUntil: '',
            }

            // if (id) {
            //     request.id = id
            // }
            //
            //
            // // if (values.publishAt) {
            // //     const publishAt = new Date(values.publishAt)
            // //     const hours = publishAt.getHours().toString().padStart(2, '0')
            // //     const minutes = publishAt.getMinutes().toString().padStart(2, '0')
            // //
            // //     request.publishAt = `${hours}:${minutes}`
            // // }
            // if (values.publishFrom) {
            //     const publishFrom = new Date(values.publishFrom)
            //     request.publishFrom = publishFrom.toISOString()
            // }
            // if (values.publishUntil) {
            //     const publishUntil = new Date(values.publishUntil)
            //     request.publishUntil = publishUntil.toISOString()
            // }

            const response = await axiosClient.post('invoice', request)
            await updateUser()

            if (id && id > 0) {
                if (generatePdf) {
                    generatePdfFile(id.toString(), values.invoiceId)
                } else if (sendPdf) {
                    sendPdfFile(id.toString())
                } else {
                    // window.scrollTo(0, 0)
                    toast.success('Faktúra bola úspešne upravená')
                }

            } else if (response.data > 0) {
                if (generatePdf) {
                    generatePdfFile(response.data.id.toString(), values.invoiceId)
                } else if (sendPdf) {
                    sendPdfFile(response.data.id.toString())
                } else {
                    navigate('/app/invoices')
                    toast.success('Faktúra bola úspešne vytvorená')
                }
            }
            // actions.resetForm()
        } catch (error: any) {
            console.log('error', error)
            toast.error('Faktúru sa nepodarilo uložiť')
        } finally {
            setIsLoading(false)
        }
    }

    const validate = (values: Partial<InvoiceFormData>) => {
        const errors: any = {}

        values.invoiceItems?.forEach((item, index) => {
            const itemErrors: { title?: string; unitPrice?: string; quantity?: string } = {}

            if (!item.title) {
                itemErrors.title = t('required_field_error')
            }
            if (!item.unitPrice || item.unitPrice <= 0) {
                itemErrors.unitPrice = t('required_field_error')
            }
            if (!item.quantity || item.quantity <= 0) {
                itemErrors.quantity = t('required_field_error')
            }

            if (Object.keys(itemErrors).length > 0) {
                if (!errors.invoiceItems) {
                    errors.invoiceItems = {}
                }
                errors.invoiceItems[index] = itemErrors
            }
        })

        if (!values?.invoiceId) {
            errors.invoiceId = t('required_field_error')
        }
        if (!values?.paymentType) {
            errors.paymentType = t('required_field_error')
        }

        if (!values?.invoiceCreationDate) {
            errors.invoiceCreationDate = t('required_field_error')
        }
        if (!values?.invoiceDueDate) {
            errors.invoiceDueDate = t('required_field_error')
        }
        if (!values?.serviceRealizationDate) {
            errors.serviceRealizationDate = t('required_field_error')
        }
        if (!values?.customerBusinessName) {
            errors.customerBusinessName = t('required_field_error')
        }

        return errors
    }

    return (
        <>
            <Formik
                initialValues={invoice}
                enableReinitialize
                validate={validate}
                onSubmit={submit}
            >{({values, setFieldValue, submitForm}) => {
                return (<>
                    <Form>
                        {/*<PrettyPrintJson data={values}/>*/}

                        <div className="row d-flex mb-4">
                            <div className="col-md-12">
                                <h5 className="text-left mb-2">Všeobecné informácie</h5>
                                <ClientFormWrapper>
                                    <div className="row mb-3">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="invoiceId">Číslo faktúry</label>
                                                <Field id="invoiceId" name="invoiceId"
                                                       className="form-control"
                                                        onBlur={(e: Event) => {
                                                            const newInvoiceId = (e.target as HTMLInputElement).value
                                                            axiosClient.get(`/invoice/generate/reference-id/${newInvoiceId}`)
                                                                .then((response: AxiosResponse<string>) => setFieldValue('referenceId', response.data))
                                                                .catch((error: AxiosError) => {
                                                                    if (400 === error.response?.status) {
                                                                        toast.error(error.response?.data)
                                                                    }
                                                                })
                                                        }}/>
                                                <FormErrorMessage name="invoiceId"/>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="orderNumber">Číslo objednávky</label>
                                                <Field id="orderNumber" name="orderNumber"
                                                       className="form-control"/>
                                                <FormErrorMessage name="orderNumber"/>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div
                                                className={'form-group'}>
                                                <label htmlFor="paymentType">
                                                    Forma úhrady
                                                </label>
                                                <Field as="select" id="paymentType" name="paymentType"
                                                       className="form-select">
                                                    {formaUhradyOptions.map((option) => (
                                                        <option key={option.value}
                                                                value={option.value}>{option.label}</option>))}
                                                </Field>
                                                <FormErrorMessage name="paymentType"/>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="referenceId">Variabilný symbol</label>
                                                <Field id="referenceId" name="referenceId"
                                                       className="form-control"/>
                                                <FormErrorMessage name="referenceId"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="invoiceCreationDate">Dátum vystavenia</label>
                                                <DatePicker
                                                    scrollableYearDropdown
                                                    yearDropdownItemNumber={100}
                                                    locale="sk"
                                                    dateFormat={datePickerDateFormat}
                                                    showYearDropdown
                                                    selected={(values.invoiceCreationDate && new Date(values.invoiceCreationDate)) || null}
                                                    onChange={(value: any) => {
                                                        setFieldValue('invoiceCreationDate', value)
                                                    }}
                                                    id="invoiceCreationDate"
                                                    name="invoiceCreationDate"
                                                    className="form-control"/>
                                                <FormErrorMessage name="invoiceCreationDate"/>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="serviceRealizationDate">Dátum dodania</label>
                                                <DatePicker
                                                    scrollableYearDropdown
                                                    yearDropdownItemNumber={100}
                                                    locale="sk"
                                                    dateFormat={datePickerDateFormat}
                                                    showYearDropdown
                                                    selected={(values.serviceRealizationDate && new Date(values.serviceRealizationDate)) || null}
                                                    onChange={(value: any) => {
                                                        setFieldValue('serviceRealizationDate', value)
                                                    }}
                                                    id="serviceRealizationDate"
                                                    name="serviceRealizationDate"
                                                    className="form-control"/>
                                                <FormErrorMessage name="serviceRealizationDate"/>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="invoiceDueDate">Dátum splatnosti</label>
                                                <DatePicker
                                                    scrollableYearDropdown
                                                    yearDropdownItemNumber={100}
                                                    locale="sk"
                                                    dateFormat={datePickerDateFormat}
                                                    showYearDropdown
                                                    selected={(values.invoiceDueDate && new Date(values.invoiceDueDate)) || null}
                                                    onChange={(value: any) => {
                                                        setFieldValue('invoiceDueDate', value)
                                                    }}
                                                    id="invoiceDueDate"
                                                    name="invoiceDueDate"
                                                    className="form-control"/>
                                                <FormErrorMessage name="invoiceDueDate"/>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div
                                                className={'form-group'}>
                                                <label htmlFor="invoiceStatus">
                                                    Stav faktúry
                                                </label>
                                                <Field as="select" id="invoiceStatus" name="invoiceStatus"
                                                       className="form-select">
                                                    {invoiceStatusOptions.map((option) => (
                                                        <option key={option.value}
                                                                value={option.value}>{option.label}</option>))}
                                                </Field>
                                                <FormErrorMessage name="invoiceStatus"/>
                                            </div>
                                        </div>
                                    </div>
                                </ClientFormWrapper>
                            </div>
                        </div>


                        <div className="row d-flex mb-4">
                            <div className="col-md-6">
                                <h5 className="text-left mb-2">Dodávateľ</h5>
                                <ClientFormWrapper>
                                    <div className="row mb-3">
                                        <div className="form-group">
                                            <label htmlFor="providerBusinessName">Názov spoločnosti</label>

                                            <AsyncSelect
                                                placeholder={'Vyhľadajte spoločnosť'}
                                                isDisabled={true}
                                                className={'async-select'}
                                                {...(defaultProviderName ? {
                                                    value: {
                                                        label: defaultProviderName,
                                                        value: {} as CorporateBody
                                                    } as CorporateOption
                                                } : null)}
                                                classNamePrefix={'async-select'}
                                                loadingMessage={(inputValue) => <>Vyhľadávam...</>}
                                                noOptionsMessage={(inputValue) => <>{inputValue.inputValue.length ? 'Nenašli sa žiadne výsledky' : 'Začnite písať...'}</>}
                                                onChange={(item) => {
                                                    console.log('sel', item)
                                                    setDefaultProviderName(item?.label as string)
                                                    setFieldValue('providerBusinessName', item?.label)
                                                    setFieldValue('providerIco', item?.value?.cin)
                                                    setFieldValue('providerDic', item?.value?.tin)
                                                    setFieldValue('providerIcDph', item?.value?.vatin)
                                                    setFieldValue('providerStreet', item?.value?.formatted_street)
                                                    setFieldValue('providerCity', item?.value?.municipality)
                                                    setFieldValue('providerZipCode', item?.value?.postal_code)

                                                    setFieldValue('providerVatPayer', false)
                                                    setFieldValue('providerDph7aParagraph', false)
                                                    if (item?.value?.vatin_paragraph === '§4') {
                                                        setFieldValue('providerVatPayer', true)
                                                        setFieldValue('providerDph7aParagraph', false)
                                                    }
                                                    if (item?.value?.vatin_paragraph === '§7' || item?.value?.vatin_paragraph === '§7a') {
                                                        setFieldValue('providerDph7aParagraph', true)
                                                        setFieldValue('providerVatPayer', false)
                                                    }
                                                }}
                                                cacheOptions
                                                loadOptions={loadOptionsForCompanySelector}/>

                                            <Field id="providerBusinessName" name="providerBusinessName"
                                                   className="form-control d-none"/>
                                            <FormErrorMessage name="providerBusinessName"/>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="form-group">
                                            <label htmlFor="providerIco">IČO</label>
                                            <Field id="providerIco" name="providerIco"
                                                   disabled
                                                   className="form-control"/>
                                            <FormErrorMessage name="providerIco"/>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="form-group">
                                            <label htmlFor="providerDic">DIČ</label>
                                            <Field id="providerDic" name="providerDic"
                                                   disabled
                                                   className="form-control"/>
                                            <FormErrorMessage name="providerDic"/>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group">
                                            <label htmlFor="providerIcDph">IČ DPH</label>
                                            <Field id="providerIcDph" name="providerIcDph"
                                                   disabled
                                                   className="form-control"/>
                                            <FormErrorMessage name="providerIcDph"/>
                                        </div>
                                    </div>


                                    <div className="row">
                                        <div className="form-group ">
                                            <div className="form-check">
                                                <input
                                                    name="vatPayer"
                                                    onChange={(e) => {
                                                        setFieldValue('providerVatPayer', false)
                                                        setFieldValue('providerDph7aParagraph', false)
                                                    }}
                                                    checked={!values.providerVatPayer && !values.providerDph7aParagraph}
                                                    type="checkbox"
                                                    disabled
                                                    id="vatPayerFalse"
                                                    className="form-check-input"/>
                                                <label className="form-check-label" htmlFor="vatPayerFalse">
                                                    Subjekt nie je platiteľ DPH
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group ">
                                            <div className="form-check">
                                                <input
                                                    name="vatPayer"
                                                    onChange={(e) => {
                                                        setFieldValue('providerVatPayer', true)
                                                        setFieldValue('dph7aParagraph', false)
                                                    }}
                                                    checked={values.providerVatPayer && !values.providerDph7aParagraph}
                                                    type="checkbox"
                                                    disabled
                                                    id="vatPayerTrue"
                                                    className="form-check-input"/>
                                                <label className="form-check-label" htmlFor="vatPayerTrue">
                                                    Subjekt je platiteľ DPH
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group ">
                                            <div className="form-check">
                                                <input
                                                    name="vatPayer"
                                                    onChange={(e) => {
                                                        setFieldValue('providerVatPayer', false)
                                                        setFieldValue('providerDph7aParagraph', true)
                                                    }}
                                                    checked={values.providerDph7aParagraph}
                                                    type="checkbox"
                                                    disabled
                                                    id="providerDph7aParagraph"
                                                    className="form-check-input"/>
                                                <label className="form-check-label" htmlFor={'providerDph7aParagraph'}>
                                                    Subjekt je registrovaný podľa §7/§7a
                                                </label>
                                            </div>
                                        </div>
                                    </div>


                                    {/*<div className="row">*/}
                                    {/*    <div className="form-group ">*/}
                                    {/*        <div className="form-check">*/}
                                    {/*            <Field*/}
                                    {/*                type="checkbox" id="vatPayer"*/}
                                    {/*                disabled*/}
                                    {/*                className="form-check-input"*/}
                                    {/*                name="providerVatPayer"/>*/}
                                    {/*            <label htmlFor={'providerVatPayer'} className="form-check-label">*/}
                                    {/*                Subjekt je platiteľ DPH*/}
                                    {/*            </label>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    {/*<div className="row">*/}
                                    {/*    <div className="form-group">*/}
                                    {/*        <div className="form-check">*/}
                                    {/*            <Field type="checkbox" disabled id="providerDph7aParagraph"*/}
                                    {/*                   className="form-check-input"*/}
                                    {/*                   name="providerDph7aParagraph"/>*/}
                                    {/*            <label htmlFor={'providerDph7aParagraph'} className="form-check-label">*/}
                                    {/*                Subjekt je registrovaný pre DPH podľa §7a*/}
                                    {/*            </label>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <div className="row mb-3">
                                        <div className="form-group">
                                            <label htmlFor="providerStreet">Ulica</label>
                                            <Field id="providerStreet" disabled name="providerStreet"
                                                   className="form-control"/>
                                            <FormErrorMessage name="providerStreet"/>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-md-8">
                                            <div className="form-group">
                                                <label htmlFor="providerCity">Mesto</label>
                                                <Field id="providerCity" disabled name="providerCity"
                                                       className="form-control"/>
                                                <FormErrorMessage name="providerCity"/>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="providerZipCode">PSČ</label>
                                                <Field id="providerZipCode" disabled name="providerZipCode"
                                                       className="form-control"/>
                                                <FormErrorMessage name="providerZipCode"/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <div className="form-group">
                                            <label htmlFor="providerIban">IBAN</label>
                                            <Field id="providerIban" disabled name="providerIban"
                                                   className="form-control"/>
                                            <FormErrorMessage name="providerIban"/>
                                        </div>
                                    </div>
                                </ClientFormWrapper>
                            </div>
                            <div className="col-md-6">
                                <h5 className="text-left mb-2">Odberateľ</h5>
                                <ClientFormWrapper>
                                    <div className="row mb-3">
                                        <div className="form-group">
                                            <label htmlFor="customerBusinessName">Názov spoločnosti</label>
                                            <AsyncSelect
                                                className={'async-select'}
                                                classNamePrefix={'async-select'}
                                                {...(defaultCustomerName ? {
                                                    value: {
                                                        label: defaultCustomerName,
                                                        value: {} as CorporateBody
                                                    } as CorporateOption
                                                } : null)}
                                                placeholder={'Vyhľadajte spoločnosť'}
                                                loadingMessage={(inputValue) => <>Vyhľadávam...</>}
                                                noOptionsMessage={(inputValue) => <>{inputValue.inputValue.length ? 'Nenašli sa žiadne výsledky' : 'Začnite písať...'}</>}
                                                onChange={(item) => {
                                                    console.log('sel', item)
                                                    setDefaultCustomerName(item?.label as string)
                                                    setFieldValue('customerBusinessName', item?.label)
                                                    setFieldValue('customerIco', item?.value?.cin)
                                                    setFieldValue('customerDic', item?.value?.tin)
                                                    setFieldValue('customerIcDph', item?.value?.vatin)
                                                    setFieldValue('customerStreet', item?.value?.formatted_street)
                                                    setFieldValue('customerCity', item?.value?.municipality)
                                                    setFieldValue('customerZipCode', item?.value?.postal_code)
                                                }}
                                                cacheOptions
                                                loadOptions={loadOptionsForCompanySelector}/>
                                            <Field id="customerBusinessName" name="customerBusinessName"
                                                   className="form-control d-none"/>
                                            <FormErrorMessage name="customerBusinessName"/>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="form-group">
                                            <label htmlFor="customerIco">IČO</label>
                                            <Field id="customerIco" name="customerIco"
                                                   className="form-control"/>
                                            <FormErrorMessage name="customerIco"/>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="form-group">
                                            <label htmlFor="customerDic">DIČ</label>
                                            <Field id="customerDic" name="customerDic"
                                                   className="form-control"/>
                                            <FormErrorMessage name="customerDic"/>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="form-group">
                                            <label htmlFor="customerIcDph">IČ DPH</label>
                                            <Field id="customerIcDph" name="customerIcDph"
                                                   className="form-control"/>
                                            <FormErrorMessage name="customerIcDph"/>
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <div className="form-group">
                                            <label htmlFor="customerStreet">Ulica</label>
                                            <Field id="customerStreet" name="customerStreet"
                                                   className="form-control"/>
                                            <FormErrorMessage name="customerStreet"/>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-md-8">
                                            <div className="form-group">
                                                <label htmlFor="customerCity">Mesto</label>
                                                <Field id="customerCity" name="customerCity"
                                                       className="form-control"/>
                                                <FormErrorMessage name="customerCity"/>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="customerZipCode">PSČ</label>
                                                <Field id="customerZipCode" name="customerZipCode"
                                                       className="form-control"/>
                                                <FormErrorMessage name="customerZipCode"/>
                                            </div>
                                        </div>
                                    </div>
                                </ClientFormWrapper>
                            </div>
                        </div>
                        <div className="row d-flex mb-4">
                            <div className="col-md-12">
                                <h5 className="text-left mb-1">Text pred položkami</h5>
                                <ClientFormWrapper>
                                    <div className="form-group">
                                        <Field
                                            id="preItemText"
                                            name="preItemText"
                                            className="form-control"/>
                                        <FormErrorMessage name="preItemText"/>
                                    </div>
                                </ClientFormWrapper>
                            </div>
                        </div>

                        <div className="row d-flex mb-4">
                            <div className="col-md-12">
                                <h5 className="text-left mb-1">Položky</h5>
                                <FieldArray
                                    name="invoiceItems"
                                    render={arrayHelpers => (
                                        <>
                                            {Array.isArray(values.invoiceItems) ? values.invoiceItems.map((service, index) => (
                                                <ClientFormWrapper key={index} className="mb-2 position-relative">
                                                    {index !== 0 &&
                                                        <i className="bi bi-x-lg position-absolute cursor-pointer"
                                                           onClick={() => {
                                                               arrayHelpers.remove(index)
                                                           }}
                                                           style={{top: '5px', right: '10px'}}></i>}
                                                    <div className="row mb-3">
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label htmlFor="total">Názov</label>
                                                                <Field
                                                                    className="form-control"
                                                                    name={`invoiceItems[${index}].title`}/>
                                                                <FormErrorMessage
                                                                    name={`invoiceItems[${index}].title`}/>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="form-group mb-0">
                                                                <label
                                                                    htmlFor={`invoiceItems[${index}].quantity`}>Množstvo</label>

                                                                <input
                                                                    className="form-control"
                                                                    type="number"
                                                                    value={values.invoiceItems[index].quantity}
                                                                    id={`invoiceItems[${index}].quantity`}
                                                                    name={`invoiceItems[${index}].quantity`}
                                                                    onChange={(e) => {
                                                                        setFieldValue(`invoiceItems[${index}].quantity`, e.target.value)

                                                                        const itemTotal = +e.target.value * values.invoiceItems[index].unitPrice
                                                                        const itemDph = itemTotal * values.invoiceItems[index].dphValue / 100
                                                                        const itemTotalWithDph = itemTotal + itemDph

                                                                        setFieldValue(`invoiceItems[${index}].dph`, itemDph)
                                                                        setFieldValue(`invoiceItems[${index}].totalWithoutDph`, itemTotal)
                                                                        setFieldValue(`invoiceItems[${index}].totalWithDph`, itemTotalWithDph)
                                                                    }}/>
                                                                <FormErrorMessage
                                                                    name={`invoiceItems[${index}].quantity`}/>

                                                                {/*<Field*/}
                                                                {/*    className="form-control"*/}
                                                                {/*    name={`invoiceItems[${index}].quantity`}/>*/}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="form-group mb-0">
                                                                <label htmlFor="unitPrice">Jedn. cena</label>

                                                                <input
                                                                    className="form-control"
                                                                    type="number"
                                                                    value={values.invoiceItems[index].unitPrice}
                                                                    id={`invoiceItems[${index}].unitPrice`}
                                                                    name={`invoiceItems[${index}].unitPrice`}
                                                                    onChange={(e) => {
                                                                        setFieldValue(`invoiceItems[${index}].unitPrice`, e.target.value)

                                                                        const itemTotal = +e.target.value * values.invoiceItems[index].quantity
                                                                        const itemDph = itemTotal * values.invoiceItems[index].dphValue / 100
                                                                        const itemTotalWithDph = itemTotal + itemDph

                                                                        setFieldValue(`invoiceItems[${index}].dph`, itemDph)
                                                                        setFieldValue(`invoiceItems[${index}].totalWithoutDph`, itemTotal)
                                                                        setFieldValue(`invoiceItems[${index}].totalWithDph`, itemTotalWithDph)

                                                                    }}/>
                                                                <FormErrorMessage
                                                                    name={`invoiceItems[${index}].unitPrice`}/>

                                                                {/*<Field*/}
                                                                {/*    on*/}
                                                                {/*    className="form-control"*/}
                                                                {/*    name={`invoiceItems[${index}].unitPrice`}/>*/}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="form-group mb-0">
                                                                <label htmlFor="dph">Sadzba DPH %</label>
                                                                {/*dphOptions*/}

                                                                <select
                                                                    id={`invoiceItems[${index}].dph`}
                                                                    name={`invoiceItems[${index}].dph`}
                                                                    className="form-select"
                                                                    value={values.invoiceItems[index].dph + ''}
                                                                    onChange={(e) => {
                                                                        console.log('e.target.value', e.target.value)
                                                                        setFieldValue(`invoiceItems[${index}].dph`, parseInt(e.target.value))

                                                                        const itemTotal = values.invoiceItems[index].unitPrice * values.invoiceItems[index].quantity
                                                                        const itemDph = itemTotal * +e.target.value / 100
                                                                        const itemTotalWithDph = itemTotal + itemDph
                                                                        console.log('itemTotal', itemTotal)
                                                                        setFieldValue(`invoiceItems[${index}].dphValue`, itemDph)
                                                                        setFieldValue(`invoiceItems[${index}].totalWithoutDph`, itemTotal)
                                                                        setFieldValue(`invoiceItems[${index}].totalWithDph`, itemTotalWithDph)


                                                                    }}>
                                                                    <option value="0">0%</option>
                                                                    {values.providerVatPayer && dphOptions.map((option) => (
                                                                        <option
                                                                            key={option.value}

                                                                            value={option.value}>
                                                                            {option.label}
                                                                        </option>))}
                                                                </select>


                                                                {/*<Field*/}
                                                                {/*    className="form-control"*/}
                                                                {/*    name={`invoiceItems[${index}].dphValue`}/>*/}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="form-group mb-0">
                                                                <label htmlFor="totalWithDph">Celková cena</label>
                                                                <Field
                                                                    className="form-control"
                                                                    name={`invoiceItems[${index}].totalWithDph`}/>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </ClientFormWrapper>
                                            )) : null}

                                            <button
                                                onClick={() => {
                                                    arrayHelpers.push({
                                                        dph: 0,
                                                        dphValue: 0,
                                                        price: 0,
                                                        unitPrice: 0,
                                                        title: '',
                                                        quantity: 1,
                                                        totalWithDph: 0,
                                                        totalWithoutDph: 0
                                                    })
                                                    // setFieldValue('invoiceItems', [...values.invoiceItems, {}])
                                                }}
                                                type="button"
                                                className="btn btn-primary mt-2 btn-amani w-auto">
                                                Pridať položku
                                            </button>
                                        </>
                                    )}
                                />

                                {/*{values.invoiceItems.map((service, index) => (*/}
                                {/*    <ClientFormWrapper key={index} className="mb-2 position-relative">*/}
                                {/*        {index !== 0 && <i className="bi bi-x-lg position-absolute cursor-pointer"*/}
                                {/*            onClick={() => {*/}
                                {/*                console.log('index', index)*/}
                                {/*                const currentItems = [...values.invoiceItems]*/}
                                {/*                console.log('currentItems', currentItems)*/}
                                {/*                currentItems.splice(index, 1)*/}
                                {/*                setFieldValue('invoiceItems', currentItems)*/}
                                {/*            }}*/}
                                {/*            style={{top: '5px', right: '10px'}}></i>}*/}
                                {/*        <div className="row mb-3">*/}
                                {/*            <div className="col-md-4">*/}
                                {/*                <div className="form-group">*/}
                                {/*                    <label htmlFor="total">Názov</label>*/}
                                {/*                    <Field id="total" name="total"*/}
                                {/*                           className="form-control"/>*/}
                                {/*                    <FormErrorMessage name="total"/>*/}
                                {/*                </div>*/}
                                {/*            </div>*/}
                                {/*            <div className="col-md-4">*/}
                                {/*                <div className="form-group mb-0">*/}
                                {/*                    <label htmlFor="inputEmail3">Dátum dodania</label>*/}

                                {/*                    <input type="email" className="form-control" id="inputEmail3"/>*/}
                                {/*                </div>*/}
                                {/*            </div>*/}
                                {/*            <div className="col-md-4">*/}
                                {/*                <div className="form-group mb-0">*/}
                                {/*                    <label htmlFor="inputEmail3">Dátum splatnosti</label>*/}

                                {/*                    <input type="email" className="form-control" id="inputEmail3"/>*/}
                                {/*                </div>*/}
                                {/*            </div>*/}

                                {/*        </div>*/}
                                {/*    </ClientFormWrapper>)*/}
                                {/*)}*/}

                                {/*<button*/}
                                {/*    onClick={() => {*/}
                                {/*        setFieldValue('invoiceItems', [...values.invoiceItems, {}])*/}
                                {/*    }}*/}
                                {/*    type="button"*/}
                                {/*    className="btn btn-primary mt-2 btn-amani w-auto">*/}
                                {/*    Pridať položku*/}
                                {/*</button>*/}
                            </div>
                        </div>

                        <div className="row d-flex mb-4">
                            <div className="col-md-12">
                                <h5 className="text-left mb-2">Celkové sumy</h5>
                                <ClientFormWrapper>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="totalWithoutDph">Celkovo</label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    readOnly
                                                    value={Array.isArray(values.invoiceItems) && values.invoiceItems.length ? values.invoiceItems.reduce((acc, service) => acc + service.totalWithoutDph, 0) : 0}/>
                                                {/*<Field id="totalWithoutDph" name="totalWithoutDph"*/}
                                                {/*       className="form-control"/>*/}
                                                {/*<FormErrorMessage name="totalWithoutDph"/>*/}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="dph">DPH</label>
                                                <input
                                                    type="number"
                                                    readOnly
                                                    value={Array.isArray(values.invoiceItems) && values.invoiceItems.length ? values.invoiceItems.reduce((acc, service) => acc + service.dphValue, 0) : 0}
                                                    className="form-control"/>
                                                {/*<Field id="dphValue" name="dphValue"*/}
                                                {/*       className="form-control"/>*/}
                                                {/*<FormErrorMessage name="dphValue"/>*/}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="totalWithDph">Celkovo s DPH</label>
                                                <input
                                                    type="number" readOnly
                                                    className={'form-control'}
                                                    value={Array.isArray(values.invoiceItems) && values.invoiceItems.length ? values.invoiceItems.reduce((acc, service) => acc + service.totalWithDph, 0) : 0}/>
                                                {/*<Field id="totalWithDph" name="totalWithDph"*/}
                                                {/*       className="form-control"/>*/}
                                                {/*<FormErrorMessage name="totalWithDph"/>*/}
                                            </div>
                                        </div>
                                    </div>
                                </ClientFormWrapper>
                            </div>
                        </div>

                        <div className="row d-flex mb-4">
                            <div className="col-md-12">
                                <h5 className="text-left mb-2">Poznámka</h5>
                                <ClientFormWrapper>
                                    <div className="form-group">
                                        <Field
                                            as="textarea"
                                            id="note"
                                            name="note"
                                            className="form-control"/>
                                        <FormErrorMessage name="dphValue"/>
                                    </div>
                                </ClientFormWrapper>
                            </div>
                        </div>

                        <div className="row d-flex mb-4">
                            <div className="col-md-12">
                                <h5 className="text-left mb-2">Dodatočné informácie</h5>
                                <ClientFormWrapper>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="invoiceCreatedByName">Faktúru vystavil/a</label>
                                                <Field id="invoiceCreatedByName" name="invoiceCreatedByName"
                                                       className="form-control"/>
                                                <FormErrorMessage name="invoiceCreatedByName"/>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="invoiceCreatedByEmail">Email</label>
                                                <Field id="invoiceCreatedByEmail" name="invoiceCreatedByEmail"
                                                       className="form-control"/>
                                                <FormErrorMessage name="invoiceCreatedByEmail"/>
                                            </div>
                                        </div>
                                    </div>

                                    <Row>
                                        <Col>
                                            <div className="form-group">
                                                <label
                                                    htmlFor="logo">{'Logo'}</label>
                                                {logoUrl ?
                                                    <>
                                                        <img src={logoUrl as unknown as string}
                                                             style={{width: '100px', display: 'block'}}/>
                                                        <button
                                                            onClick={() => {
                                                                setLogoUrl('')
                                                                setFieldValue('logo', '')
                                                            }}
                                                            type="button"
                                                            className="button btn btn-primary btn-amani mt-3 mb-2">{'Vymazať logo'}</button>
                                                    </> : null}

                                                <label className="btn btn-like-input d-block mt-1"
                                                       htmlFor="logo">{'Nahrať logo'}</label>
                                                <input className={'d-none'} id="logo" name="logo" type="file"
                                                       onChange={(event) => {
                                                           if (event.target.files && event.target.files[0]) {
                                                               const formData = new FormData()
                                                               formData.append('file', event.target.files[0])

                                                               axiosClient.post('storage', formData).then((resp) => {
                                                                   console.log('resp', resp.data)
                                                                   setFieldValue('logo', resp.data)
                                                                   setLogoUrl(`${SERVER_URL}/storage/${resp.data}`)
                                                               })
                                                           }
                                                       }}/>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className="form-group">
                                                <label
                                                    htmlFor="signature">{'Podpis'}</label>
                                                {signatureUrl ?
                                                    <>
                                                        <img src={signatureUrl as unknown as string}
                                                             style={{width: '100px', display: 'block'}}/>
                                                        <button
                                                            onClick={() => {
                                                                setSignatureUrl('')
                                                                setFieldValue('signature', '')
                                                            }}
                                                            type="button"
                                                            className="button btn btn-primary btn-amani mt-3 mb-2">{'Vymazať podpis'}</button>
                                                    </> : null}

                                                <label className="btn btn-like-input d-block mt-1"
                                                       htmlFor="signature">{'Nahrať podpis'}</label>
                                                <input className={'d-none'} id="signature" name="signature" type="file"
                                                       onChange={(event) => {
                                                           if (event.target.files && event.target.files[0]) {
                                                               const formData = new FormData()
                                                               formData.append('file', event.target.files[0])

                                                               axiosClient.post('storage', formData).then((resp) => {
                                                                   console.log('resp', resp.data)
                                                                   setFieldValue('signature', resp.data)
                                                                   setSignatureUrl(`${SERVER_URL}/storage/${resp.data}`)
                                                               })
                                                           }
                                                       }}/>
                                            </div>
                                        </Col>
                                    </Row>
                                </ClientFormWrapper>
                            </div>
                        </div>

                        <div className="d-flex gap-2 mb-4">
                            <NiceButton type="button" onClick={() => {
                                generatePdf = false
                                sendPdf = false
                                submitForm()
                            }} isLoading={isLoading}>
                                Uložiť
                            </NiceButton>
                            <NiceButton
                                type="button"
                                onClick={() => {
                                    generatePdf = true
                                    sendPdf = false
                                    submitForm()
                                }}
                                isLoading={isLoading}>
                                Uložiť a stiahnúť
                            </NiceButton>
                            <NiceButton
                                type="button"
                                onClick={() => {
                                    generatePdf = false
                                    sendPdf = true
                                    submitForm()
                                }}
                                isLoading={isLoading}>
                                Uložiť a odoslať
                            </NiceButton>
                        </div>

                    </Form>
                </>)
            }}</Formik>
        </>
    )
}