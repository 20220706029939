import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import enLocale from '../translations/en-us.json'
import skLocale from '../translations/sk-sk.json'

export const datePickerDateFormat = 'd.M.yyyy'

const resources = {
    en: { translations: enLocale },
    sk: { translations: skLocale },
}

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: 'sk',
        defaultNS: 'translations',
        fallbackLng: 'sk',
        keySeparator: false, // we do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    })

export default i18n