import React, { PropsWithChildren } from 'react'

import { Link, useNavigate } from 'react-router-dom'

import { useAuth } from './Auth'
import logo from '../assets/logo-small.png'

export default function AppLayout({children}: PropsWithChildren<unknown>) {
    const auth = useAuth()
    const navigate = useNavigate()

    return (
        <>
            {/*<header className="navbar sticky-top bg-white flex-md-nowrap p-0 shadow" data-bs-theme="dark">*/}
            {/*    <a className="navbar-brand bg-white col-md-3 col-lg-2 me-0 px-3 fs-6 text-white" href="#">*/}
            {/*        <img src={logo} style={{width: '150px'}}/>*/}
            {/*    </a>*/}

            {/*    <ul className="navbar-nav flex-row d-md-none">*/}
            {/*        <li className="nav-item text-nowrap">*/}
            {/*            <button className="nav-link px-3 text-black" type="button" data-bs-toggle="offcanvas"*/}
            {/*                    data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false"*/}
            {/*                    aria-label="Toggle navigation">*/}
            {/*                Menu*/}
            {/*                /!*<svg className="bi">*!/*/}
            {/*                /!*    <use xlinkHref="#list"/>*!/*/}
            {/*                /!*</svg>*!/*/}
            {/*            </button>*/}
            {/*        </li>*/}
            {/*    </ul>*/}
            {/*</header>*/}


            <div className="container-fluid">
                <div className="row">
                    <div className="sidebar  col-md-3 col-lg-2 p-0 bg-white">

                        <div
                            className="d-flex flex-sm-nowrap justify-content-between align-items-center justify-content-md-center">
                            <a className="navbar-brand bg-white"
                               href="#">
                                <img src={logo} style={{width: '150px'}}/>
                            </a>
                            <ul className="navbar-nav flex-row d-md-none">
                                <li className="nav-item text-nowrap">
                                    <button className="nav-link px-3 text-black" type="button"
                                            data-bs-toggle="offcanvas"
                                            data-bs-target="#sidebarMenu" aria-controls="sidebarMenu"
                                            aria-expanded="false"
                                            aria-label="Toggle navigation">
                                        Menu
                                        {/*<svg className="bi">*/}
                                        {/*    <use xlinkHref="#list"/>*/}
                                        {/*</svg>*/}
                                    </button>
                                </li>
                            </ul>
                        </div>

                        <div className="offcanvas-md offcanvas-end bg-white" tabIndex={-1} id="sidebarMenu"
                             aria-labelledby="sidebarMenuLabel">
                            <div className="offcanvas-header">
                                <h5 className="offcanvas-title" id="sidebarMenuLabel">{auth.user?.fullName}</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="offcanvas"
                                        data-bs-target="#sidebarMenu" aria-label="Close"></button>
                            </div>
                            <div className="offcanvas-body d-md-flex flex-column p-0 pt-lg-3 overflow-y-auto">

                                {/*<h5 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-3 text-body-secondary text-uppercase">*/}
                                {/*    {auth.user?.fullName}*/}
                                {/*</h5>*/}

                                <ul className="nav flex-column">
                                    <li className="nav-item">
                                        <Link to={'/app/dashboard'}
                                              className="nav-link d-flex align-items-center gap-2 text-uppercase">
                                            <i className="bi bi-house"></i>
                                            {/*<svg className="bi">*/}
                                            {/*    <use xlinkHref="#house-fill"/>*/}
                                            {/*</svg>*/}
                                            Dashboard
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to={'/app/clients'}
                                              className="nav-link d-flex align-items-center gap-2 text-uppercase">
                                            <i className="bi bi-people"></i>

                                            Klienti
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to={'/app/calendar'}
                                              className="nav-link d-flex align-items-center gap-2 text-uppercase">
                                            <i className="bi bi-calendar"></i>
                                            Kalendár
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to={'/app/posts'}
                                              className="nav-link d-flex align-items-center gap-2 text-uppercase">
                                            <i className="bi bi-instagram"></i>
                                            Sociálne siete
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to={'/app/timesheet'}
                                              className="nav-link d-flex align-items-center gap-2 text-uppercase">
                                            <i className="bi bi-clock"></i>
                                            Timetracker
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to={'/app/invoices'}
                                              className="nav-link d-flex align-items-center gap-2 text-uppercase">
                                            <i className="bi bi-list-ol"></i>
                                            Fakturácia
                                        </Link>
                                    </li>

                                </ul>

                                {/*<h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-body-secondary text-uppercase">*/}
                                {/*    <span>Saved reports</span>*/}
                                {/*    <a className="link-secondary" href="#" aria-label="Add a new report">*/}
                                {/*        <svg className="bi">*/}
                                {/*            <use xlinkHref="#plus-circle"/>*/}
                                {/*        </svg>*/}
                                {/*    </a>*/}
                                {/*</h6>*/}
                                {/*<ul className="nav flex-column mb-auto">*/}
                                {/*    <li className="nav-item">*/}
                                {/*        <a className="nav-link d-flex align-items-center gap-2" href="#">*/}
                                {/*            /!*<svg className="bi">*!/*/}
                                {/*            /!*    <use xlinkHref="#file-earmark-text"/>*!/*/}
                                {/*            /!*</svg>*!/*/}
                                {/*            Current month*/}
                                {/*        </a>*/}
                                {/*    </li>*/}

                                {/*</ul>*/}

                                <hr className="my-3"/>

                                <ul className="nav flex-column mb-auto">
                                    <li className="nav-item">
                                        <Link to={'/app/edit-profile'}
                                              className="nav-link d-flex align-items-center gap-2">

                                            <i className="bi bi-person-gear"></i>
                                            {/*<svg className="bi">*/}
                                            {/*    <use xlinkHref="#gear-wide-connected"/>*/}
                                            {/*</svg>*/}
                                            Môj profil
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to={'/app/affiliate'}
                                              className="nav-link d-flex align-items-center gap-2">

                                            <i className="bi bi-diagram-3"></i>
                                            {/*<svg className="bi">*/}
                                            {/*    <use xlinkHref="#gear-wide-connected"/>*/}
                                            {/*</svg>*/}
                                            Affiliate
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link d-flex align-items-center gap-2" href="#"
                                           onClick={(e) => {
                                               e.preventDefault()
                                               auth.logout(() => navigate('/'))
                                           }}>
                                            <i className="bi bi-box-arrow-left"></i>
                                            {/*<svg className="bi">*/}
                                            {/*    <use xlinkHref="#door-closed"/>*/}
                                            {/*</svg>*/}
                                            Odhlásiť sa
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <main className="main-content col-md-9 ms-sm-auto col-lg-10 px-md-4">
                        {/*<TimeTrackingWidget />*/}

                        {children}
                    </main>
                </div>
            </div>
        </>

    )
}