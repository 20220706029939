import React from 'react'

import DatePicker from 'react-datepicker'

import { datePickerDateFormat } from '../common/i18n'
import { InvoicesFilter } from '../types/Invoice'

interface Props {
    filter: InvoicesFilter
    setFilter: React.Dispatch<React.SetStateAction<InvoicesFilter>>
}

export const InvoicesListFilter = ({filter, setFilter}: Props) => {
    return (
        <>
            <div className="rounded-content-body mb-4">
                <div className="row d-flex justify-content-center">
                    <div className="col-md-4">
                        <div className="form-group">
                            <label htmlFor="entryDate">Dátum od</label>
                            <DatePicker
                                scrollableYearDropdown
                                yearDropdownItemNumber={100}
                                locale="sk"
                                dateFormat={datePickerDateFormat}
                                showYearDropdown
                                selected={(filter.from && new Date(filter.from)) || null}
                                onChange={(value: any) => {
                                    setFilter((old) => ({...old, from: value}))
                                }}
                                id="from"
                                name="from"
                                className="form-control"/>
                        </div>

                    </div>

                    <div className="col-md-4">
                        <div className="form-group">
                            <label htmlFor="entryDate">Dátum do</label>
                            <DatePicker
                                scrollableYearDropdown
                                yearDropdownItemNumber={100}
                                locale="sk"
                                dateFormat={datePickerDateFormat}
                                showYearDropdown
                                selected={(filter.to && new Date(filter.to)) || null}
                                onChange={(value: any) => {
                                    setFilter((old) => ({...old, to: value}))
                                }}
                                id="until"
                                name="until"
                                className="form-control"/>
                        </div>

                    </div>
                    <div className="col-md-4">
                    </div>

                </div>
            </div>
        </>
    )
}