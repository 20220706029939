import React from 'react'

import { isFunction } from 'lodash'
import { useTranslation } from 'react-i18next'

import './Table.scss'
import SmartTableBodyRow from './TableBodyRow'
import { SmartTableProps } from './types'
import { useSmartTable } from './useSmartTable'
import { IdentifiableItemResponse } from '../../types/Common'

export const SmartTable = <T extends IdentifiableItemResponse>({
                                                                   renderRow,
                                                                   onRowClick,
                                                                   keyExtractor = (item: T) => {
                                                                       return item.id
                                                                   },
                                                                   HeadRow,
                                                               }: SmartTableProps<T>) => {
    const tableData = useSmartTable()
    const {t} = useTranslation()

    // @ts-ignore
    return (
        <table className="table fndt_table table-hover">
            <thead>
            <tr>{HeadRow}</tr>
            </thead>
            <tbody>
            {// @ts-ignore
                tableData.entries.length ? tableData.entries.map((item: T) => (
                    <SmartTableBodyRow onClick={() => isFunction(onRowClick) ? onRowClick(item) : null}
                                       key={keyExtractor(item)}>
                        {renderRow(item)}
                    </SmartTableBodyRow>
                )) : <tr>
                    <td colSpan={100}>{t('common.table.noResults')}</td>
                </tr>}
            </tbody>
        </table>
    )
}
