import React, { useState } from 'react'

import { Field, Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { useAuth } from '../common/Auth'
import FormErrorMessage from '../common/FormErrorMessage'
import NiceButton from '../common/NiceButton'
import PublicLayout from '../common/PublicLayout'

function ForgotPasswordPage() {
    const auth = useAuth()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [successMsg, setSuccessMsg] = useState<boolean>(false)
    const [errorMsg, setErrorMsg] = useState<string>('')
    const {t} = useTranslation()

    const validate = (values: any) => {
        const errors: any = {}

        if (!values.email.trim()) {
            errors.email = t('required_field_error')
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            errors.email = 'Nesprávny formát e-mailovej adresy'
        }

        return errors
    }

    const submit = async (values: any) => {
        setErrorMsg('')
        setSuccessMsg(false)
        setIsLoading(true)
        try {
            await auth.forgotPassword(values.email)
            setSuccessMsg(true)
        } catch (error: any) {
            setErrorMsg(error?.response?.data?.message || error)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <PublicLayout>
            <div className="row d-flex justify-content-center py-5">
                {/* <p>You must log in to view the page at {from}</p> */}
                <div className="col-md-5">
                    {successMsg ?
                        <>
                            <h2 className="text-center">Na zadaný e-mail sme ti zaslali link na obnovu hesla</h2>
                            <div className="text-center mt-5">
                                <Link to={'/login'} className="btn-amani not-uppercase not-bold">Späť na
                                    prihlásenie</Link>
                            </div>
                        </> :
                        <>
                            <h2 className="text-center">Zabudol si heslo?</h2>
                            <p className="text-center">
                                Žiadny problém. Zadaj svoju e-mailovú adresu a my ti pošleme odkaz na vygenerovanie
                                nového
                                hesla.
                            </p>

                            <div className="rounded-content-body">
                                <Formik
                                    initialValues={{
                                        email: ''
                                    }}
                                    validate={validate}
                                    onSubmit={submit}
                                >
                                    <Form>
                                        <div className="form-group">
                                            <label htmlFor="email">E-mail</label>
                                            <Field id="email" name="email" className="form-control"/>
                                            <FormErrorMessage name="email"/>
                                        </div>

                                        {errorMsg && <div className="text-danger form-text">{errorMsg}</div>}
                                        <NiceButton type="submit" className="" isLoading={isLoading}>
                                            Odoslať
                                        </NiceButton>
                                    </Form>
                                </Formik>
                            </div>
                        </>}


                </div>
            </div>
        </PublicLayout>
    )
}

export default ForgotPasswordPage
