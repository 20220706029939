import React from 'react'

import 'react-multi-carousel/lib/styles.css'
import { Link } from 'react-router-dom'

import { ClientForm } from './ClientForm'
import { ClientsList } from './ClientsList'
import AppLayout from '../common/AppLayout'
import AppTitle from '../common/AppTitle'
import { useContentWrapper } from '../common/ContentWrapperProvider'

function ClientsAddPage() {

    return (
        <AppLayout>
            <AppTitle append={<Link to={'/app/clients' }><button className="btn amani-primary">Späť</button></Link>}>Pridanie klienta</AppTitle>

            <ClientForm/>


        </AppLayout>
    )
}

export default ClientsAddPage
