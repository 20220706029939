import React, { useEffect, useState } from 'react'

import { Field, Form, Formik } from 'formik'
import { Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import addressIcon from '../assets/address_icon.png'
import mailIcon from '../assets/mail_icon.png'
import telIcon from '../assets/tel_icon.png'
import { useContentWrapper } from '../common/ContentWrapperProvider'
import FormErrorMessage from '../common/FormErrorMessage'
import PublicLayout from '../common/PublicLayout'
import { StyledInfoPageHeaderWrapper } from '../common/styledComponents'
import axios from '../service/axiosClient'

function ContactPage() {
    const contentWrapper = useContentWrapper()
    const {t} = useTranslation()
    const [formSent, setFormSent] = useState(false)

    useEffect(() => {
        contentWrapper.setNoBootstrapColumns(true)

        return () => {
            contentWrapper.setNoBootstrapColumns(false)
        }
    }, [])

    //@ts-ignore
    const renderMarkers = (map, maps) => {
        const marker = new maps.Marker({
            //@ts-ignore
            position: {
                lat: 48.3183007,
                lng: 18.1036843
            },
            map,
            title: 'AMani'
        })
        return marker
    }

    return (
        <PublicLayout>
            <div className="info-page">
                <StyledInfoPageHeaderWrapper>
                    <Container>
                        <Row>
                            <Col>
                    {/*<h1>Kontakt</h1>*/}
                            </Col>
                        </Row>
                    </Container>
                </StyledInfoPageHeaderWrapper>
                <Container>
                    <div className="rounded-content-body mt-3">
                        <Row>
                            <Col>
                                <h2>Fakturačné údaje</h2>
                                <div className=" mt-3">
                                    <div className="address">

                                    <span>
                                    The first million s. r. o.<br/>
                                    Dolné Sľažany 317<br/>
                                    951 71, Sľažany<br/>

                                    IČO :			54616 913<br/>
                                        DIČ :			2121733438<br/>
                                        Zápis :			Obchodný register Okresného súdu Nitra, oddiel Sro, vložka č. 60864/N
                                </span>
                                    </div>
                                </div>
                            </Col>
                            <Col>
                                {/* <div className="telephone">
                                <h4>Telefón:</h4>
                                <span>
                                    +421 915 693 552
                                </span>
                            </div> */}
                                <h2>Kontakty</h2>
                                <div className=" mt-3">
                                    <div className="email">

                                    <span>
                                    E-mail: pomozemti@amani.sk
                                </span>
                                    </div>
                                </div>

                            </Col>
                        </Row>

                        <Formik
                            initialValues={{
                                email: '', name: '', telephone: '', message: ''
                            }}
                            validate={(values: any) => {
                                const errors: any = {}

                                if (!values.message.trim()) {
                                    errors.message = t('required_field_error')
                                }
                                if (!values.email.trim()) {
                                    errors.email = t('required_field_error')
                                } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
                                    errors.email = 'Nesprávny formát e-mailovej adresy'
                                }

                                return errors
                            }}
                            onSubmit={(values) => {
                                axios.post('/contactform', {
                                    'email': values.email || '',
                                    'subject': 'Kontaktný formulár',
                                    'name': values.name || '',
                                    'phoneNumber': values.telephone || '',
                                    'content': values.message
                                })
                                    .then(response => {
                                        setFormSent(true)
                                    })
                                //contactform
                            }}>
                            <Form>
                                <Row>
                                    <Col>
                                        <h2 className={'mt-5'}>{formSent ? 'Tvoja správa bola odoslaná. Čoskoro ti odpovieme' : 'Kontaktný formulár'}</h2>
                                    </Col>
                                </Row>
                                {!formSent &&
                                    <div className=" mt-3">
                                        <Row>
                                            <Col lg={5}>
                                                <div className="form-group">
                                                    <Field id="email" name="email" placeholder="Zadaj tvoj e-mail"
                                                           className="form-control"/>
                                                    <FormErrorMessage name="email"/>
                                                </div>
                                                <div className="form-group">
                                                    <Field id="name" name="name" placeholder="Zadaj tvoje meno"
                                                           className="form-control"/>
                                                    <FormErrorMessage name="name"/>
                                                </div>
                                                <div className="form-group">
                                                    <Field id="telephone" name="telephone"
                                                           placeholder="Zadaj tvoje telefónne číslo"
                                                           className="form-control"/>
                                                    <FormErrorMessage name="telephone"/>
                                                </div>
                                            </Col>
                                            <Col lg={7}>
                                                <div className="form-group">
                                                    <Field as="textarea" rows="4" style={{height: '150px'}} id="message"
                                                           name="message" placeholder="Zadaj správu"
                                                           className="form-control"/>
                                                    <FormErrorMessage name="message"/>
                                                </div>
                                                <button type="submit" className="btn btn-primary btn-amani">
                                                    Odoslať
                                                </button>
                                            </Col>
                                        </Row></div>}
                            </Form>
                        </Formik>
                        {/* <Row className="mt-5">
                    <Col>
                        <StyledContactMapWrapper>
                            <GoogleMapReact
                                bootstrapURLKeys={{ key: 'AIzaSyAXD34zg1VgjDsKOig1dwbC2_oVsgPdm4I' }}
                                defaultCenter={{
                                    lat: 48.3183007,
                                    lng: 18.1036843
                                }}
                                defaultZoom={17}
                                yesIWantToUseGoogleMapApiInternals
                                onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
                            >

                                {/* <AnyReactComponent
                                    lat={59.955413}
                                    lng={30.337844}
                                    text="My Marker"
                                /> */}
                        {/* </GoogleMapReact> */}
                        {/* </StyledContactMapWrapper> */}

                        {/* </Col> */}
                        {/* </Row> */}
                    </div>
                </Container>
            </div>
        </PublicLayout>
    )
}

const StyledContactMapWrapper = styled.div`
    height: 540px;
    width: 100%;
    border: 2px solid #A7A6A7;
`
const StyledContactInfoWrapper = styled.div`
    margin: 20px 0;
    display: flex;
    // justify-content: space-between;
    justify-content: flex-start;

    div {
        background-repeat: no-repeat;
        padding-left: 155px;
        padding-top: 5px;
        min-height: 140px;
        width: 50%;

        &.address {
            background-image: url(${addressIcon});
        }

        &.telephone {
            background-image: url(${telIcon});
        }

        &.email {
            background-image: url(${mailIcon});
        }
    }

    @media only screen and (max-width: 991px) {
        display: block;

        div {
            width: 100%;
        }
    }
`

export default ContactPage
