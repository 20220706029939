import React, { useEffect, useState } from 'react'

import { Table } from 'react-bootstrap'
import toast from 'react-hot-toast'
import { Link, useNavigate } from 'react-router-dom'

import { ClientColorSquare } from '../common/ClientColorSquare'
import { NoRecords } from '../common/NoRecords'
import axiosClient from '../service/axiosClient'
import ClientDTO from '../types/Client'

export const ClientsList = () => {
    const [clients, setClients] = useState<ClientDTO[]>([])

    function load() {
        axiosClient.get('client').then(response => {
            console.log('response', response)
            setClients(response.data)
        })
    }

    useEffect(() => {
        load()
    }, [])

    function deleteClient(id: number) {
        if (confirm('Naozaj si želáte daného klienta vymazať?')) {
            axiosClient.delete('client/' + id)
            toast.success('Klient bol úspešne vymazaný')
            load()
        }
    }

    return (
        // clients.length === 0 ?
        //     <NoRecords/> :
            <>
                <Table className={'fndt_table'}>
                    <thead>
                    <tr>
                        {/*<th scope="col">#</th>*/}
                        <th scope="col">Meno a priezvisko</th>
                        <th scope="col">Názov firmy</th>
                        <th scope="col">Mobil</th>
                        <th scope="col">Email</th>
                        <th scope="col" className="text-right">Akcia</th>
                    </tr>
                    </thead>
                    <tbody>
                    {clients.length ?
                    clients.map((client, index) => (
                        <tr
                            className=""
                            key={client.id}
                            onClick={() => {
                                // navigate('/clients/' + client.id)
                            }}
                        >
                            {/*<td>{client.id}</td>*/}
                            <td><ClientColorSquare
                                color={client.color}/>{client.firstName && client.lastName ? client.firstName + ' ' + client.lastName : client.companyName}
                            </td>
                            <td>
                                {client.companyName}
                            </td>
                            <td>{`${client.countryPrefix} ${client.phoneNumber} ` + (client.companyPhoneNumber ? `${client.companyCountryPrefix} ${client.companyPhoneNumber} ` : '')}</td>
                            <td>{client.email || client.companyEmail}</td>
                            <td className="text-right">
                                <div className="d-flex gap-2 justify-content-end">
                                    <Link className={'table-action-link'} to={'/app/clients/' + client.id}
                                          title={'Upraviť'}>
                                        <i className="bi bi-pencil primary-text-color"></i>
                                    </Link>
                                    <a className={'table-action-link'} href={'#'} onClick={(e) => {
                                        e.preventDefault()
                                        client.id && deleteClient(client.id)
                                    }} title={'Vymazať'}>
                                        <i className="bi bi-trash primary-text-color"></i>
                                    </a>
                                </div>
                            </td>
                        </tr>
                    )) : <tr><td colSpan={5}><NoRecords /></td></tr>}
                    </tbody>
                </Table>
                <div className="d-flex justify-content-between fndt_pagination_wrapper"></div>
            </>
    )
}