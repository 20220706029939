import React, { KeyboardEventHandler, useEffect, useState } from 'react'

import clsx from 'clsx'

import { TodoItem } from '../types/Note'

interface Props {
    todo: TodoItem
    toggleTodoDone: (id: number, newState: boolean) => void
    delTodo: (id: number) => void
    editTodo: (todo: TodoItem) => void
}

const Todo = ({todo, delTodo, toggleTodoDone, editTodo}: Props) => {
    const [editInputValue, setInputValue] = useState(todo.value)
    const [isEdit, setIsEdit] = useState(false)

    let inputEle: HTMLInputElement | null = null

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value)
    }

    const handleKeydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const keyCode = e.which || e.keyCode
        const value = editInputValue.trim()

        if (keyCode === 13 && value.length) {
            e.preventDefault()
            editTodo({...todo, value: value})
            setIsEdit(false)
        }
        if (keyCode === 27) {
            cancelEdit()
        }
    }

    const cancelEdit = () => {
        setInputValue(todo.value)
        setIsEdit(false)
    }

    useEffect(() => {
        if (isEdit) {
            inputEle?.focus()
        }
    }, [inputEle, isEdit])

    return (

        <div
            className={clsx('task-item', {
                done: todo.checked,
                edit: isEdit,
            })}
        >
            <div
                className="task-item-checked"
                onClick={() => toggleTodoDone(todo.id, !todo.checked)}
            >
                <span className="icon icon-checked"></span>
            </div>

            <div className="task-item-body" onDoubleClick={() => setIsEdit(true)}>
                <span className="task-item-body-text">{todo.value}</span>

                <input
                    className="task-item-body-input"
                    type="text"
                    // placeholder="Adding more todos"
                    value={editInputValue}
                    onChange={handleChange}
                    onKeyDown={handleKeydown}
                    onBlur={cancelEdit}
                    ref={(ele) => (inputEle = ele)}
                />
            </div>
            <div className="task-item-action">
                <button
                    className="btn-reset btn-destroy icon"
                    onClick={() => delTodo(todo.id)}
                ></button>
            </div>
        </div>
    )
}

export default Todo
