import React from 'react'

import 'react-multi-carousel/lib/styles.css'
import { Link } from 'react-router-dom'

import { TSEntryForm } from './TSEntryForm'
import AppLayout from '../common/AppLayout'
import AppTitle from '../common/AppTitle'

function TimesheetAddPage() {

    return (
        <AppLayout>
            <AppTitle append={<Link to={'/app/timesheet'}>
                <button className="btn amani-primary">Späť</button>
            </Link>}>Pridanie záznamu</AppTitle>

            <div className="rounded-content-body p-3">
                <TSEntryForm/>
            </div>

        </AppLayout>
    )
}

export default TimesheetAddPage
