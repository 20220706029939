import React from 'react'

import 'react-multi-carousel/lib/styles.css'

import { Dropdown } from 'react-bootstrap'

import { SideCalendar } from './SideCalendar'
import { StickyNotesWrapper } from './StickyNotesWrapper'
import AppLayout from '../common/AppLayout'
import AppTitle from '../common/AppTitle'
import emitter from '../service/emitter'

function DashboardPage() {
    return (
        <AppLayout>
            <AppTitle append={
                // <button
                //     onClick={() => emitter.emit('add-sticky-note')}
                //     className="amani-primary">
                //     Pridať poznámku
                // </button>
                <Dropdown className={'ms-2 amani-primary'}>
                    <Dropdown.Toggle variant="success" className="amani-primary" id="dropdown-add-note">
                        Pridať &nbsp;
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item
                            onClick={() => {
                                emitter.emit('add-sticky-note')
                            }}>
                            Poznámku
                        </Dropdown.Item>
                        <Dropdown.Item
                            onClick={() => {
                                emitter.emit('add-sticky-todo')
                            }}>
                            Todo
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            }>Dashboard</AppTitle>

            <div className="row">
                <div className="col-md-9">
                    <StickyNotesWrapper/>
                </div>
                <div className="col-md-3">
                    <div className="rounded-right-sidebar">
                        <SideCalendar/>
                    </div>
                </div>
            </div>
        </AppLayout>
    )
}

export default DashboardPage

