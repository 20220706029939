import axios from 'axios'

import emitter from './emitter'

export const SERVER_URL = process.env.REACT_APP_SERVER_URL

const axiosClient = axios.create({
    baseURL: SERVER_URL,
})

axiosClient.interceptors.request.use((config) => {
    // @ts-ignore
    config.headers.common['Cache-Control'] = 'no-cache'
    // @ts-ignore
    config.headers.common['Pragma'] = 'no-cache'
    // @ts-ignore
    config.headers.common['Expires'] = '0'
    return config
})

// axiosClient.interceptors.response.use(
//     response => response,
//     error => {
//         if ([404].includes(error?.response?.status) || error?.response?.status >= 500 || error?.toString() === 'Error: Network Error') {
//             emitter.emit('show-server-err')
//         } else {
//             console.log('error', error)
//             return Promise.reject(error)
//         }
//     }
// )

export default axiosClient