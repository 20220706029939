import React, { useEffect, useState } from 'react'

import { Field, Form, Formik, FormikHelpers } from 'formik'
import DatePicker from 'react-datepicker'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'

import FormErrorMessage from '../common/FormErrorMessage'
import { dateToIsoString } from '../common/helpers'
import { datePickerDateFormat } from '../common/i18n'
import NiceButton from '../common/NiceButton'
import axiosClient from '../service/axiosClient'
import ClientDTO from '../types/Client'
import TSEntry, { TSEntryFormData } from '../types/TSEntry'

interface Props {
    id?: number | null
}

export const TSEntryForm = ({id}: Props) => {
    const [searchParams,] = useSearchParams()
    const queryClientId = searchParams.get('clientId')
    const queryStart = searchParams.get('start')
    const queryEnd = searchParams.get('end')
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const navigate = useNavigate()
    const [clients, setClients] = useState<ClientDTO[]>([])
    const [entry, setEntry] = useState<TSEntryFormData>({
        description: '',
        clientId: queryClientId || '',
        entryDate: null,
        startedAt: '',
        endedAt: '',
    } as TSEntryFormData)
    const {t} = useTranslation()

    useEffect(() => {
        axiosClient.get('client').then(response => {
            console.log('response', response)
            setClients(response.data)
        })
    }, [])

    useEffect(() => {
        if (id) {
            axiosClient.get('timesheet/' + id)
                .then(response => {
                    console.log('response', response)
                    const entryTmp: TSEntry = response.data
                    setEntry({
                        ...entryTmp,
                        clientId: entryTmp.clientId ? entryTmp.clientId.toString() : '',
                        entryDate: entryTmp.entryDate ? new Date(entryTmp.entryDate) : null,
                        endedAt: entryTmp.endedAt,
                        startedAt: entryTmp.startedAt
                    })
                })

        }
    }, [id])

    const submit = async (values: TSEntryFormData, actions: FormikHelpers<TSEntryFormData>) => {
        console.log('values', values)

        try {
            // const response = await updateProfile({...values})
            setIsLoading(true)
            const request: Omit<TSEntry, 'id' | 'earning'> & { id?: number } = {
                description: values.description ?? '',
                clientId: values.clientId as unknown as number,
                entryDate: values.entryDate ? dateToIsoString(new Date(values.entryDate)) : '',
                startedAt: values.startedAt,
                endedAt: values.endedAt,
            }

            if (id) {
                request.id = id
            }
            const response =
                id ?
                    await axiosClient.put('timesheet', request) :
                    await axiosClient.post('timesheet', request)

            if (id) {
                window.scrollTo(0, 0)
                toast.success('Záznam bol úspešne upravený')
            } else if (response.data > 0) {
                navigate('/app/timesheet')
                toast.success('Záznam bol úspešne vytvorený')
            }

            // actions.resetForm()

        } catch (error: any) {
            toast.error('Záznam sa nepodarilo uložiť')
        } finally {
            setIsLoading(false)
        }
    }

    const validate = (values: Partial<TSEntryFormData>) => {
        const errors: any = {}

        if (!values?.clientId) {
            errors.clientId = t('required_field_error')
        }
        if (!values?.endedAt) {
            errors.endedAt = t('required_field_error')
        }
        if (!values?.startedAt) {
            errors.startedAt = t('required_field_error')
        }

        if (!values?.entryDate) {
            errors.entryDate = t('required_field_error')
        }

        if (!values?.description?.trim()) {
            errors.description = t('required_field_error')
        }

        return errors
    }

    return (
        <div className="row d-flex">
            <div className="col-md-8">
                <Formik
                    initialValues={entry}
                    enableReinitialize
                    validate={validate}
                    onSubmit={submit}
                >{({values, setFieldValue}) => (<>
                    <Form>
                        <div className="row d-flex justify-content-center">
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="entryDate">Dátum</label>
                                    <DatePicker
                                        scrollableYearDropdown
                                        yearDropdownItemNumber={100}
                                        locale="sk"
                                        dateFormat={datePickerDateFormat}
                                        showYearDropdown
                                        selected={(values.entryDate && new Date(values.entryDate)) || null}
                                        onChange={(value: any) => {
                                            setFieldValue('entryDate', value)
                                        }}
                                        id="entryDate"
                                        name="entryDate"
                                        className="form-control"/>
                                    <FormErrorMessage name="entryDate"/>
                                </div>

                            </div>

                            <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="startedAt">Čas od</label>
                                    <input
                                        className="form-control"
                                        type="time"
                                        value={values.startedAt}
                                        id="startedAt"
                                        name="startedAt"
                                        onChange={(e) => setFieldValue('startedAt', e.target.value)}/>
                                    <FormErrorMessage name="startedAt"/>
                                </div>

                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="endedAt">Čas do</label>
                                    <input
                                        className="form-control"
                                        type="time"
                                        value={values.endedAt}
                                        id="endedAt"
                                        name="endedAt"
                                        onChange={(e) => setFieldValue('endedAt', e.target.value)}/>
                                    <FormErrorMessage name="endedAt"/>
                                </div>

                            </div>
                        </div>

                        <div
                            className={'form-group'}>
                            <label htmlFor="clientId">
                                Klient
                            </label>
                            <Field as="select" id="clientId" name="clientId" className="form-select">
                                <option value="">Vyberte</option>
                                {clients.map((option) => (
                                    <option key={option.id}
                                            value={option.id}>{option.companyName || (`${option.firstName} ${option.lastName}`)}</option>))}
                            </Field>
                            <FormErrorMessage name="clientId"/>
                        </div>

                        <div className="form-group">
                            <label htmlFor="description">Popis</label>
                            <Field as="textarea" rows={5} id="description" name="description" className="form-control"/>
                            <FormErrorMessage name="description"/>
                        </div>


                        <NiceButton isLoading={isLoading} type="submit">
                            Uložiť
                        </NiceButton>
                    </Form>
                </>)}

                </Formik>
            </div>
        </div>
    )
}