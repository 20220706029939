import React, { useState } from 'react'

import toast from 'react-hot-toast'

import NiceButton from '../common/NiceButton'
import { ClientFormWrapper } from '../common/styledComponents'
import axiosClient from '../service/axiosClient'

// TODO: Define API_PATHS.letters
const API_PATHS = { 
    letters: `${process.env.REACT_APP_SERVER_URL}/api/affiliate-letters`
}

const AffiliateCommisionLetters = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false)

    function statusColorSwith(status : string){
        switch(status){
            case 'PAID':
                return 'inherit'
            case 'UNPAID':
                return 'purple'
            case 'PANDING':
                return '#ffecc4'
            default:
                return 'inherit'
        }
    }
/*     // Test data
    const tableData= [            
        {
            id:'2024001',
            description:'provízia',
            status:'paid',
            value: 32.00,
        },
        {
            id:'2024002',
            description:'provízia',
            status:'unpaid',
            value: 32.00,
        },
        {
            id:'2024003',
            description:'provízia',
            status:'waiting',
            value: 32.00,
        },
        {
            id:'2024004',
            description:'provízia',
            status:'paid',
            value: 32.00,
        },
    ] */
        const generatePdfFile = async (letterId: string) => {
            try {
                setIsLoading(true)
                const response = await axiosClient.get(`/affiliate-letters/generate/${letterId}`)
                const pdfResponse = await axiosClient({
                    url: `/affiliate-letters/download/${letterId}`,
                    method: 'GET',
                    responseType: 'blob', // important
                })
    
                // create file link in browser's memory
                const href = URL.createObjectURL(pdfResponse.data)
    
                // create "a" HTML element with href to file & click
                const link = document.createElement('a')
                link.href = href
                link.setAttribute('download', `affiliate_letter_${letterId}.pdf`) // or any other extension
                document.body.appendChild(link)
                link.click()
    
                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link)
                URL.revokeObjectURL(href)
    
                toast.success('PDF was successfully generated and downloaded')
            } catch (error) {
                console.error('Error generating PDF:', error)
                toast.error('Failed to generate PDF')
            } finally {
                setIsLoading(false)
            }
        }
  return (
    <ClientFormWrapper>
        {/* <strong className='px-2'>Provízne listy</strong> */}
        <table className="table table-borderless" >
            <thead>
                <tr>
                    <th scope='col' style={{color:'inherit'}}>provízny list č.</th>
                    <th scope='col' style={{color:'inherit'}}>popis</th>
                    <th scope='col' style={{color:'inherit'}}>stav</th>
                    <th scope='col' style={{color:'inherit'}}>suma</th>
                    <th scope='col' style={{color:'inherit'}}>{/* icony: zobraziť stiahnuť */}</th>
                </tr>
            </thead>
            <tbody>
{/*                 {tableData.map((list)=>{
                    return (
                        <tr key={list.id} >
                            <td scope='row' style={{color:'inherit'}}>{list.id}</td>
                            <td style={{color:'inherit'}}>{list.description}</td>
                            <td style={{color:statusColorSwith(list.status)}}>{list.status}</td>
                            <td style={{color:'inherit'}}>{list.value} eur</td>
                        </tr>
                    )
                })} */}
                 {/* Example data */}
                 {/* [
                        { id: '2024001', description: 'provízia', status: 'PAID', value: 32.00 },
                        { id: '2024002', description: 'provízia', status: 'UNPAID', value: 32.00 },
                    ].map((list) => (
                        <tr key={list.id}>
                            <td scope='row' style={{ color: 'inherit' }}>{list.id}</td>
                            <td style={{ color: 'inherit' }}>{list.description}</td>
                            <td style={{ color: statusColorSwith(list.status) }}>{list.status}</td>
                            <td style={{ color: 'inherit' }}>{list.value} eur</td>
                            <td style={{ color: 'inherit' }}>
                                <NiceButton
                                    type="button"
                                    isLoading={isLoading}
                                    onClick={() => generatePdfFile(list.id)}
                                >
                                    Stiahnuť a zobraziť
                                </NiceButton>
                            </td>
                        </tr>
                    )) */}
            </tbody>
        </table>
    </ClientFormWrapper>
  )
}

export default AffiliateCommisionLetters