import React, { useEffect, useState } from 'react'

import { Field, Form, Formik, FormikHelpers } from 'formik'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { TagsInput } from 'react-tag-input-component'

import { ColorPickerInput } from '../common/ColorPickerInput'
import FormErrorMessage from '../common/FormErrorMessage'
import NiceButton from '../common/NiceButton'
import { ClientFormWrapper } from '../common/styledComponents'
import axiosClient from '../service/axiosClient'
import { CalendarEvent, CalendarEventFormData, CalendarEventType, ReminderUnit } from '../types/Calendar'
import ClientDTO from '../types/Client'

interface Props {
    id?: number | null
}

//5 min pred 10 min pred 15 min pred 30 min pred 1 hod pred atd
const reminderOptions = [
    {reminder: '5', reminderUnit: ReminderUnit.MINUTES, text: '5 minút pred'},
    {reminder: '15', reminderUnit: ReminderUnit.MINUTES, text: '15 minút pred'},
    {reminder: '30', reminderUnit: ReminderUnit.MINUTES, text: '30 minút pred'},
    {reminder: '1', reminderUnit: ReminderUnit.HOURS, text: '1 hodinu pred'},
    {reminder: '2', reminderUnit: ReminderUnit.HOURS, text: '2 hodiny pred'},
    {reminder: '5', reminderUnit: ReminderUnit.HOURS, text: '5 hodín pred'},
    {reminder: '1', reminderUnit: ReminderUnit.DAYS, text: '1 deň pred'},
    {reminder: '2', reminderUnit: ReminderUnit.DAYS, text: '2 dni pred'},
    {reminder: '7', reminderUnit: ReminderUnit.DAYS, text: '1 týždeň pred'},
]

const reminderUnitOptions = [
    {value: ReminderUnit.DAYS, label: 'Dni'},
    {value: ReminderUnit.MINUTES, label: 'Minúty'},
    {value: ReminderUnit.HOURS, label: 'Hodiny'},
]

export const CalendarEventForm = ({id}: Props) => {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [searchParams,] = useSearchParams()
    const queryClientId = searchParams.get('clientId')
    const entryDate = searchParams.get('entryDate')
    const navigate = useNavigate()
    const [clients, setClients] = useState<ClientDTO[]>([])
    const [eventTypes, setEventTypes] = useState<CalendarEventType[]>([])
    const [event, setEvent] = useState<CalendarEventFormData>({
        description: '',
        clientId: queryClientId || '',
        eventType: 0,
        reminderUnit: '' as unknown as ReminderUnit,
        reminder: '',
        name: '',
        color: '',
        meetingLink: '',
        street: '',
        guestsEmailAddresses: [],
        city: '',
        zipCode: '',
        country: '',
        entryDate: entryDate ?? '',
        startedAt: '',
        endedAt: '',
        // publishFrom: queryStart ? new Date(queryStart) : null,
        // publishUntil: queryEnd ? new Date(queryEnd) : null,
    } as CalendarEventFormData)
    const {t} = useTranslation()
    const [calendarEventTypes, setCalendarEventTypes] = useState<CalendarEventType[]>([])

    useEffect(() => {
        axiosClient.get('client').then(response => {
            console.log('response', response)
            setClients(response.data)
        })
        axiosClient.get('calendar-event/type').then(response => {
            console.log('response types', response)
            setEventTypes(response.data)
        })
        axiosClient.get('calendar-event/type').then(response => {
            console.log('response', response)
            setCalendarEventTypes(response.data)
        })
    }, [])

    useEffect(() => {
        if (id) {
            axiosClient.get<CalendarEvent>('calendar-event/' + id)
                .then(response => {
                    console.log('response', response)
                    const postTmp = response.data
                    setEvent({
                        ...postTmp,
                        clientId: postTmp.clientId.toString(),
                        eventType: postTmp.eventType.id,
                        guestsEmailAddresses: postTmp.guestsEmailAddresses ? postTmp.guestsEmailAddresses.split(',') : [],

                    } as CalendarEventFormData)
                })

        }
    }, [id])

    const submit = async (values: CalendarEventFormData, actions: FormikHelpers<CalendarEventFormData>) => {
        console.log('values', values)

        try {
            // const response = await updateProfile({...values})
            setIsLoading(true)
            const request: Omit<CalendarEvent, 'id' | 'clientId' | 'lastName' | 'firstName' | 'reminderUnit' | 'eventType'> & {
                id?: number,
                clientId: string,
                reminderUnit?: ReminderUnit
                eventType?: number
            } = {
                ...values,
                guestsEmailAddresses: values.guestsEmailAddresses.join(','),
            }

            if (!request.reminderUnit) {
                delete request.reminderUnit
            }
            if (!request.eventType) {
                delete request.eventType
            } else {
                request.eventType = +request.eventType
            }

            if (id) {
                request.id = id
            }

            const response = await axiosClient.post('calendar-event', request)

            if (id) {
                window.scrollTo(0, 0)
                toast.success('Udalosť bola úspešne upravená')
            } else if (response.data > 0) {
                toast.success('Udalosť bola úspešne vytvorená')
                navigate('/app/calendar')
            }
            // actions.resetForm()
        } catch (error: any) {
            toast.error('Udalosť sa nepodarilo uložiť')
        } finally {
            setIsLoading(false)
        }
    }

    function eventTypesLinkClick(e: React.MouseEvent<HTMLAnchorElement>) {
        e.preventDefault()
        navigate('/app/edit-profile#calendar-event-types')
    }

    const validate = (values: Partial<CalendarEventFormData>) => {
        const errors: any = {}

        // if (!values?.clientId) {
        //     errors.clientId = t('required_field_error')
        // }
        if (!values?.startedAt) {
            errors.startedAt = t('required_field_error')
        }
        if (!values?.endedAt) {
            errors.endedAt = t('required_field_error')
        }
        if (!values?.entryDate) {
            errors.entryDate = t('required_field_error')
        }
        if (!values?.name?.trim()) {
            errors.name = t('required_field_error')
        }
        if (!values?.eventType) {
            errors.eventType = t('required_field_error')
        }

        // if (!values?.eventType) {
        //     errors.eventType = t('required_field_error')
        // }

        return errors
    }

    return (
        <ClientFormWrapper>
            <div className="row d-flex">
                <div className="col-md-8">
                    <Formik
                        initialValues={event}
                        enableReinitialize
                        validate={validate}
                        onSubmit={submit}
                    >{({values, setFieldValue}) => (<>
                        <Form>
                            <div className="row d-flex">
                                <div className="form-group">
                                    <label htmlFor="name">Názov</label>
                                    <Field
                                        id="name"
                                        name="name"
                                        className="form-control"/>
                                    <FormErrorMessage name="name"/>
                                </div>

                                <div className="col-md-5">
                                    <div
                                        className={'form-group'}>
                                        <label htmlFor="clientId">
                                            Klient
                                        </label>
                                        <Field as="select" id="clientId" name="clientId" className="form-select">
                                            <option value="">Vyberte</option>
                                            {clients.map((option) => (
                                                <option key={option.id}
                                                        value={option.id}>{option.companyName || (`${option.firstName} ${option.lastName}`)}</option>))}
                                        </Field>
                                        <FormErrorMessage name="clientId"/>
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div
                                        className={'form-group'}>
                                        <label htmlFor="eventType">
                                            Typ udalosti
                                        </label>&nbsp;<a href="#" onClick={eventTypesLinkClick}>(pridať)</a>
                                        <select id="eventType" name="eventType"
                                                value={values.eventType}
                                                onChange={(e) => {
                                                    const event = eventTypes.find(et => et.id.toString() === e.target.value)
                                                    if (event?.color) {
                                                        setFieldValue('color', event?.color)
                                                    }
                                                    setFieldValue('eventType', e.target.value)
                                                }}
                                               className="form-select">
                                            <option value="">Vyberte</option>
                                            {eventTypes.map((option) => (
                                                <option key={option.id}
                                                        value={option.id}>{option.name}</option>))}
                                        </select>
                                        <FormErrorMessage name="eventType"/>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label>Farba</label>
                                        <ColorPickerInput
                                            color={values.color}
                                            setColor={(color) => setFieldValue('color', color)}/>
                                    </div>
                                </div>
                            </div>
                            <div className="row d-flex">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="publishFrom">Dátum</label>
                                        <input
                                            className="form-control"
                                            type="date"
                                            value={values.entryDate}
                                            id="entryDate"
                                            name="entryDate"
                                            onChange={(e) => setFieldValue('entryDate', e.target.value)}/>
                                        <FormErrorMessage name="entryDate"/>
                                    </div>

                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="startedAt">Začiatok</label>
                                        <input
                                            className="form-control"
                                            type="time"
                                            value={values.startedAt}
                                            id="startedAt"
                                            name="startedAt"
                                            onChange={(e) => setFieldValue('startedAt', e.target.value)}/>
                                        <FormErrorMessage name="startedAt"/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="endedAt">Koniec</label>
                                        <input
                                            className="form-control"
                                            type="time"
                                            value={values.endedAt}
                                            id="endedAt"
                                            name="endedAt"
                                            onChange={(e) => setFieldValue('endedAt', e.target.value)}/>
                                        <FormErrorMessage name="endedAt"/>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Pozvaní hostia</label>
                                <TagsInput
                                    beforeAddValidate={(newTag, allTags) => {
                                        return !!(String(newTag)
                                            .toLowerCase()
                                            .match(
                                                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                            ))
                                    }}
                                    value={values.guestsEmailAddresses}
                                    onChange={(tags) => setFieldValue('guestsEmailAddresses', tags)}
                                    name="guestsEmailAddresses"
                                    placeHolder="Zadajte email a stlačte enter"
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="description">Popis</label>
                                <Field as="textarea" rows={5} id="description" name="description"
                                       className="form-control"/>
                                <FormErrorMessage name="description"/>
                            </div>

                            <div className="form-group">
                                <label htmlFor="meetingLink">Link na meeting</label>
                                <Field id="meetingLink" name="meetingLink"
                                       className="form-control"/>
                                <FormErrorMessage name="meetingLink"/>
                            </div>

                            <div className="row d-flex">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="street">Ulica</label>
                                        <Field id="street" name="street"
                                               className="form-control"/>
                                        <FormErrorMessage name="street"/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="city">Mesto</label>
                                        <Field id="city" name="city"
                                               className="form-control"/>
                                        <FormErrorMessage name="city"/>
                                    </div>
                                </div>
                            </div>
                            <div className="row d-flex">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="zipCode">PSČ</label>
                                        <Field id="zipCode" name="zipCode"
                                               className="form-control"/>
                                        <FormErrorMessage name="zipCode"/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="country">Krajina</label>
                                        <Field id="country" name="country"
                                               className="form-control"/>
                                        <FormErrorMessage name="country"/>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={'form-group'}>
                                <label htmlFor="reminderu">
                                    Upozorniť
                                </label>
                                <select
                                    id="reminderu"
                                    className="form-select"
                                    onChange={(e) => {
                                        const actualReminder = reminderOptions.find(option => option.text === e.target.value)
                                        setFieldValue('reminder', actualReminder?.reminder || '')
                                        setFieldValue('reminderUnit', actualReminder?.reminderUnit || '')
                                    }}>
                                    <option value="">Vyberte</option>
                                    {reminderOptions.map((option) => (
                                        <option
                                            key={option.text}
                                            selected={option.reminderUnit === values.reminderUnit && option.reminder === values.reminder.toString()}
                                            value={option.text}>{option.text}</option>))}
                                </select>
                            </div>

                            {/*<div className="row d-flex">*/}
                            {/*    <div className="col-md-6">*/}
                            {/*        <div*/}
                            {/*            className={'form-group'}>*/}
                            {/*            <label htmlFor="reminder">*/}
                            {/*                Upozorniť*/}
                            {/*            </label>*/}
                            {/*            <Field id="reminder" name="reminder" className="form-control"/>*/}
                            {/*            <FormErrorMessage name="reminder"/>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}

                            {/*    <div className="col-md-6">*/}
                            {/*        <div*/}
                            {/*            className={'form-group'}>*/}
                            {/*            <label htmlFor="reminderUnit">*/}
                            {/*                Jednotka upozornenia*/}
                            {/*            </label>*/}
                            {/*            <Field as="select" id="reminderUnit" name="reminderUnit"*/}
                            {/*                   className="form-control">*/}
                            {/*                <option value="">Vyberte</option>*/}
                            {/*                {reminderUnitOptions.map((option) => (*/}
                            {/*                    <option key={option.value}*/}
                            {/*                            value={option.value}>{option.label}</option>))}*/}
                            {/*            </Field>*/}
                            {/*            <FormErrorMessage name="reminderUnit"/>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            <NiceButton isLoading={isLoading} type="submit">
                                Uložiť
                            </NiceButton>
                        </Form>
                    </>)}

                    </Formik>
                </div>
            </div>
        </ClientFormWrapper>
    )
}