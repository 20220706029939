import React from 'react'

import styled from 'styled-components'

const StyledBg = styled.div`
`

function AboutUsPage() {
    return (
        <StyledBg>
            <div className="row d-flex justify-content-center">
                <div className="col-md-8">
                    about us
                </div>
            </div>
        </StyledBg>
    )
}

export default AboutUsPage
